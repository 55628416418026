import React from 'react'
import tw from 'twin.macro'

import deliveroo from '../../../images/partners/photos/deliveroo.svg'
import getease from '../../../images/partners/photos/getease.png'
import youngones from '../../../images/partners/photos/youngones.png'

import { RegisterAsPartnerButton } from '../../CTA'

// Copys automatically integrates
const point = [
    {
        body: `
            Krijg jouw eigen unieke tracking link waarmee je jouw netwerk gemakkelijk introduceert aan Tellow en vul je campagnes in op een manier die het beste past bij jouw bedrijf en doelgroep.
        `,
    },
    {
        body: `
            Interessante kickback-fee proposities op basis van de door jou aangeleverde omzet.
        `,
    },
    {
        body: `
            Samenwerken met een betrouwbare partner. Onze klanten waarderen ons gemiddeld een 9.2 op basis van meer dan 200 reviews.
        `,
    },
]

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl pb-4 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-12`,
    title: tw`text-2xl lg:text-3xl font-plus-jakarta font-black items-start mb-6 text-tellow-purple!`,
    text: tw`text-base lg:text-sm text-gray-600 font-normal mb-8`,
    point: tw`text-xl font-black mb-2`,
    message: tw`text-base md:text-lg lg:text-xl font-medium`,
    content: tw`flex flex-col h-auto items-start justify-center`,
    img: tw`hidden md:inline mx-auto my-0 p-0 filter mt-6 mb-6 h-20`,
    textBlock: tw`flex flex-col items-start mt-5`,
    name: tw`text-left text-lg font-bold mb-0 font-bold`,
    company: tw`text-left text-lg text-tellow-purple mb-0 font-bold`,
    messageContainer: tw`flex justify-center rounded-2xl bg-tellow-white border-solid border-2 border-tellow-black border-opacity-10 shadow-xl mr-5`,
    tryContainer: tw`rounded-2xl bg-tellow-white border-solid border-2 border-tellow-black border-opacity-10 shadow-xl p-10`,
    bigQuote: tw`text-tellow-purple font-bold text-7xl -mb-5`,
    textPurple: tw`inline text-tellow-purple!`,
    check: tw`h-12 w-12 text-tellow-purple`,
    logogrid: tw`grid grid-cols-3 gap-3`,
}

const WhyBecomePartner = () => (
    <div css={style.wrapper}>
        <div css={style.grid}>
            <div css={style.messageContainer} style={{ transform: 'rotate(-6deg)' }}>
                <div css={style.tryContainer} style={{ transform: 'rotate(6deg)' }}>
                    <div css={style.logogrid}>
                        <img css={style.img} src={getease} alt="GetEase logo" />

                        <img css={style.img} src={deliveroo} alt="Deliveroo logo" />

                        <img css={style.img} src={youngones} alt="Youngones logo" />
                    </div>
                    <div css={style.bigQuote}>“</div>
                    <div css={style.message}>
                        Ervaar ook waarom GetEase, Deliveroo en YoungOnes Tellow exclusief aanraden.<span css={style.textPurple}>”</span>
                    </div>
                    <div css={style.textBlock}>
                        <p css={style.name}>Midas</p>
                        <p css={style.company}>Partnership Mananger bij Tellow</p>
                    </div>
                </div>
            </div>
            <div css={style.content}>
                <div css={style.title}>Voordelen voor partners</div>
                {point.map(({ body }) => (
                    <>
                        <svg css={style.check} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5,0a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,0ZM7.442,4.192l-2.75,2.75a.625.625,0,0,1-.884,0l-1.25-1.25a.625.625,0,0,1,.884-.884l.808.808L6.558,3.308a.625.625,0,0,1,.884.884Z" />
                        </svg>
                        <div css={style.text}>{body}</div>
                    </>
                ))}
                <RegisterAsPartnerButton text="Contact opnemen" url="mailto:support@tellow.nl" />
            </div>
        </div>
    </div>
)

export default WhyBecomePartner
