import React from 'react'
import styled from 'styled-components'

const BlockSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(251, 251, 251);
    padding: 4rem 1rem;
    @media screen and (min-width: 768px) {
        padding: 4rem 1rem;
    }
`

const Content = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
    }
    h2 {
        font-size: 2.2rem;
        line-height: 1.1;
        font-weight: bold;
        color: #011532;
        text-align: center;
        margin-bottom: 3rem;
        @media screen and (min-width: 768px) {
            font-size: 2.7rem;
        }
    }
`

const SocialProofRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    @media screen and (max-width: 1200px) and (min-width: 768px) {
        height: 340px;
    }
`

const SocialProof = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 15.5rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px) {
        margin-bottom: unset;
    }
    @media screen and (max-width: 1200px) and (min-width: 768px) {
        width: 30%;
        height: 100%;
        justify-content: space-between;
    }
    h3 {
        line-height: 3.8rem;
        font-size: 2.7rem;
        font-weight: bold;
        color: #4bbc90;
    }
    p {
        line-height: 1.375rem;
        font-size: 0.9907rem;
        color: #98a0a6;
    }
    h4 {
        line-height: 1.8125rem;
        font-size: 1.3003rem;
        font-weight: bold;
        color: #98a0a6;
    }
`

const SocialProofBlock = () => (
    <BlockSection>
        <Content>
            <h2>Zzp'ers beoordelen Tellow met een 8.9</h2>
            <SocialProofRow>
                <SocialProof>
                    <h3>9.0</h3>
                    <p>"Makkelijk programma in gebruik, heb meteen overzicht in de financiën. Vriendelijke hulp op afstand die vragen beantwoord en problemen oplost."</p>
                    <h4>Mariëtte uit Nispen</h4>
                </SocialProof>
                <SocialProof>
                    <h3>10.0</h3>
                    <p>"Tellow zorgt ervoor dat je vrijwel niet meer aan je boekhouding hoeft te denken. Met een klein beetje basiskennis kan je vrijwel alles zelf."</p>
                    <h4>Dirk</h4>
                </SocialProof>
                <SocialProof>
                    <h3>8.0</h3>
                    <p>
                        "Ik ben ben heel blij met Tellow. Ik heb Tellow nu 2 maanden en het bevalt prima, heel makkelijk en overzichtelijk. Ze bellen je zelfs op als je er niet uit
                        komt!"
                    </p>
                    <h4>Yvonne uit Malden</h4>
                </SocialProof>
            </SocialProofRow>
        </Content>
    </BlockSection>
)
export default SocialProofBlock
