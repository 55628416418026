/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import currency from 'currency.js'
import StreamlineIcon from '../../../images/icons/streamline/index'
import CTA from '../../refactored/CTA'
import config from '../../../config'

/**
 * Components to mimick
 * Tellow Webapp components.
 *
 * 1.  Form
 * 2.  Input
 * 3.  Select
 *
 * Starting with:
 * 1.  Form
 */
const formstyle = {
    base: tw`z-20 p-6 rounded-md shadow-2xl max-w-3/4 mx-auto`,
    grid: tw`grid gap-2 items-start auto-rows-min grid-cols-4`,
}
const TellowStyleForm = ({ children }) => <div css={[formstyle.base, formstyle.grid, tw`bg-tellow-purple `]}>{children}</div>

// 2.  Input
const inputstyle = {
    base: tw`w-full appearance-none rounded shadow-sm py-2 pl-8 pr-4 focus:outline-none`,
    color: tw`border border-solid border-gray-100`,
    text: tw`antialiased text-sm`,
    label: tw`text-gray-500 text-xs antialiased font-medium`,
}
const Input = styled.span`
    position: relative;

    &::before {
        content: '€ ';
        color: #adb5bd;
        user-select: none;

        top: 0.325em;
        left: 0.75rem;

        height: 100%;
        position: absolute;
    }
`
const TellowStyleInput = ({ name: nameProp, value: valueProp, type = 'number', callback, extend }) =>
    type === 'text' ? (
        <span css={[extend, tw`relative`]}>
            <input
                type={type}
                name={nameProp}
                value={valueProp}
                onChange={({ target }) => callback(target.name, target.value)}
                css={[inputstyle.base, inputstyle.color, inputstyle.text, tw`self-start pl-10`]}
            />

            {/* 'Text' icon */}
            <span css={tw`pointer-events-none absolute h-full inset-y-0 left-4 flex items-center`}>
                <svg height="11" width="12">
                    <path
                        d="M8.3571429.5c.5522847 0 1 .4477153 1 1 0 .5128358-.3860402.9355072-.883379.9932723L8.357143 2.5h-7c-.5522848 0-1-.4477152-1-1 0-.5128358.3860401-.9355072.8833788-.9932723L1.357143.5h7zm-2.5357143 4c.5522847 0 1 .4477152 1 1 0 .5128358-.3860402.9355072-.8833789.9932723L5.8214286 6.5H1.1785714c-.5522847 0-1-.4477152-1-1 0-.5128358.3860402-.9355072.8833789-.9932723L1.1785714 4.5h4.6428572zm4.3571428 4c.5522848 0 1 .4477152 1 1 0 .5128358-.3860402.9355072-.8833788.9932723L10.1785713 10.5h-9c-.5522847 0-1-.4477152-1-1 0-.5128358.3860402-.9355072.8833789-.9932723L1.1785714 8.5h9z"
                        fill="#9fa6b2"
                    />
                </svg>
            </span>
        </span>
    ) : (
        <Input>
            <input
                step="0.01"
                type={type}
                name={nameProp}
                value={valueProp}
                onChange={({ target }) => callback(target.name, target.value)}
                css={[inputstyle.base, inputstyle.color, inputstyle.text, tw`self-start`, extend]}
            />
        </Input>
    )

// 3.  Select
const TellowStyleSelect = ({ callback, options, value: valueProp, name: nameProp }) => (
    <span css={tw`flex flex-col relative`}>
        <select
            name={nameProp}
            value={valueProp}
            id="very-long-name"
            onChange={({ target }) => callback(target.name, target.value)}
            css={[tryout.select.base, tryout.select.color, tryout.select.text]}
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {Number(option)}%
                </option>
            ))}
        </select>

        {/* Dropdown icon */}
        <span css={tw`pointer-events-none absolute h-full inset-y-0 right-2 flex items-center`}>
            <svg css={tw`h-5 w-5`} fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" fill="none" stroke="#9fa6b2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg>
        </span>
    </span>
)

/**
 * Legend on the
 * 'result' component.
 */
const res = {
    label: tw`text-xs tracking-tight text-gray-500 antialiased mb-0`,
    base: tw`flex flex-col`,
    value: tw`text-xs antialiased text-gray-800 z-30 pt-2`,
    nowrap: tw`whitespace-nowrap`,
}
const ResultLegend = () => (
    <>
        <p css={[res.label, tw`col-span-2`]}>Omschrijving</p>
        <p css={res.label}>Bedrag</p>
        <p css={res.label}>Bedrag excl. BTW</p>
        <p css={res.label}>BTW</p>
        <p css={res.label}>Totaal</p>
    </>
)

/**
 * Faux Tellow
 * invoiceline.
 */
const Invoiceline = ({ data }) => (
    <>
        <p css={[res.value, tw`col-span-2`]}>{data.description}</p>
        <p css={[res.value, res.nowrap]}>€ {data.price}</p>
        <p css={[res.value, res.nowrap]}>€ {data.excl}</p>
        <p css={[res.value, res.nowrap]}>€ {currency(data.price, { precision: 2 }).subtract(data.excl).value}</p>
        <p css={[res.value, res.nowrap]}>€ {data.price}</p>
    </>
)

/**
 * Component to render
 * results of given data
 * into UI fields.
 */
const result = {
    wrap: tw`transform rotate-2 relative mx-auto -mb-10 mt-6`,
    sheet: tw`z-10 auto-rows-min relative bg-white grid gap-y-8 grid-cols-1 grid-flow-row rounded-md p-10`,
    company: tw`text-gray-300 grid gap-x-8 grid-cols-2 mt-4 mb-8`,
    name: tw`m-0 z-30 text-2xl font-light antialiased`,
    address: tw`m-0 text-xs font-medium antialiased`,
    invoiceline: tw`grid grid-cols-6 gap-x-4 auto-rows-auto`,
    mocks: tw`z-0 absolute rounded-md top-0 bg-indigo-100`,
    shadow: tw`absolute inset-0 m-6 shadow-xl bg-gradient-to-r from-indigo-500 to-white`,
}

/**
 * Styled component to add a
 * pseudo :after tag with a gradient.
 */
const Card = styled.figure`
    &::after {
        content: '';
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
    }
`

/**
 * Format the invoiceline
 * to something that resembles
 * what you see in Tellow.
 */
const Result = ({ children, name = 'Maker & Co.' }) => {
    const hRef = useRef()
    const [dimensions, setDimensions] = useState({ maxWidth: null })

    /**
     * Function to set;
     * you guessed it...
     * the size.
     */
    const setSize = () => {
        const size = 0.95
        const scaled = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) / 1.5
        setDimensions({ maxWidth: scaled / size })
    }

    /**
     * Resize listener,
     * hook style.
     */
    useEffect(() => {
        let timeoutId = null
        const resizeListener = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => setSize(), 150)
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    return (
        <div css={result.wrap}>
            <Card ref={hRef} style={{ height: 'auto', maxWidth: dimensions.maxWidth }} css={result.sheet}>
                <div css={result.company}>
                    <span>
                        <h4 css={result.name}>{name}</h4>
                    </span>
                    <span css={tw`flex flex-col items-center`}>
                        <p css={result.address}>{config.address.street}</p>
                        <p css={result.address}>
                            {config.address.postalcode}, {config.address.city}
                        </p>
                    </span>
                </div>
                <div css={result.invoiceline}>{children}</div>
            </Card>

            {/* Faux gradient shadow. */}
            <span style={{ height: '45%', filter: 'blur(60px)' }} css={result.shadow} />
        </div>
    )
}

/**
 * Legend as seen
 * in Tellow UI.
 */
const legendstyle = {
    grid: tw`col-span-4 grid grid-cols-4`,
    label: tw`m-0 text-xs antialiased text-white`,
}
const TellowStyleLegend = () => (
    <div css={legendstyle.grid}>
        <span css={tw`col-span-4`}>
            <p css={legendstyle.label}>Omschrijving</p>
        </span>
        <span css={legendstyle.grid}>
            <p css={legendstyle.label}>Bedrag</p>
            <p css={legendstyle.label}>Bedrag excl. BTW</p>
            <p css={legendstyle.label}>BTW</p>
            <p css={legendstyle.label}>Totaal</p>
        </span>
    </div>
)

/**
 * Extra selling points.
 */
const sellingpointstyle = {
    wrap: tw`max-w-full md:max-w-3/4 mx-auto grid px-6 py-12 grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-y-0 md:gap-x-10 lg:gap-x-14`,
    span: tw`rounded-full mb-5 w-12 h-12 flex items-center justify-center border-2 border-solid`,
    h4: tw`text-xl text-gray-800 tracking-tight mb-4`,
    p: tw`text-sm max-w-xs text-gray-500`,
}
const SellingPoints = () => (
    <div css={sellingpointstyle.wrap}>
        <div>
            <span css={[sellingpointstyle.span, tw`border-indigo-500`]}>
                <StreamlineIcon style={{ margin: 0, filter: 'brightness(1) saturate(100) invert(1) grayscale(1)' }} icon="Computer" />
            </span>
            <h4 css={sellingpointstyle.h4}>Mooi makkelijk</h4>
            <p css={sellingpointstyle.p}>Facturen maak je in een handomdraai; en ze zien er nog goed uit ook!</p>
        </div>
        <div>
            <span css={[sellingpointstyle.span, tw`border-tellow-green`]}>
                <StreamlineIcon style={{ margin: 0, filter: 'brightness(1) saturate(100) invert(1) grayscale(1)' }} icon="Travel" />
            </span>
            <h4 css={sellingpointstyle.h4}>Alles online</h4>
            <p css={sellingpointstyle.p}>Al je inkoop- en verkoopfacturen staan veilig online, bij ons in de cloud. Altijd bereikbaar.</p>
        </div>
    </div>
)

/**
 * Global component
 * (for tryout) styles.
 */
const tryout = {
    hideOnMobile: tw`hidden md:block`,
    background: tw`bg-white w-full pt-12`,
    wrapper: tw`max-w-7xl mx-auto`,
    grid: tw`grid grid-cols-1 gap-6 `,
    intro: tw`flex mx-auto text-center flex-col mb-8`,
    title: tw`text-tellow-purple font-black tracking-tight antialiased mb-4 mt-4 sm:mb-6 sm:text-3xl md:text-4xl lg:text-5xl`,
    p: tw`antialiased prose text-sm mb-6`,
    select: {
        wrap: tw`flex flex-col`,
        base: tw`w-full appearance-none rounded shadow-sm py-2 px-4 focus:outline-none`,
        color: tw`border border-solid border-gray-100`,
        text: tw`antialiased text-sm`,
        label: tw`text-gray-500 text-xs antialiased font-medium`,
    },
}

/**
 * End the section with a fade.
 * Tailwind does not have pseudo
 * support, hence we do it here.
 */
const Fade = styled.div`
    position: relative;
    margin: 0 auto;

    &::after {
        content: '';
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);

        left: 0;
        right: 0;
        bottom: 0;
        height: 6rem;
        margin: -1rem -4rem;
        position: absolute;
    }
`

/**
 * The actual component
 * to render to the DOM.
 */
const Tryout = () => {
    // eslint-disable-next-line no-unused-vars
    const [company, _] = useState('Maker & Co.')
    const [line, setLine] = useState({
        description: 'Mountainbike-band (voor)',
        price: 100,
        excl: 79,
        vat: '21',
    })

    /**
     * Handle all fields from the
     * 'mocked' Tellow UI form.
     *
     * @param {string} key to map over (in state).
     * @param {string | number} value passed by input.
     */
    const handle = (key, value) => {
        const euro = (val) => currency(val, { precision: 2 })
        switch (key) {
            case 'price':
                // Price also sets excl.
                setLine((prev) => {
                    const val = value || prev.price || 0
                    const timesHundred = euro(val).value
                    const timesVat = euro(val).divide(100).multiply(prev.vat).value

                    return { ...prev, price: timesHundred, excl: euro(timesHundred).subtract(timesVat).value }
                })
                break
            case 'excl':
                // Excl also sets price.
                setLine((prev) => {
                    const val = value || prev.excl || 0
                    const timesHundred = euro(val).value
                    const plusVal = euro(val).divide(100).multiply(prev.vat).value

                    return { ...prev, excl: timesHundred, price: euro(timesHundred).add(plusVal).value }
                })
                break
            case 'vat':
                // Vat also sets price and excl.
                setLine((prev) => {
                    const timesHundred = prev.price
                    const timesVat = currency(prev.price).divide(100).multiply(value).value

                    return { ...prev, vat: value, price: euro(prev.price).value, excl: euro(timesHundred).subtract(timesVat).value }
                })
                break
            default:
                // Anything else goes here.
                setLine((prev) => ({ ...prev, [key]: value }))
                break
        }
    }

    return (
        <section css={[tryout.background, tryout.hideOnMobile]}>
            <div css={[tryout.wrapper, tryout.grid]}>
                {/* Header */}
                <div css={tryout.intro}>
                    <h2 css={tryout.title}>
                        Facturen maken is een eitje.&nbsp;
                        <br />
                        <span css={tw`text-gray-800`}>Ervaar het zelf!</span>
                    </h2>
                </div>

                {/* Preview */}
                <Fade>
                    <Result name={company}>
                        <ResultLegend />
                        <Invoiceline data={line} />
                    </Result>
                </Fade>

                {/* Form */}
                <TellowStyleForm>
                    <h3 css={tw`col-span-4 text-white mb-1 antialiased font-semibold text-sm`}>
                        Dit is hoe je werkt in Tellow; <span css={tw`font-normal`}>vul zelf gegevens in, en zie hier boven meteen het resultaat!</span>
                    </h3>
                    <hr css={tw`col-span-4 mb-4`} />

                    <TellowStyleLegend />
                    <>
                        <TellowStyleInput callback={(name, value) => handle(name, value)} value={line.description} extend={tw`col-span-4`} type="text" name="description" />
                        <TellowStyleInput callback={(name, value) => handle(name, value)} value={line.price} name="price" />
                        <TellowStyleInput callback={(name, value) => handle(name, value)} value={line.excl} name="excl" />
                        <TellowStyleSelect callback={(name, value) => handle(name, value)} value={line.vat} options={['21', '09', '06']} name="vat" />
                        <Input>
                            <div css={[inputstyle.base, inputstyle.color, inputstyle.text, tw`bg-white`]}>{line.price}</div>
                        </Input>
                    </>
                </TellowStyleForm>

                {/* Additional copy */}
                <SellingPoints />

                {/* Convert users? */}
                <CTA text="Ook gemakkelijk facturen maken? Begin vandaag" />
            </div>
        </section>
    )
}

export default Tryout
