import React from 'react'

import { BalloonSmall } from '../../UI'
import Check from '../../../images/styleguide/check'
import FreelancerPicture from './freelancerPicture'

import { Container, Item, Pair, Text as TextBlock } from '../../../utils'

const SummaryBlock = ({ data, url, alt }) => (
    <Container>
        <TextBlock
            style={{
                maxWidth: 1000,
                margin: '0 auto',
                paddingTop: '5rem',
                paddingBottom: '3rem',
            }}
        >
            <Pair>
                <Item>
                    <ol>
                        {data.map((topic) => (
                            <li key={topic}>
                                <BalloonSmall style={{ width: 40 }} color="green" textColor="white">
                                    <Check />
                                </BalloonSmall>
                                {topic}
                            </li>
                        ))}
                    </ol>
                </Item>

                <Item>
                    <FreelancerPicture url={url} alt={alt} />
                </Item>
            </Pair>
        </TextBlock>
    </Container>
)

export default SummaryBlock
