import React from 'react'
import styled from 'styled-components'
import DropDownIcon from '../Icons/dropDownIcon'

const Icon = styled.div`
    height: 1.25rem;
    width: 18px;
    display: inline-block;
    transform: rotate(${(props) => (props.active ? '180deg' : '0deg')});
    transition: transform 0.15s ease-out;
`

export default function PlusToX({ active, onClick, style }) {
    return (
        <Icon active={active} onClick={onClick} style={style}>
            <DropDownIcon />
        </Icon>
    )
}
