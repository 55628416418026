import React from 'react'

const TellowLogo = (props) => (
    <svg {...props} viewBox="0 0 155 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.13 0v5.17h7.53v20.3h5.9V5.18h7.53V0H52.13Z" />
        <path d="M85.46 9.54a8.36 8.36 0 0 0-6.4-2.62c-2.81 0-5.1.87-6.93 2.65a9.19 9.19 0 0 0-2.74 6.88c0 2.8.91 5.06 2.74 6.86A9.17 9.17 0 0 0 78.68 26c3.84 0 6.65-1.53 8.4-4.62l-3.98-2.3a4.98 4.98 0 0 1-4.2 2.25c-2.22 0-4.04-1.35-4.33-3.53h13.36v-1.86c0-2.55-.84-4.7-2.47-6.4M74.8 13.98c.58-1.79 2.15-2.84 4.15-2.84 2.07 0 3.64 1.05 4.07 2.84H74.8ZM96.39 0h-5.46v25.48h5.46V0ZM105.31 0h-5.45v25.48h5.45V0ZM122.14 7.9A9.54 9.54 0 1 0 113.7 25a9.54 9.54 0 0 0 8.43-17.1Zm-.17 12.57a.2.2 0 0 1-.25.24l-.94-.27c-.43-.12-.89-.09-1.3.08a4.36 4.36 0 1 1 .31-8.01 4.44 4.44 0 0 1 2.1 5.79c-.22.44-.26.94-.13 1.41l.21.76ZM149.22 7.43l-2.4 10.26-2.77-10.26h-5.24l-2.77 10.26-2.4-10.26h-5.46l5.06 18.05h5.27l2.92-11 2.9 11h5.28l5.06-18.05h-5.45ZM25.99 0h-8.5c0 3.6.73 7.03 2.06 10.16h-5.33A17.45 17.45 0 0 1 0 17.49V26c9.1 0 17.1-4.67 21.74-11.75A25.97 25.97 0 0 0 43.47 26v-8.5A17.5 17.5 0 0 1 25.99 0Z" />
    </svg>
)

export default TellowLogo
