import React from 'react'
import styled from 'styled-components'

const Picture = styled.img`
    box-shadow: ${(props) => props.theme.subtleShadow};
    border-radius: 1rem;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

const HeroPicture = ({ url, alt }) => <Picture src={url} alt={alt} />

export default HeroPicture
