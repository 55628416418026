import React from 'react'
import tw from 'twin.macro'

const styles = {
    bg: tw`bg-white`,
    wrap: tw`w-full max-w-7xl py-10 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 items-center`,
    h2: tw`text-gray-800 text-left font-black tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    ul: tw`list-disc list-inside mb-0 leading-relaxed`,
    entry: tw`text-gray-600 tracking-tight antialiased`,
}

const Summary = ({ key, title = 'Placeholder', items }) => (
    <div key={key} css={styles.bg}>
        <div css={[styles.wrap, styles.grid]}>
            <h2 css={styles.h2}>{title}</h2>
            <ul css={styles.ul}>
                {items.map(({ entry }) => (
                    <li key={entry.text} css={styles.entry}>
                        {entry.text}
                    </li>
                ))}
            </ul>
        </div>
    </div>
)

export default Summary
