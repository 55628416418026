import React from 'react'
import { Container, Spacing, StyledContainer, Copy, Center, Heading, Span, P, A, Placement, Screenshot } from './cta.styles'

// Fallback screenshot
const image = require('../../images/screenshots/homepage.png')

export default function CTA({ content }) {
    const slice = content.primary

    return (
        <Container>
            <Spacing>
                <StyledContainer>
                    <Copy>
                        <Center>
                            <Heading>
                                <Span>{slice.screenshot_cta_title.text || 'Een betere boekhouding?'}</Span>
                                <Span>{slice.screenshot_cta_subtitle.text}</Span>
                            </Heading>
                            <P>
                                {slice.screenshot_cta_button_body.text ||
                                    'Overzichtelijker. Makkelijker. Succesvoller. Het kan echt! Meld je vandaag nog aan, en ervaar zelf hoe makkelijk boekhouden écht kan zijn.'}
                            </P>
                            <A target="_blank" href="https://app.tellow.nl/registreer">
                                Gebruik Tellow gratis
                            </A>
                        </Center>
                    </Copy>
                    <Placement>
                        <Screenshot src={slice.screenshot_cta_button_image.url || image} alt={slice.screenshot_cta_button_image.alt || 'Tellow Screenshot'} />
                    </Placement>
                </StyledContainer>
            </Spacing>
        </Container>
    )
}
