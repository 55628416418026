/* eslint-disable no-nested-ternary */
import React from 'react'
import styled, { css } from 'styled-components'

import { ColoredBackground } from '../../UI'
import { Container, TextContainer } from '../../../utils'

const shared = css`
    display: flex;
    flex-direction: row;
    flex: 1;

    position: relative;
    max-width: 900px;
    margin: 0 auto;

    --reduced-height: 1rem 0;
    --normal-height: 2rem 0;
    --text-only-height: 4rem 0;
`

const ExtendedTextContainer = styled(TextContainer)`
    ${shared}
    padding: ${(props) => (props.reducedHeight ? 'var(--reduced-height)' : props.textOnly ? 'var(--text-only-height)' : 'var(--normal-height)')};

    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    flex-wrap: wrap;

    p {
        margin: ${(props) => (props.textOnly ? '0' : 'inherit')};
        font-size: ${(props) => (props.isQuote ? '2rem' : 'inherit')};
    }

    @media screen and (max-width: 740px) {
        padding: 2rem 1rem 8rem 1rem;
    }
`

const ExtendedGenericContainer = styled(Container)`
    ${shared}
    padding: ${(props) => (props.reducedHeight ? 'var(--reduced-height)' : 'var(--normal-height)')};

    --margin: 2rem;

    @media screen and (max-width: 820px) {
        #hero {
            display: none;
        }
    }

    div {
        display: flex;
        flex: 1;

        + img {
            flex: 1;
            max-width: 45%;
            margin-right: 1rem;
            margin-left: 3rem;
            margin-top: ${(props) => (props.reducedHeight ? '2rem' : '5rem')};
            align-self: ${(props) => (props.unlabelled ? 'center' : 'flex-start')};
        }
    }

    img {
        flex: 1;
        margin: var(--margin);
        margin-right: 3rem;
        margin-top: ${(props) => (props.reducedHeight ? '0' : '6rem')};
        border-radius: 0.2rem;
        max-width: 40%;
        display: flex;
        align-self: flex-start;

        + div {
            max-width: 50%;
        }
    }

    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-top: none;

        img {
            height: auto;
            max-width: unset;
            margin-top: unset;
            align-self: center;
            width: 80%;

            + div {
                max-width: unset;
                margin: var(--margin);
            }
        }

        div {
            order: 2;

            + img {
                order: 1;
                max-width: unset;
                width: 80%;
                padding-bottom: 2rem;
            }
        }
    }
`

export default function Block({ children, color, reducedHeight, center, unlabelled, textOnly, isQuote }) {
    return (
        <ColoredBackground color={color}>
            {children.length && children.some((child) => child && child.type && child.type === 'img') ? (
                <ExtendedGenericContainer unlabelled={unlabelled} center={center} reducedHeight={reducedHeight} textOnly={textOnly} isQuote={isQuote}>
                    {children}
                </ExtendedGenericContainer>
            ) : (
                <ExtendedTextContainer unlabelled={unlabelled} center={center} reducedHeight={reducedHeight} textOnly={textOnly} isQuote={isQuote}>
                    {children}
                </ExtendedTextContainer>
            )}
        </ColoredBackground>
    )
}
