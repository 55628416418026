import React from 'react'
import tw from 'twin.macro'
import Button from '../../UI/Buttons/button'

const styles = {
    bg: tw`bg-white`,
    wrap: tw`w-full max-w-7xl py-0 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 items-center`,
    button: tw`w-min-content mb-10 whitespace-nowrap text-base sm:text-sm xs:text-xs`,
}

const SubtleCTA = ({ key, title = 'Open een Tellow account', href }) => (
    <div key={key} css={styles.bg}>
        <div css={[styles.wrap, styles.grid]}>
            <Button css={styles.button} target="_blank" rel="noopener noreferrer" href={href} tertiary big lightShadow>
                {title} &nbsp; →
            </Button>
        </div>
    </div>
)

export default SubtleCTA
