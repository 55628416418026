import React from 'react'
import styled from 'styled-components'

const BlockSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 4rem 1rem;
`

const Content = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
    }
    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #011532;
        text-align: center;
    }
`

const PartnerRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }
    img {
        width: auto;
        height: 100%;
        margin: 5% 1rem;
    }
`

const PartnerBlock = ({ children }) => (
    <BlockSection>
        <Content>
            <h2>Onze partners:</h2>
            <PartnerRow>{children}</PartnerRow>
        </Content>
    </BlockSection>
)
export default PartnerBlock
