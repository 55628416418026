import React from 'react'
import styled from 'styled-components'
import { RegisterButton } from '../UI'
import TellowTeam from '../../images/team/tellowteam.jpg'

const BlockSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1rem;
`
const Content = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 3rem 0;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
    }
    h2 {
        font-size: 2.7rem;
        font-weight: bold;
        color: #011532;
        text-align: center;
        margin-bottom: 3rem;
    }
    p {
        margin: 1rem 0;
        padding: 0;
        font-size: 1rem;
        line-height: 1.2rem;
        color: #98a0a6;
        text-align: center;
        span {
            font-weight: bold;
            color: #011532;
        }
    }
    img {
        border-radius: 50px 0 30px 30px;
        box-shadow: 10px 10px rgb(236, 236, 236);
        object-fit: cover;
    }
    .large-text {
        font-size: 1.4rem;
        font-weight: 700;
    }
`
const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    align-items: center;
    @media screen and (min-width: 992px) {
        justify-content: space-between;
        flex-direction: row;
    }
`

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        width: 70%;
    }
    @media screen and (min-width: 992px) {
        max-width: 40%;
    }
    p.subtitle {
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
    p:last-of-type {
        margin-bottom: 2.5rem;
    }
`

const VisualContent = styled.div`
    @media screen and (min-width: 768px) {
        max-width: 50%;
    }
`

const AboutBlock = () => (
    <BlockSection>
        <Content>
            <h2>Over Tellow</h2>
            <Row>
                <TextContent>
                    <p className="subtitle">
                        <span>Wij geloven dat je meer uit</span>
                    </p>
                    <p className="subtitle">
                        <span>het leven haalt als ondernemer</span>
                    </p>
                    <br />
                    <p>
                        Ondernemen gaat om de vrijheid om van je werk te genieten. Overal waar je bent. Daarom willen wij iedereen de mogelijkheid geven om het ondernemersleven
                        goed uit te proberen.
                    </p>
                    <p>Wij willen dat ondernemen een feestje voor je is. De dingen waar jij tegenop ziet, maken wij simpel en leuk voor je. Zoals de boekhouding.</p>
                    <p>- Het Tellow team</p>
                </TextContent>
                <VisualContent>
                    <img data-at="shape" src={TellowTeam} alt="Tellow team groupphoto" />
                </VisualContent>
            </Row>
            <RegisterButton context="about" style={{ fontSize: '1.3em' }} big>
                Gebruik Tellow gratis
            </RegisterButton>
        </Content>
    </BlockSection>
)
export default AboutBlock
