import React, { forwardRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { RegisterButton } from '../../UI'
import freelancer from '../../../images/logos/partners/svg/freelancer-nl.svg'
import belastingdienst from '../../../images/logos/partners/svg/belastingdienst.svg'
import zzp from '../../../images/logos/partners/zzp.png'
import rabobank from '../../../images/logos/partners/svg/horizontal/rabo-mono.svg'
import mkb from '../../../images/logos/partners/svg/mkb-brandstof.svg'
import ns from '../../../images/logos/partners/svg/ns.svg'
import youngones from '../../../images/logos/partners/youngones.webp'

const styles = {
    group: tw`mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8`,
    wrapper: tw`max-w-7xl grid mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8`,
    title: tw`text-left font-plus-jakarta pb-4 tracking-tight font-bold text-tellow-gray-800 md:text-center max-w-prose leading-tight text-2xl md:text-3xl lg:text-4xl`,
    body: tw`mx-auto mt-20 text-left text-sm opacity-60 antialiased font-normal max-w-prose text-tellow-gray-800`,
    button: tw`mx-auto max-w-xs flex items-center justify-center mt-2 whitespace-pre-wrap break-normal`,
    logo: {
        wrapper: tw`col-span-1 flex justify-center py-8 px-8 items-center`,
        base: tw`w-full max-h-10 object-scale-down mb-0 monotone!`,
    },
}

/**
 * Item variants for Framer Motion.
 */
const item = {
    visible: { opacity: 1, transition: { duration: 1.25, type: 'easeInOut' } },
    hidden: { opacity: 0 },
}

/**
 * Proxy component for Logo.
 */
const Logo = ({ props, src, alt }) => (
    <motion.div key={alt.replace(' ', '')} variants={item} css={styles.logo.wrapper} {...props}>
        <img css={styles.logo.base} src={src} alt={alt} />
    </motion.div>
)

/**
 * Container variants for Framer Motion.
 */
const container = {
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
            duration: 0.15,
        },
    },
    hidden: { opacity: 0 },
}

/**
 * ForwardRef required to use isInView ref.
 */
const Group = forwardRef(({ children, animate }, ref) => (
    <motion.div ref={ref} animate={animate} initial="hidden" variants={container} css={styles.group}>
        {children}
    </motion.div>
))
/**
 * forwardRef requires a displayName to be set.
 */
Group.displayName = 'Group'

/**
 * Wrappers for divs with Tailwind Styles.
 */
const Title = ({ children }) => <h3 css={styles.title}>{children}</h3>
const Wrapper = ({ children }) => <h3 css={styles.wrapper}>{children}</h3>

const Titles = ({ lang: english, content }) => (
    <>
        {english && <Title>Trusted by companies that you trust too</Title>}
        {!english && <Title>{`${content.primary.main_title_white.text} ${content.primary.main_title_green.text}`}</Title>}
    </>
)

/**
 * The actual block of code.
 */
const PartnersBlock = ({ english, content }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    const [companies, setCompanies] = useState([])

    /**
     * Scroll into view
     * lifecycle hook.
     */
    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    const partners = [
        { name: 'Freelancer.nl', source: freelancer },
        { name: 'NS', source: ns },
        { name: 'Rabobank', source: rabobank },
        { name: 'Belastingdienst', source: belastingdienst },
        { name: 'ZZP', source: zzp },
        { name: 'MKB Brandstof', source: mkb },
        { name: 'Young Ones', source: youngones },
    ]

    /**
     * This has to happen in
     * useEffect, otherwise
     * SSR will render one
     * default view, once.
     */
    useEffect(() => {
        setCompanies(
            partners
                /**
                 * The following 'map > sort > map'
                 * flow shuffles the array.
                 *
                 * Then, we only take 6, for
                 * maximum visual pleasure. ;-)
                 */
                .map((a) => [Math.random(), a])
                .sort((a, b) => a[0] - b[0])
                .map((a) => a[1])
                .slice(0, 6)
        )
    }, [])

    return (
        <section css={tw`bg-tellow-white`}>
            {content && (
                <Wrapper>
                    <Titles lang={english} content={content} />

                    <Group ref={ref} animate={controls}>
                        {companies.map(({ name, source }) => (
                            <Logo key={name} src={source} alt={`${name} logo`} />
                        ))}
                    </Group>

                    <p css={styles.body}>{content.primary.description.text}</p>
                    <RegisterButton big purple css={styles.button} href="/reviews">
                        {content.primary.gratis_button.text} &nbsp;
                        <span className="arrow" css={tw`hidden sm:inline-block`}>
                            {'->'}
                        </span>
                    </RegisterButton>
                </Wrapper>
            )}
        </section>
    )
}

export default PartnersBlock
