/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'
import { hexToCSSFilter } from 'hex-to-css-filter'

import ArrowUpImport from './source/streamline-icon-arrow-up-1@24x24.svg'
import CameraImport from './source/streamline-icon-camera-settings-focus@24x24.svg'
import ClickImport from './source/streamline-icon-monitor-touch@24x24.svg'
import GripImport from './source/streamline-icon-cash-payment-bills-1@24x24.svg'
import InvoiceImport from './source/streamline-icon-accounting-invoice-hand@24x24.svg'
import CountImport from './source/streamline-icon-accounting-abacus@24x24.svg'
import MoneyImport from './source/streamline-icon-accounting-coins-bill@24x24.svg'
import AlertImport from './source/streamline-icon-alarm-bell-timer@24x24.svg'
import SpeedImport from './source/streamline-icon-browser-gauge@24x24.svg'
import SupportImport from './source/streamline-icon-conversation-smile-type-1@24x24.svg'
import FloppyImport from './source/streamline-icon-floppy-disk-1@24x24.svg'
import OnOffImport from './source/streamline-icon-settings-toggle-horizontal@24x24.svg'
import TravelImport from './source/streamline-icon-travel-paper-plane@24x24.svg'
import WirelessImport from './source/streamline-icon-wireless-payment-smartphone-1@24x24.svg'
import ComputerImport from './source/streamline-icon-desktop-monitor-smiley-1@24x24.svg'
import ReaderImport from './source/streamline-icon-newspaper-read-man@24x24.svg'
import AddUserImport from './source/streamline-icon-phone-action-add-user@24x24.svg'
import SettingsOnImport from './source/streamline-icon-settings-on@24x24.svg'
import ManGraduateImport from './source/streamline-icon-people-man-graduate@24x24.svg'
import TagEuroImport from './source/streamline-icon-tag-euro@24x24.svg'
import CustomerSupportImport from './source/streamline-icon-headphones-customer-support-human@24x24.svg'
import MagicWandImport from './source/streamline-icon-retouch-magic-wand@24x24.svg'
import MonetizationImport from './source/streamline-icon-monetization-tablet@24x24.svg'
import DealImport from './source/streamline-icon-business-deal-handshake@24x24.svg'
import SmartphoneImport from './source/streamline-icon-dating-smartphone-man@24x24.svg'
import InfoFlagImport from './source/streamline-icon-flag-information@24x24.svg'
import CustomerQuestionImport from './source/streamline-icon-headphones-customer-support-question@24x24.svg'
import InfoCircleImport from './source/streamline-icon-information-circle@24x24.svg'
import TodoListImport from './source/streamline-icon-list-to-do@24x24.svg'
import InfoMessageBubbleImport from './source/streamline-icon-messages-bubble-information@24x24.svg'
import NoteBookImport from './source/streamline-icon-notes-book-text@24x24.svg'
import ChecklistImport from './source/streamline-icon-notes-checklist-flip@24x24.svg'
import ChefImport from './source/streamline-icon-professions-man-chef-3@24x24.svg'
import QuestionSquareImport from './source/streamline-icon-question-help-square@24x24.svg'
import TaxesImport from './source/streamline-icon-credit-card-flag-alternate@20x20.svg'
import ScanBarcodeImport from './source/streamline-icon-barcode-scan@20x20.svg'
import GraphImport from './source/streamline-icon-data-file-bars@20x20.svg'
import ExchangeImport from './source/streamline-icon-business-contract-give@20x20.svg'
import ClockImport from './source/time-clock-circle-1-alternate.svg'
import CalendarImport from './source/calendar.svg'
import CopyImport from './source/copy.svg'
import SearchPersonImport from './source/neutral-search.svg'
import LockImport from './source/streamline-icon-lock-shield@14x14.svg'
import BankImport from './source/streamline-icon-money-bank@14x14b.svg'
import WhiteDocumentImport from './source/streamline-icon-common-file-text-alternate@14x14.svg'
import LoopImport from './source/streamline-icon-button-refresh-arrows@14x14.svg'

import MenuFunctionsImport from './source/menu-functions.svg'
import MenuTagImport from './source/menu-tag.svg'
import MenuReviewsImport from './source/menu-reviews.svg'
import MenuSupportHeadsetImport from './source/menu-support-headset.svg'
import MenuTellowImport from './source/menu-tellow.svg'
import MenuUserImport from './source/menu-user.svg'

import MenuChefImport from './source/menu-chef.svg'
import MenuPriceTagImport from './source/menu-pricetag.svg'
import MenuFeaturesImport from './source/menu-features.svg'
import MenuKeyImport from './source/menu-key.svg'
import MenuAtImport from './source/menu-at.svg'
import MenuBookImport from './source/menu-book.svg'
import MenuCheckImport from './source/menu-check.svg'
import MenuStoreImport from './source/menu-store.svg'
import MenuInfoImport from './source/menu-info.svg'
import MenuSupportImport from './source/menu-support.svg'
import MenuArrowImport from './source/menu-arrow.svg'
import MenuCloseImport from './source/menu-close.svg'
import MenuShieldImport from './source/menu-shield.svg'

const icons = {
    MenuFunctions: MenuFunctionsImport,
    MenuTag: MenuTagImport,
    MenuReviews: MenuReviewsImport,
    MenuSupportHeadset: MenuSupportHeadsetImport,
    MenuTellow: MenuTellowImport,
    MenuUser: MenuUserImport,

    MenuArrow: MenuArrowImport,
    MenuClose: MenuCloseImport,
    MenuShield: MenuShieldImport,
    MenuAt: MenuAtImport,
    MenuBook: MenuBookImport,
    MenuCheck: MenuCheckImport,
    MenuStore: MenuStoreImport,
    MenuInfo: MenuInfoImport,
    MenuSupport: MenuSupportImport,
    MenuKey: MenuKeyImport,
    MenuChef: MenuChefImport,
    MenuPriceTag: MenuPriceTagImport,
    MenuFeatures: MenuFeaturesImport,
    Camera: CameraImport,
    Click: ClickImport,
    Grip: GripImport,
    Invoice: InvoiceImport,
    Count: CountImport,
    Money: MoneyImport,
    Alert: AlertImport,
    Speed: SpeedImport,
    Support: SupportImport,
    Floppy: FloppyImport,
    OnOff: OnOffImport,
    Travel: TravelImport,
    Wireless: WirelessImport,
    Computer: ComputerImport,
    Reader: ReaderImport,
    AddUser: AddUserImport,
    SettingsOn: SettingsOnImport,
    ManGraduate: ManGraduateImport,
    TagEuro: TagEuroImport,
    CustomerSupport: CustomerSupportImport,
    MagicWand: MagicWandImport,
    Monetization: MonetizationImport,
    ArrowUp: ArrowUpImport,
    Deal: DealImport,
    Smartphone: SmartphoneImport,
    InfoFlag: InfoFlagImport,
    CustomerQuestion: CustomerQuestionImport,
    InfoCircle: InfoCircleImport,
    TodoList: TodoListImport,
    InfoMessageBubble: InfoMessageBubbleImport,
    NoteBook: NoteBookImport,
    Checklist: ChecklistImport,
    Chef: ChefImport,
    QuestionSquare: QuestionSquareImport,
    Taxes: TaxesImport,
    ScanBarcode: ScanBarcodeImport,
    Graph: GraphImport,
    Exchange: ExchangeImport,
    Clock: ClockImport,
    Calendar: CalendarImport,
    Copy: CopyImport,
    SearchPerson: SearchPersonImport,
    Lock: LockImport,
    Bank: BankImport,
    Document: WhiteDocumentImport,
    Loop: LoopImport,
}

// make component take props, change color of SVGs later on
const Icon = styled.img`
    display: flex;
    height: ${(props) => (props.size ? `${props.size}px` : '24px')};
    width: ${(props) => (props.size ? `${props.size}px` : '24px')};
    filter: ${(props) => (props.invertColor ? 'invert(1) saturate(200%)' : props.color ? hexToCSSFilter(props.color) : 'none')};
    fill: white;
`
const StreamlineIcon = (props) => <Icon {...props} src={icons[props.icon]} alt={`${props.icon} icon`} />

export default StreamlineIcon
