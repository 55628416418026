import React from 'react'
import styled from 'styled-components'
import { TextContainer } from '../../../utils'
import Link from '../Link/link'

const StyledNewPageToaster = styled.div`
    .seminar-cta {
        margin: 2rem auto;
        padding: 0.5rem;
        text-align: center;
        border: 2px solid ${({ theme }) => theme.color.tellowPurple};
        box-shadow: ${({ theme }) => theme.smoothShadow};
        border-radius: 0.75rem;
        max-width: 1000px;
        top: 0;
        position: relative;

        a {
            color: ${({ theme }) => theme.color.tellowPurple};
            display: inline-block;
            font-size: 0.9rem;
        }

        &__icon {
            font-size: 1.2rem;
            padding-right: 0.5em;
        }

        &.lg-move-up {
            @media screen and (min-width: 1045px) {
                margin: 0 auto;
                position: relative;
                top: -50px;
            }
        }

        .ticker {
            margin: 0;
            line-height: 2.1rem;
        }
    }
`

// TODO: Refactor into re-useable util component.
const NewPageToaster = (props) => (
    <StyledNewPageToaster className={props.className}>
        <TextContainer style={{ textAlign: 'center', padding: 0 }}>
            <div className={props.pullup ? 'seminar-cta lg-move-up' : 'seminar-cta'}>
                <Link to="/corona-steunpakket/">
                    <span className="seminar-cta__icon" />
                    <strong>Getroffen door COVID-19?</strong> Klik hier om te lezen wat je kunt doen.
                </Link>
            </div>
        </TextContainer>
    </StyledNewPageToaster>
)

export default NewPageToaster
