/* eslint-disable react/no-array-index-key */
import React from 'react'
import { styled, css } from 'twin.macro'

const Container = styled.div`
    border-radius: ${({ theme }) => theme.radii.border.full};
    position: relative;
    width: min-content;
    background: ${({ theme }) => theme.color.tellowGray200};
    height: auto;

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`

const StyledToggle = styled.div`
    -webkit-appearance: none !important;
    appearance: none !important;
    padding: 0;
    border: 0;

    display: inline-grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;
    position: relative;

    > *:first-child {
        color: ${(props) => (props.enabled ? 'white' : 'inherit')};
    }

    > *:last-child {
        color: ${(props) => (props.enabled ? 'inherit' : 'white')};
    }
`

const Background = styled.span`
    transform: ${(props) => (props.enabled ? css`translateX(0)` : css`translateX(calc(100% + .5rem))`)};
    transition: transform 350ms ease-in-out;
    will-change: transform;

    position: absolute;
    left: 0.25rem;
    top: 0.25rem;

    border-radius: ${({ theme }) => theme.radii.border.full};
    display: inline-block;
    background: ${({ theme }) => theme.color.tellowPurple};
    height: calc(100% - 0.5rem);
    width: calc(100% / 2 - 0.5rem);
`

const Option = styled.button`
    background: unset;
    appearance: none;
    cursor: pointer;
    border: 0;

    z-index: 2;
    width: 6.5rem;
    text-align: center;
    padding: 0.5rem 1rem;

    font-size: clamp(0.5rem, 5vw + 0.25rem, 0.75rem);
    font-weight: 500;

    transition: color 150ms ease-in-out;
    will-change: color;
`

const Toggle = ({ options = ['First', 'Second'], value, onChange }) => (
    <Container onClick={() => onChange(!value)}>
        <StyledToggle enabled={value}>
            {options.map((option, idx) => (
                <Option key={`toggle-option-${idx}`}>{option}</Option>
            ))}
        </StyledToggle>
        <Background enabled={value} />
    </Container>
)

export default Toggle
