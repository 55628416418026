import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import PartnerBlock from '../../components/pages/homepage/partnersBlock'
import { SocialProofBlock } from '../../components/blocks'
import CTABlock from '../../components/pages/whitepaper/CTABlock'
import WhitepaperHero from '../../components/pages/whitepaper/whitepaperHero'
import ColumnHero from '../../components/pages/whitepaper/columnHero'
import SummaryBlock from '../../components/pages/whitepaper/summaryBlock'
import { reviewStructuredData } from '../../components/layout/meta/schema-org'

export const whitepaperQuery = graphql`
    query WhitepaperBySlug($uid: String!) {
        prismicWhitepaper(uid: { eq: $uid }) {
            _previewable
            data {
                color
                whitepaper_download_url {
                    url
                }
                bulletpoint_image {
                    alt
                    url
                }
                bulletpoints {
                    bulletpoint {
                        text
                        html
                    }
                }
                content {
                    text
                    html
                }
                content_header {
                    text
                    html
                }
                hero_image {
                    url
                    alt
                }
                label {
                    text
                }
                meta_description {
                    text
                }
                meta_title {
                    text
                }
                pre_cta {
                    html
                    text
                }
                title {
                    html
                    text
                }
                subkop {
                    text
                    html
                }
            }
            uid
            id
        }
    }
`

const Whitepaper = ({ data }) => {
    // Shorthand for whitepaper data
    const dpWd = data.prismicWhitepaper.data
    const dlUrl = dpWd.whitepaper_download_url ? dpWd.whitepaper_download_url.url : null

    // Setting up basic data, easier formatting
    const bulletpoints = []
    dpWd.bulletpoints.forEach((point) => bulletpoints.push(point.bulletpoint.text.toString()))

    // Set color fetched from Prismic
    const { color } = dpWd
    function setColor(c) {
        // Waterfall if-statement; final else returns 'lightGrey' hex-code.
        if (c === 'Orange') {
            return '#FBB36B'
        }
        if (c === 'Blue') {
            return '#52B9E9'
        }
        if (c === 'Darkblue') {
            return '#011532'
        }
        return '#E2E2E2'
    }

    return (
        <Layout>
            <Meta
                title={dpWd.meta_title.text || dpWd.title.text}
                description={dpWd.meta_description.text}
                image={dpWd.hero_image.url || ''}
                path={`/whitepapers/${data.prismicWhitepaper.uid}`}
                jsonLD={reviewStructuredData}
            />
            <WhitepaperHero title={dpWd.title.text} subtitle={dpWd.subkop.text} url={dpWd.hero_image.url} alt={dpWd.hero_image.alt} label={dpWd.label.text} downloadUrl={dlUrl} />
            <ColumnHero title={dpWd.content_header.text} body={dpWd.content.html} color={setColor(color)} />
            <SummaryBlock data={bulletpoints} url={dpWd.bulletpoint_image.url} alt={dpWd.bulletpoint_image.alt} />
            <CTABlock data={dpWd.pre_cta.text} label={dpWd.label.text} url={dlUrl} />
            <SocialProofBlock />
            <PartnerBlock />
        </Layout>
    )
}

export default withPrismicPreview(Whitepaper)
