import React from 'react'
import { lighten } from 'polished'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import { RegisterButton } from '../../UI'
import Trusted from './trusted'
import HomePageImage from '../../../images/home/home-page-image.png'

const style = {
    section: tw`relative overflow-hidden h-full`,
    video: tw`absolute h-full w-full top-0 inset-x-0 object-center object-cover mix-blend-multiply`,
    layout: {
        wrap: tw`max-w-7xl pt-10 mx-auto px-4 sm:px-6 lg:px-8`,
        container: tw`relative flex flex-col items-start mx-auto`,
    },
    title: tw`z-10 block whitespace-pre-line items-start text-white font-black tracking-tight antialiased mb-2 mt-4 leading-tight`,
    body: tw`antialiased text-left py-4 font-normal text-white mb-8 opacity-70`,
    green: tw`text-tellow-green`,
    iphone: tw`z-0 relative hidden sm:block sm:w-32 md:w-36 lg:w-40 xl:w-48`,
    item: tw`text-base text-gray-600 font-bold tracking-tight antialiased ml-2 mb-2`,
}

const Mockup = ({ content }) => (
    <div css={tw`relative w-full lg:w-4/5 mx-auto relative z-10 mt-10`}>
        <img css={tw`relative z-20`} src={HomePageImage} alt={content.primary.main_image.alt} />
        <div style={{ margin: '0 calc(80% - 80vw)', marginBottom: '-1px' }} css={tw`absolute h-1/2 bg-white left-0 right-0 bottom-0`} />
    </div>
)

const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5,
        },
    },
}

const item = (opacity = 0) => ({
    hidden: { opacity, y: 15 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.35,
            type: 'spring',
            damping: 30,
            mass: 1,
        },
    },
})

function template({ y }) {
    return `translateY(${y})`
}

const Prose = styled(motion.p)`
    max-width: ${(props) => `var(--max-width, ${props.width})`};
    font-size: clamp(0.875rem, 0.625rem + 1.1111vw, 1.25rem);
    line-height: 1.35;
`

const H1 = styled(motion.h1)`
    margin: 0 !important;

    > span > span {
        color: #01ff94;
    }
`

const Text = ({ content }) => (
    <motion.div variants={container} initial="hidden" animate="show">
        <H1
            variants={item(0.5)}
            transformTemplate={template}
            style={{
                fontSize: 'clamp(1.375rem, -0.375rem + 7.7778vw, 4rem)',
                lineHeight: 1.15,
                fontWeight: 600,
                maxWidth: `17ch`,
            }}
            css={style.title}
        >
            <span style={{ maxWidth: '17ch !important' }}>
                <span>Gratis</span> bankieren
            </span>
        </H1>
        <H1
            variants={item()}
            transformTemplate={template}
            style={{
                fontSize: 'clamp(1.375rem, -0.375rem + 7.7778vw, 4rem)',
                lineHeight: 1.15,
                fontWeight: 600,
                maxWidth: `17ch`,
            }}
            css={style.title}
        >
            <span style={{ maxWidth: '17ch !important' }}>
                <span>Gratis</span> facturen sturen
            </span>
        </H1>
        <H1
            variants={item()}
            transformTemplate={template}
            style={{
                fontSize: 'clamp(1.375rem, -0.375rem + 7.7778vw, 4rem)',
                lineHeight: 1.15,
                fontWeight: 600,
                maxWidth: `17ch`,
            }}
            css={style.title}
        >
            <span style={{ maxWidth: '17ch !important' }}>
                <span>Automatisch</span> boekhouden
            </span>
        </H1>
        <Prose width="52ch" variants={item()} transformTemplate={template} css={style.body}>
            <span>
                Je boekhouding, zakelijke bankrekening en al je financiële zaken veilig in één app. Run je onderneming snel, gemakkelijk en efficiënt met onze slimme technologie.
            </span>
        </Prose>
        <motion.div variants={item()} transformTemplate={template}>
            <RegisterButton white big style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Direct beginnen &nbsp;</span>
                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                    →
                </span>
            </RegisterButton>
        </motion.div>
    </motion.div>
)

const noise = `data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='1.84' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")'/%3E%3C/svg%3E`
const GradientSection = styled.section`
    /* background-image: radial-gradient(circle at top right, #5950ec 0%, transparent 25%), linear-gradient(175deg, #5950ec 20%, transparent 55%),
        linear-gradient(to right, #261fa2, #857ef1, #eba1ec 40%, #f0e8e8) , url(${noise}) !important; */

    /* Move the element up a notch to prevent white menu loading. */
    margin-top: -4rem;
    padding-top: 4rem;

    /* Set a nice gradient. */
    background-color: ${({ theme }) => theme.color.tellowPurple};
    background-image: radial-gradient(circle at top right, ${({ theme }) => theme.color.tellowPurple} 0%, transparent 25%),
        linear-gradient(175deg, ${({ theme }) => theme.color.tellowPurple} 20%, transparent 55%),
        linear-gradient(to right, ${({ theme }) => theme.color.tellowPurple}, ${({ theme }) => lighten(0.1, theme.color.tellowPurple)}) !important;
`

const Hero = React.forwardRef(({ content }, ref) => (
    <GradientSection ref={ref} css={style.section}>
        <div css={style.layout.wrap}>
            <div css={style.layout.container}>
                <Text content={content} />
            </div>
            <Mockup content={content} />
        </div>
        <Trusted />
    </GradientSection>
))

Hero.displayName = 'Hero'

export default Hero
