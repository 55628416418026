import React from 'react'
import styled from 'styled-components'

const HeaderImage = styled.div`
    height: 10rem;
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    background: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${(props) => props.position};
    margin: 0 0 0 auto;
    filter: opacity(0.89);
    @media screen and (min-width: 768px) {
        height: 29.5rem;
        width: 30rem;
    }
    @media screen and (max-width: 1200px) and (min-width: 768px) {
        height: 90%;
        width: 80%;
    }
`

const HeaderImageShape = ({ ...props }) => <HeaderImage {...props} />

export default HeaderImageShape
