import React from 'react'
import styled from 'styled-components'
import { Link } from '../UI'
import TellowLogo from '../../images/logos/tellow/logo'
import FacebookIcon from '../../images/footer/Facebook.png'
import InstagramIcon from '../../images/footer/Instagram.png'
import LinkedinIcon from '../../images/footer/LinkedIn.png'
import config from '../../config'
import { useTranslate } from '../../hooks'

const FooterContainer = styled.footer`
    padding: 4rem 0;
    font-size: 0.8em;
    background: ${(props) => props.theme.color.tellowGray200};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &,
    a:link,
    a:visited {
        color: ${(props) => props.theme.color.tellowGray700};
    }

    a {
        transition: color 0.1s ease-in-out;
    }

    h2 {
        font-size: 1.3em;
        line-height: 1.4;
        margin-bottom: 0;
        max-width: 11rem;
    }

    h3 {
        font-size: 1.1em;
        line-height: 1.6;
    }

    .mobile {
        display: none;
    }

    a:hover {
        color: ${(props) => props.theme.color.tellowGray800};
    }

    @media screen and (min-width: 775px) {
        font-size: 0.8em;

        h3 {
            font-size: 0.8em;
        }
    }

    @media screen and (min-width: 1100px) {
        font-size: 0.9em;

        h2 {
            font-size: 1.8em;
        }

        h3 {
            font-size: 1.2em;
        }
    }
`

const ColumnsWrap = styled.div`
    padding: 0 2rem;
    margin: 0 auto;
    max-width: 1200px;

    display: grid;
    column-gap: 1rem;
    row-gap: 3rem;
    grid-template-columns: 222px repeat(4, 1fr);

    .logo {
        color: ${(props) => props.theme.color.tellowPurple};
    }

    .item .allFeatures {
        color: ${(props) => props.theme.color.tellowPurple};
        font-weight: 600;
    }

    .item .socialIcons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin: 2rem 0 0 0;
    }
    .title {
        font-weight: bold;
        padding-bottom: 0.5em;
    }
    .text {
        font-size: 14px;
        display: flex;
        flex-direction: row;
    }

    .dummy {
        display: none;
    }

    .span-two {
        grid-column: 2 / 4;
    }

    .item .features {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    }

    .item {
        flex: 1 1 calc(50% - 1em);
        list-style-type: none;

        // Increase size of tap target
        a {
            display: block;
        }

        ul {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            li {
                margin-bottom: 0.5em;
            }
        }
    }

    @media screen and (max-width: 1115px) {
        grid-template-columns: repeat(3, 1fr);

        .dummy {
            display: block;
        }
    }

    @media screen and (max-width: 775px) {
        grid-template-columns: repeat(2, 1fr);

        .text {
            font-size: 12px;
        }

        .mobile {
            display: block;
            margin-top: -2rem;
        }
        .desktop,
        .dummy {
            display: none;
        }

        .desktop-logo,
        .span-two,
        .mobile {
            grid-column: 1 / 3;
        }
    }

    @media screen and (max-width: 360px) {
        grid-template-columns: 1fr;

        .mobile,
        .span-two,
        .desktop-logo {
            grid-column: 1;
        }

        .item .features {
            grid-template-columns: 1fr;
        }
    }
`

const Copyright = styled.div`
    margin: 0 auto;
    padding: 0rem;
    max-width: 1200px;
    opacity: 0.8;
    font-size: 0.8em;
`

const FooterLink = styled(Link)`
    display: inline-block;

    img {
        width: 24px;
        height: 24px;
    }
`

const Footer = () => {
    const { t } = useTranslate('components', 'footer')

    return (
        <FooterContainer>
            <ColumnsWrap className="no-print">
                <div className="item desktop-logo">
                    <TellowLogo width="125" className="logo" />
                    <div className="desktop">
                        <ul className="socialIcons">
                            <li>
                                <FooterLink to="https://nl-nl.facebook.com/hello.tellow/">
                                    <img src={FacebookIcon} alt="Facebook" />
                                </FooterLink>
                            </li>
                            <li>
                                <FooterLink to="https://www.instagram.com/hellotellow/">
                                    <img src={InstagramIcon} alt="Instagram" />
                                </FooterLink>
                            </li>
                            <li>
                                <FooterLink to="https://nl.linkedin.com/company/tellow/">
                                    <img src={LinkedinIcon} alt="Linkedin" />
                                </FooterLink>
                            </li>
                        </ul>
                        <Copyright>
                            &#9400; 2015 &ndash; {new Date().getFullYear()} Tellow B.V. <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;{t.allRightsReserved} <br />
                            <br />
                            &#9829; {t.madeWithLove} {config.address.city}
                        </Copyright>
                    </div>
                </div>

                <nav className="item span-two">
                    <li className="title">{t.features}</li>
                    <div className="features">
                        <ul className="text">
                            <li>
                                <FooterLink to="/functies/facturen/">{t.invoices}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/offertes/">{t.quotes}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/bonnen/">{t.scanningReceipts}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/koppel-je-rekening/">{t.bankLinking}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/urenregistratie/">{t.hourRegistration}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/urenregistratie/">{t.kilometerRegisteration}</FooterLink>
                            </li>
                        </ul>
                        <ul className="text">
                            <li>
                                <FooterLink to="/functies/btw-aangifte/">{t.btw}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/inkomstenbelasting/">{t.incomeTax}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/zakelijke-rekening-openen/">{t.paymentCards}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/plan/compleet/">{t.advice}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/rapportages/">{t.report}</FooterLink>
                            </li>
                            <li>
                                <FooterLink to="/functies/" className="allFeatures">
                                    <i>{t.allFeatures}</i>
                                </FooterLink>
                            </li>
                        </ul>
                    </div>
                </nav>

                <nav className="item dummy" />

                <nav className="item">
                    <li className="title">{t.forOurClients}</li>
                    <ul className="text">
                        <li>
                            <FooterLink to="/gratis-factuur-voorbeeld/">{t.freeInvoicingExamples}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/voor-wie/">{t.forWhom}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/blog/">{t.blog}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/reviews/">{t.customerReview}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/support/">{t.support}</FooterLink>
                        </li>
                    </ul>
                </nav>

                <nav className="item">
                    <li className="title">{t.about}</li>
                    <ul className="text">
                        <li>
                            <FooterLink to="/over-ons/">{t.ourStory}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/lp/in-de-media/">{t.publicationInMedia}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/prijs/">{t.subscriptions}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="https://www.ageras.com/us/careers/">{t.workAtTellow}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/privacy/">{t.privacyAndSecurity}</FooterLink>
                        </li>
                        <li>
                            <FooterLink to="/voorwaarden/">{t.generalConditions}</FooterLink>
                        </li>
                    </ul>
                </nav>

                <div className="mobile">
                    <nav className="item">
                        <ul className="text socialIcons">
                            <li>
                                <FooterLink to="https://nl-nl.facebook.com/hello.tellow/">
                                    <img src={FacebookIcon} alt="Facebook" />
                                </FooterLink>
                            </li>
                            <li>
                                <FooterLink to="https://www.instagram.com/hellotellow/">
                                    <img src={InstagramIcon} alt="Instagram" />
                                </FooterLink>
                            </li>
                            <li>
                                <FooterLink to="https://nl.linkedin.com/company/tellow/">
                                    <img src={LinkedinIcon} alt="Linkedin" />
                                </FooterLink>
                            </li>
                        </ul>
                    </nav>
                    <Copyright>
                        &#9400; 2015 &ndash; {new Date().getFullYear()} Tellow B.V. <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;{t.allRightsReserved} <br />
                        <br />
                        &#9829; {t.madeWithLove} {config.address.city}
                    </Copyright>
                </div>
            </ColumnsWrap>
        </FooterContainer>
    )
}

export default Footer
