import React from 'react'

import { Heading } from '../../typography'
import CTA from '../../shared/CTA'

import { Container, Hero } from '../../../utils'

const CTABlock = ({ data, label }) => (
    <Hero
        style={{
            margin: '0 auto',
            padding: '1rem 0 3rem 0',
        }}
    >
        <Container
            style={{
                maxWidth: 850,
                margin: '0 auto',
                padding: 0,
            }}
        >
            <Heading size={25} style={{ textAlign: 'center' }}>
                <div>{data}</div>
            </Heading>
            <CTA context="starter" labelName={label} />
        </Container>
    </Hero>
)

export default CTABlock
