import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout, Meta } from '../../components/layout'
import { Hero, PartnersBlock, FeatureBlock, AppsBlock, SupportBlock } from '../../components/pages/homepage'

const Home = ({ data }) => {
    const home = data.prismicHomepage.data

    return (
        <Layout>
            <Meta name="robots" indexed="noindex" title={home.meta_title.text} description={home.meta_desc.text} path={`/home/${data.prismicHomepage.uid}`} />
            {home.body.map((slice) => {
                switch (slice.slice_type) {
                    case 'hero':
                        return <Hero className="hero" key={slice} content={slice} />
                    case 'featureblock':
                        return <FeatureBlock className="featureblock" key={slice} content={slice} />
                    case 'appsblock':
                        return <AppsBlock className="appsblock" key={slice} content={slice} />
                    case 'partnersblock':
                        return <PartnersBlock className="partnersblock" key={slice} content={slice} />
                    case 'supportblock':
                        return <SupportBlock className="supportblock" key={slice} content={slice} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}
export default withPrismicPreview(Home)

export const query = graphql`
    query MyQuery($uid: String) {
        prismicHomepage(uid: { eq: $uid }) {
            _previewable
            data {
                body {
                    ... on PrismicHomepageDataBodyHero {
                        id
                        slice_type
                        primary {
                            title_1 {
                                text
                            }
                            title_2 {
                                text
                            }
                            description {
                                text
                            }
                            main_image {
                                url
                                alt
                            }
                            placeholder {
                                text
                            }
                            copy_waiting_time {
                                text
                            }
                            signup_button {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageDataBodyFeatureblock {
                        id
                        slice_type
                        items {
                            functions_name {
                                text
                            }
                            explanation {
                                text
                            }
                            function_image {
                                url
                            }
                            icon_name {
                                text
                            }
                            path {
                                text
                            }
                        }
                        primary {
                            functions {
                                text
                            }
                            sub_function {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageDataBodyAppsblock {
                        id
                        slice_type
                        primary {
                            announcer {
                                text
                            }
                            app_title {
                                text
                            }
                            app_title_after_freelancer_number {
                                text
                            }
                            primary_button {
                                text
                            }
                            review_buton {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageDataBodyPartnersblock {
                        id
                        slice_type
                        items {
                            partners_image {
                                url
                            }
                        }
                        primary {
                            main_title_white {
                                text
                            }
                            main_title_green {
                                text
                            }
                            description {
                                text
                            }
                            gratis_button {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageDataBodySupportblock {
                        id
                        slice_type
                        primary {
                            success_announcer {
                                text
                            }
                            support_title {
                                text
                            }
                            description {
                                text
                            }
                            call_button {
                                text
                            }
                            change_offer {
                                text
                            }
                        }
                    }
                }
                meta_desc {
                    text
                }
                meta_title {
                    text
                }
            }
            uid
        }
    }
`
