import React from 'react'
import styled from 'styled-components'
import StreamlineIcon from '../../images/icons/streamline'
import { convertToReadableDate } from '../../utils/prismic/dateConverter'

const ReadingTimeStyled = styled.div`
    font-size: 0.8rem;
    opacity: 0.8;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 5px;
    color: ${(props) => props.color && `${props.color}`};

    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 320px) {
        width: 100%;
        flex-direction: row;
    }

    div {
        display: flex;
        align-items: center;
    }

    time:after {
        content: ${(props) => props.withDot && `'\\00B7'`};
        padding: ${(props) => props.withDot && `0 5px`};
        display: ${(props) => props.withDot && `inline-block`};
        font-weight: ${(props) => props.withDot && `bold`};
    }

    time,
    p {
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        padding-right: ${(props) => (props.paddingRight ? `${props.paddingRight}` : `2rem`)};

        @media screen and (max-width: 400px) {
            padding-right: ${(props) => (props.paddingRight ? `${props.paddingRight}` : `1.5rem`)};
        }

        ${({ blogCategory }) =>
            blogCategory &&
            `
        @media screen and (max-width: 965px) {
            font-size: 10px;
            line-height: 15px;
        }
    }
  `}
    }

    img {
        opacity: 0.8;
        margin-right: 0.5rem;
        margin-bottom: 0;
    }
`

const ArticleMeta = (props) => (
    <ReadingTimeStyled paddingRight={props.paddingRight} color={props.metaColor} withDot={props.withDot} blogCategory>
        <div>
            {props.withIcons && <StreamlineIcon size="18" icon="Calendar" />}
            {props.lastPublicationDate ? <time>{convertToReadableDate(props.lastPublicationDate)}</time> : null}
        </div>

        <div>
            {props.withIcons && <StreamlineIcon size="18" icon="Clock" />}
            <p>{props.readingTime}</p>
        </div>
    </ReadingTimeStyled>
)

export default ArticleMeta
