import React from 'react'

const AppStore = ({ fill }) => (
    <svg viewBox="0 0 101 23" style={{ width: '100%' }} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.7689 12.3007C15.7796 11.466 16.0013 10.6477 16.4134 9.9217C16.8254 9.19575 17.4143 8.58581 18.1254 8.14864C17.6737 7.50348 17.0778 6.97253 16.3849 6.59798C15.6921 6.22343 14.9215 6.01559 14.1343 5.99096C12.455 5.8147 10.8271 6.99579 9.97138 6.99579C9.09911 6.99579 7.78161 6.00846 6.36288 6.03765C5.44521 6.06729 4.55086 6.33415 3.76698 6.81221C2.9831 7.29026 2.33643 7.96322 1.88996 8.76551C-0.0440401 12.1139 1.39855 17.035 3.25116 19.7416C4.17806 21.0669 5.26134 22.5474 6.67879 22.4949C8.06585 22.4374 8.58389 21.6104 10.2582 21.6104C11.917 21.6104 12.403 22.4949 13.8492 22.4615C15.3376 22.4374 16.2753 21.1303 17.1697 19.7924C17.8357 18.848 18.3481 17.8043 18.6881 16.6999C17.8234 16.3341 17.0855 15.722 16.5664 14.9397C16.0472 14.1574 15.7699 13.2396 15.7689 12.3007V12.3007Z"
            fill={fill}
        />
        <path
            d="M13.0373 4.2109C13.8488 3.23669 14.2486 1.98451 14.1518 0.720276C12.9119 0.850496 11.7667 1.44306 10.9442 2.37989C10.542 2.83755 10.2341 3.36999 10.0378 3.94676C9.84156 4.52353 9.76092 5.13332 9.80048 5.74128C10.4206 5.74766 11.0341 5.61325 11.5948 5.34816C12.1554 5.08307 12.6487 4.69422 13.0373 4.2109Z"
            fill={fill}
        />
        <path
            d="M33.3023 15.6932H28.5689L27.4322 19.0496H25.4273L29.9107 6.63162H31.9937L36.4771 19.0496H34.438L33.3023 15.6932ZM29.0591 14.1443H32.8111L30.9615 8.69707H30.9097L29.0591 14.1443Z"
            fill={fill}
        />
        <path
            d="M46.1597 14.5233C46.1597 17.3367 44.6538 19.1443 42.3814 19.1443C41.8057 19.1745 41.2332 19.0419 40.7294 18.7617C40.2256 18.4816 39.8109 18.0652 39.5327 17.5603H39.4897V22.0447H37.6313V9.99591H39.4302V11.5018H39.4644C39.7553 10.9993 40.1771 10.5851 40.6847 10.3033C41.1923 10.0214 41.7669 9.88246 42.3472 9.90117C44.645 9.90118 46.1597 11.7176 46.1597 14.5233ZM44.2495 14.5233C44.2495 12.6903 43.3023 11.4852 41.8569 11.4852C40.437 11.4852 39.4819 12.7156 39.4819 14.5233C39.4819 16.3475 40.437 17.5692 41.8569 17.5692C43.3023 17.5692 44.2495 16.3729 44.2495 14.5233Z"
            fill={fill}
        />
        <path
            d="M56.1245 14.5232C56.1245 17.3367 54.6187 19.1443 52.3462 19.1443C51.7706 19.1744 51.1981 19.0418 50.6943 18.7617C50.1905 18.4816 49.7757 18.0652 49.4976 17.5603H49.4546V22.0447H47.5962V9.9959H49.395V11.5018H49.4292C49.7201 10.9993 50.1419 10.5851 50.6495 10.3033C51.1571 10.0214 51.7317 9.88246 52.312 9.90118C54.6099 9.90118 56.1245 11.7176 56.1245 14.5232ZM54.2144 14.5232C54.2144 12.6902 53.2671 11.4852 51.8218 11.4852C50.4019 11.4852 49.4468 12.7156 49.4468 14.5232C49.4468 16.3475 50.4019 17.5691 51.8218 17.5691C53.2671 17.5691 54.2144 16.3729 54.2144 14.5232H54.2144Z"
            fill={fill}
        />
        <path
            d="M62.7105 15.5897C62.8482 16.8211 64.0445 17.6297 65.6792 17.6297C67.2456 17.6297 68.3726 16.8211 68.3726 15.7107C68.3726 14.7468 67.6929 14.1697 66.0835 13.7742L64.4742 13.3865C62.1939 12.8357 61.1353 11.7693 61.1353 10.0388C61.1353 7.89624 63.0025 6.42456 65.6538 6.42456C68.2778 6.42456 70.0767 7.89624 70.1372 10.0388H68.2612C68.1489 8.79956 67.1245 8.05152 65.6274 8.05152C64.1304 8.05152 63.106 8.80836 63.106 9.90992C63.106 10.7878 63.7603 11.3045 65.3608 11.6999L66.729 12.0359C69.2768 12.6384 70.3354 13.6619 70.3354 15.4782C70.3354 17.8015 68.4849 19.2566 65.5415 19.2566C62.7876 19.2566 60.9282 17.8357 60.8081 15.5896L62.7105 15.5897Z"
            fill={fill}
        />
        <path
            d="M74.3462 7.85333V9.99591H76.0679V11.4676H74.3462V16.4588C74.3462 17.2342 74.6909 17.5955 75.4478 17.5955C75.6522 17.592 75.8562 17.5776 76.0591 17.5525V19.0154C75.7188 19.079 75.373 19.1078 75.0269 19.1014C73.1939 19.1014 72.479 18.4129 72.479 16.657V11.4676H71.1626V9.99591H72.479V7.85333H74.3462Z"
            fill={fill}
        />
        <path
            d="M77.065 14.5233C77.065 11.6746 78.7427 9.88458 81.3589 9.88458C83.9839 9.88458 85.6539 11.6746 85.6539 14.5233C85.6539 17.3797 83.9927 19.1619 81.3589 19.1619C78.7261 19.1619 77.065 17.3797 77.065 14.5233ZM83.7603 14.5233C83.7603 12.5692 82.8648 11.4158 81.3589 11.4158C79.8531 11.4158 78.9585 12.5779 78.9585 14.5233C78.9585 16.4852 79.8531 17.6297 81.3589 17.6297C82.8648 17.6297 83.7603 16.4852 83.7603 14.5233H83.7603Z"
            fill={fill}
        />
        <path
            d="M87.1861 9.99591H88.9585V11.5369H89.0015C89.1214 11.0556 89.4034 10.6303 89.8 10.3324C90.1966 10.0345 90.6836 9.88225 91.1793 9.90117C91.3934 9.90042 91.607 9.92368 91.816 9.97051V11.7088C91.5456 11.6262 91.2636 11.5882 90.981 11.5965C90.711 11.5855 90.4418 11.6331 90.192 11.736C89.9422 11.8389 89.7175 11.9946 89.5336 12.1925C89.3496 12.3904 89.2106 12.6258 89.1262 12.8825C89.0418 13.1391 89.0139 13.411 89.0444 13.6795V19.0496H87.1861L87.1861 9.99591Z"
            fill={fill}
        />
        <path
            d="M100.384 16.3904C100.134 18.034 98.5337 19.1619 96.4859 19.1619C93.8521 19.1619 92.2173 17.3973 92.2173 14.5662C92.2173 11.7264 93.8609 9.88458 96.4077 9.88458C98.9126 9.88458 100.488 11.6053 100.488 14.3504V14.9871H94.0933V15.0994C94.0638 15.4326 94.1055 15.7683 94.2157 16.0841C94.3258 16.4 94.502 16.6888 94.7323 16.9313C94.9627 17.1738 95.242 17.3646 95.5517 17.4909C95.8615 17.6172 96.1946 17.6762 96.5288 17.6639C96.968 17.705 97.4087 17.6033 97.7854 17.3739C98.1621 17.1444 98.4547 16.7995 98.6197 16.3904L100.384 16.3904ZM94.1021 13.6883H98.6284C98.6451 13.3887 98.5997 13.0889 98.495 12.8077C98.3903 12.5265 98.2287 12.2699 98.0201 12.0542C97.8116 11.8384 97.5608 11.6681 97.2833 11.5538C97.0058 11.4396 96.7077 11.384 96.4077 11.3904C96.1051 11.3886 95.8051 11.4468 95.5251 11.5615C95.245 11.6763 94.9905 11.8453 94.7761 12.059C94.5618 12.2726 94.3918 12.5266 94.2761 12.8062C94.1605 13.0859 94.1013 13.3857 94.1021 13.6883V13.6883Z"
            fill={fill}
        />
    </svg>
)

export default AppStore
