import React from 'react'
import styled from 'styled-components'
import readingTime from 'reading-time'
import { ColoredBackground } from './UI'
import BlogPostCard from '../templates/prismic/blog-post-card'
import Carousel from './pages/reviews/carousel'
import { RelativeContainer } from './pages/voor-wie/styled'

const CarouselBlockTitle = styled.div`
    text-align: center;
    margin: 80px 0 0;
    color: ${(props) => props.theme.color.tellowBlack};

    @media screen and (max-width: 600px) {
        padding: 0 15px;
        margin: 60px 0 0;
    }
`

const CarouselWrapper = styled.div`
    overflow: hidden;
    width: 100%;
`

const Link = styled.a`
    display: contents;
`

const Related = (props) => {
    if (!props.edges.length) return null
    return (
        <ColoredBackground white>
            <CarouselBlockTitle>
                <h2>Wil je meer te weten komen? Lees verder</h2>
            </CarouselBlockTitle>

            <CarouselWrapper>
                <RelativeContainer>
                    <Carousel bulletColor="#D9D9D9" bulletInactiveColor="transparent" bulletBorderColor="#dadada" amount={props.edges.length}>
                        {props.edges.map((i) => (
                            <Link key={i.node.uid} title={i.node.uid} href={`/blog/${i.node.uid}`}>
                                <BlogPostCard
                                    key={i.node.uid}
                                    fluid={i.node.data.headerimage.fluid}
                                    previewImgAlt={i.node.data.headerimage.alt}
                                    title={i.node.data.title.text}
                                    description={i.node.data.excerpt}
                                    lastPublicationDate={i.node.data.post_date}
                                    readingTime={readingTime(i.node.data.content.text).text.replace('min read', 'min leestijd')}
                                    paddingRight="0"
                                    metaColor="#9DA7B3"
                                    tag={i.node.data.tags[0].tag.document.data.tag_name}
                                    withDot
                                />
                            </Link>
                        ))}
                    </Carousel>
                </RelativeContainer>
            </CarouselWrapper>
        </ColoredBackground>
    )
}

export default Related
