import React from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Bank from '../../../../images/englishlp/icons/bank.js'
import Invoice from '../../../../images/englishlp/icons/invoice.js'
import Scanning from '../../../../images/englishlp/icons/scanning.js'
import Btw from '../../../../images/englishlp/icons/btw.js'
import Quotes from '../../../../images/englishlp/icons/quote.js'
import Graph from '../../../../images/invoicelp/graph.js'
import Signet from '../../../../images/invoicelp/signet.js'
import { useTranslate } from '../../../../hooks'
import Link from '../../Link/link.js'
import TimeClockIcon from '../../Icons/timeClock.js'
import IncomeTaxIcon from '../../Icons/incomeTax.js'
import SupportIcon from '../../Icons/supportIcon.js'

const style = {
    section: tw`fixed w-full bg-tellow-white py-2 z-40 top-16`,
    grid: tw`grid grid-cols-4 gap-3`,
    content: tw`flex flex-col h-auto items-start md:px-8 py-4 max-w-7xl left-0 mx-auto text-tellow-black!`,
    backgroundCards: tw`flex items-start h-full hover:rounded-lg hover:bg-tellow-gray-100 p-4`,
    title: tw`antialiased text-sm tracking-tight mb-0 text-tellow-gray-800!`,
    text: tw`antialiased text-left text-xs font-normal mb-0 opacity-80 text-tellow-gray-800!`,
    svg: tw`h-10 w-10 fill-none! flex-initial text-tellow-purple mr-4 rounded-full bg-tellow-purple bg-opacity-10 justify-center items-center p-2`,
    button: tw`text-tellow-purple! antialiased tracking-tight text-center text-sm font-semibold items-center h-auto w-auto flex items-center justify-center`,
}

const Copy = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
`

const data = [
    {
        Icon: Invoice,
        i18nKey: 'invoices',
        path: `facturen`,
    },
    {
        Icon: Quotes,
        i18nKey: 'quotes',
        path: `offertes`,
    },
    {
        Icon: Bank,
        i18nKey: 'banking',
        path: `koppel-je-rekening`,
    },
    {
        Icon: Scanning,
        i18nKey: 'scanning',
        path: `bonnen`,
    },
    {
        Icon: Graph,
        i18nKey: 'reporting',
        path: `rapportages`,
    },
    {
        Icon: Btw,
        i18nKey: 'taxes',
        path: `btw-aangifte`,
    },
    {
        Icon: Signet,
        i18nKey: 'bookkeeping',
        path: `boekhouding`,
    },
    {
        Icon: TimeClockIcon,
        i18nKey: 'hourRegistration',
        path: `urenregistratie`,
    },
    {
        Icon: IncomeTaxIcon,
        i18nKey: 'incomeTax',
        path: `inkomstenbelasting`,
    },
    {
        Icon: SupportIcon,
        i18nKey: 'support',
        featurePath: `support`,
    },
]

const Dropdown = () => {
    const { t } = useTranslate('components', 'menu.submenus')

    return (
        <motion.div css={style.content}>
            <div css={style.grid}>
                {data.map(({ Icon, i18nKey, path, featurePath }) => (
                    <Link to={path ? `/functies/${path}` : `/${featurePath}`} key={t[i18nKey]?.title}>
                        <div key={t[i18nKey]?.title} css={style.backgroundCards}>
                            <div css={style.svg}>
                                <Icon />
                            </div>
                            <Copy>
                                <p css={style.title}>{t[i18nKey]?.title}</p>
                                <p css={style.text}>{t[i18nKey]?.body}</p>
                            </Copy>
                        </div>
                    </Link>
                ))}
                <a css={style.button} href="/functies">
                    {t.all} &nbsp;
                    <span className="arrow" tw="text-tellow-purple!">
                        {' ->'}
                    </span>
                </a>
            </div>
        </motion.div>
    )
}

export default Dropdown
