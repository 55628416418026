import React from 'react'

const Signet = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 7.19998C9 6.2452 9.37928 5.32952 10.0544 4.65439C10.7295 3.97926 11.6452 3.59998 12.6 3.59998H23.4C24.3548 3.59998 25.2705 3.97926 25.9456 4.65439C26.6207 5.32952 27 6.2452 27 7.19998V32.4L18 27.9L9 32.4V7.19998Z"
            fill="currentColor"
        />
    </svg>
)

export default Signet
