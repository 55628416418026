import React from 'react'

const Report = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.6 5.40002C8.64522 5.40002 7.72955 5.77931 7.05442 6.45444C6.37928 7.12957 6 8.04525 6 9.00002V27C6 27.9548 6.37928 28.8705 7.05442 29.5456C7.72955 30.2207 8.64522 30.6 9.6 30.6H27.6C28.5548 30.6 29.4705 30.2207 30.1456 29.5456C30.8207 28.8705 31.2 27.9548 31.2 27V9.00002C31.2 8.04525 30.8207 7.12957 30.1456 6.45444C29.4705 5.77931 28.5548 5.40002 27.6 5.40002H9.6ZM25.8 12.6C25.8 12.1226 25.6104 11.6648 25.2728 11.3272C24.9352 10.9897 24.4774 10.8 24 10.8C23.5226 10.8 23.0648 10.9897 22.7272 11.3272C22.3896 11.6648 22.2 12.1226 22.2 12.6V23.4C22.2 23.8774 22.3896 24.3353 22.7272 24.6728C23.0648 25.0104 23.5226 25.2 24 25.2C24.4774 25.2 24.9352 25.0104 25.2728 24.6728C25.6104 24.3353 25.8 23.8774 25.8 23.4V12.6ZM20.4 16.2C20.4 15.7226 20.2104 15.2648 19.8728 14.9272C19.5352 14.5897 19.0774 14.4 18.6 14.4C18.1226 14.4 17.6648 14.5897 17.3272 14.9272C16.9896 15.2648 16.8 15.7226 16.8 16.2V23.4C16.8 23.8774 16.9896 24.3353 17.3272 24.6728C17.6648 25.0104 18.1226 25.2 18.6 25.2C19.0774 25.2 19.5352 25.0104 19.8728 24.6728C20.2104 24.3353 20.4 23.8774 20.4 23.4V16.2ZM15 21.6C15 21.1226 14.8104 20.6648 14.4728 20.3272C14.1352 19.9897 13.6774 19.8 13.2 19.8C12.7226 19.8 12.2648 19.9897 11.9272 20.3272C11.5896 20.6648 11.4 21.1226 11.4 21.6V23.4C11.4 23.8774 11.5896 24.3353 11.9272 24.6728C12.2648 25.0104 12.7226 25.2 13.2 25.2C13.6774 25.2 14.1352 25.0104 14.4728 24.6728C14.8104 24.3353 15 23.8774 15 23.4V21.6Z"
            fill="currentColor"
        />
    </svg>
)

export default Report
