import React from 'react'
import styled from 'styled-components'

const StyledPill = styled.div`
    width: 32px;
    height: 32px;
    display: inline-block;
    color: ${(props) => (props.color ? props.theme.color[props.color] : props.theme.color.tellowBlue)};
    background: ${(props) => (props.background ? props.theme.color[props.background] : props.theme.color.tellowWhite)};
    border-radius: 16px;
`

const Pill = ({ className, style, background, color, children }) => {
    const props = { className, style, background, color }
    return <StyledPill {...props}>{children}</StyledPill>
}

export default Pill
