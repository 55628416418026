import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Badge from './badge'
import ArticleMeta from './article-meta'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    ${({ categoryStyles }) =>
        categoryStyles &&
        `
        @media screen and (min-width: 600px) and (max-width: 965px) {
            max-height: 80px;
        }
    }
  `}

    ${({ small }) =>
        small &&
        `
        flex-direction: row;
        box-shadow: unset;
    }
  `}

    ${({ large }) =>
        large &&
        `
        padding-right: 3rem;
        box-shadow: unset;

        @media screen and (max-width: 1100px) {
            padding-right: 1rem;
        }

        @media screen and (min-width: 500px) and (max-width: 820px) {
            flex-direction: row;
        }
    }
  `}
`

const Image = styled(Img)`
    margin-bottom: 15px;
    object-fit: cover;
    width: 100%;
    height: 50%;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    ${({ large }) =>
        large &&
        `
        height: 55%;
        border-radius: 10px;

        @media screen and (min-width: 500px) and (max-width: 820px) {
          min-width: 140px;
          width: 140px;
          height: 110px;
        }
    }
  `}

    ${({ small }) =>
        small &&
        `
        width: 160px;
        height: 120px;
        border-radius: 10px;

        @media screen and (max-width: 1024px) {
            width: 180px;
            min-width: 180px;
        }

        @media screen and (min-width: 500px) and (max-width: 820px) {
            min-width: 140px;
            width: 140px;
            height: 110px;
        }

        @media screen and (max-width: 425px) {
            width: 125px;
            min-width: 125px;
            height: 100px;
        }
    }
  `}

    ${({ categoryImage }) =>
        categoryImage &&
        `
        width: 100px;
        min-width: 100px;
        height: 80px;

        @media screen and (max-width: 1024px) {
            width: 100px;
            min-width: 100px;
            height: 80px;
        }

        @media screen and (max-width: 425px) {
            min-width: 105px;
            height: 86px;
            width: 105px;
        }
    }
  `}
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 965px) {
        padding: 0 25px;
    }

    ${({ small }) =>
        small &&
        `
        padding-top: 5px;

        @media screen and (max-width: 965px) {
            padding: 0 0 0 15px;
        }

        @media screen and (max-width: 320px) {
            padding-left: 10px;
        }
    }
  `}

    ${({ large }) =>
        large &&
        `
        padding 0;

        @media screen and (max-width: 965px) {
            padding: 0 0 0 15px;
        }
    }
  `}
`

const Title = styled.h4`
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin: 12px 0 0 0;
    overflow: hidden;
    --max-lines: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    ${({ large }) =>
        large &&
        `
        font-size: 24px;
        line-height: 35px;

        @media screen and (min-width: 820px) and (max-width: 1184px) {
            font-size: 20px;
            line-height: 24px;
        }

        @media screen and (min-width: 500px) and (max-width: 820px) {
            font-size: 14px;
            line-height: 20px;
            word-break: break-all;
        }
    }
  `}

    ${({ small }) =>
        small &&
        `
        margin-top: 6px;

        @media screen and (max-width: 320px) {
            font-size: 12px;
        }
    }
  `}

    ${({ categoryStyles }) =>
        categoryStyles &&
        `
        margin-top: 15px;

        @media screen and (max-width: 768px) {
            margin-right: 10px;
        }

        @media screen and (max-width: 425px) {
            margin-top: 20px;
        }

    }
  `}
`

const Description = styled.div`
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    text-align: left;
    margin: 12px 0 0 0;
    overflow: hidden;
    --max-lines: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${({ large }) =>
        large &&
        `
        font-size: 16px;
        line-height: 24px;

        @media screen and (max-width: 1184px) {
          font-size: 14px;
          line-height: 16px;
        }

        @media screen and (max-width: 820px) {
          display: none;
          visibility: hidden;
        }
    }
  `}
`

const MetaContainer = styled.div`
    position: absolute;
    bottom: 5px;
    margin-top: 5px;

    ${({ large }) =>
        large &&
        `
        bottom: 35px;

        @media screen and (max-width: 1184px) {
            bottom: 20px;
        }

        @media screen and (max-width: 820px) {
            bottom: 40px;
        }
    }
  `}

    ${({ small }) =>
        small &&
        `
        position: unset;
        margin-top: 10px;
    }
  `}
`

const BlogPostCard = (props) => (
    <Card small={props.small} large={props.large} categoryStyles={props.category}>
        <Image fluid={props.fluid} alt={props.previewImgAlt} small={props.small} large={props.large} categoryImage={props.category} />

        <Wrapper small={props.small} large={props.large}>
            {props.tag && <Badge tag={props.tag} />}
            <Title large={props.large} small={props.small} categoryStyles={props.category}>
                {props.title}
            </Title>
            {props.description && <Description large={props.large}>{props.description}</Description>}
            <MetaContainer small={props.small} large={props.large}>
                <ArticleMeta
                    readingTime={props.readingTime}
                    lastPublicationDate={props.lastPublicationDate}
                    paddingRight={props.paddingRight}
                    metaColor={props.metaColor}
                    withDot={props.withDot}
                    blogCategory
                />
            </MetaContainer>
        </Wrapper>
    </Card>
)

export default BlogPostCard
