import { Link } from 'gatsby'
import styled from 'styled-components'
import { Button } from '../components/UI'
import { Hero } from '../utils'

export const BlogCard = styled.div`
    border-radius: 0.5rem;
    box-shadow: ${(props) => props.theme.subtleShadow};

    & > div {
        padding: 12px 24px 24px;
    }

    & > img {
        margin: 0;
    }

    transition: all 0.2s;

    &:hover {
        transform: scale(1.005, 1.005) translateY(2px);
        box-shadow: ${(props) => props.theme.shadow};
        transition: all 0.24s;
    }

    .blog-img {
        border-radius: 0.5rem 0.5rem 0 0;
        height: 19rem;
        width: 100%;
        object-fit: cover;

        @media screen and (min-width: 768px) {
            height: 14rem;
        }

        @media screen and (min-width: 1100px) {
            height: 19rem;
        }
    }
`

export const BlogTitle = styled.div`
    font-weight: bold;
    font-size: 23px;
    height: 4.45rem;
    width: 100%;
    overflow: hidden;
`

export const BlogDate = styled.div`
    color: #999;
    display: inline-block;
    line-height: 100%;
`

export const StyledLink = styled(Link)`
    display: block;
`

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.75rem;
    grid-auto-rows: 350px;
`

export const TagContainer = styled.div`
    margin-bottom: 1.666rem;
    font-size: 0.8em;
`

// TODO: Fix this legacy slider crap.
export const Tags = styled.div`
    margin-top: 10px;
    max-height: ${(props) => (props.height !== null ? props.height : '56px')};

    @media screen and (max-width: 1186px) {
        max-height: ${(props) => (props.height !== null ? props.height : '112px')};
    }

    @media screen and (max-width: 674px) {
        max-height: ${(props) => (props.height !== null ? props.height : '224px')};
    }

    overflow-y: scroll;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
`

export const PublishDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
`

export const Summary = styled.div`
    height: 6rem;
    color: ${(props) => props.theme.color.tellowGray600};
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255, 1) 75%);
    }
`

export const RotatableChevron = styled.div`
    font-size: 0;
    transform: rotate(${(props) => props.rotation}deg);
    width: 32px;
    height: 32px;
    display: inline-block;
`

export const FilterTagButtonText = styled.p`
    margin: auto 0;
    padding: 0;
`

export const ShowFilterTagsButton = styled(Button)`
    display: inline-flex;
    padding: 5px 5px 5px 20px;
    transition: opacity 0.2s;
    color: #011532;
    background-color: white;
    box-shadow: none;
    font-size: 1.2em;

    &.active,
    &:hover {
        opacity: 0.4;
        transition: opacity 0.2s;
    }
`

export const BlogHero = styled(Hero)`
    background: ${(props) => props.theme.color.tellowPurple};
    color: ${(props) => props.theme.color.tellowWhite};
    height: auto;
    padding: 0 1.2rem;
`
