import React from 'react'
import canUseDOM from 'can-use-dom'
import Button from './button'

class ChatButton extends React.Component {
    constructor(props) {
        super(props)
        this.updateState = this.updateState.bind(this)

        if (canUseDOM) {
            window.addEventListener('intercom_loader', this.updateState)

            this.state = {
                intercomLoaded: window.intercomLoaded,
                intercomTimeout: window.intercomTimeout,
            }
        } else {
            this.state = { intercomLoaded: false, intercomTimeout: false }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('intercom_loader', this.updateState)
    }

    updateState() {
        this.setState({
            intercomLoaded: window.intercomLoaded,
            intercomTimeout: window.intercomTimeout,
        })
    }

    render() {
        const { intercomLoaded, intercomTimeout } = this.state

        if (intercomTimeout) {
            return null
        }

        return (
            <Button disabled={!intercomLoaded} onClick={() => intercomLoaded && window.Intercom('show')} big={this.props.big}>
                {intercomLoaded ? this.props.children : '... chat laden'}
            </Button>
        )
    }
}

export default ChatButton
