/* eslint-disable react/no-array-index-key */
import { graphql } from 'gatsby'
import React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import Related from '../../components/related'
import CallToAction from '../../components/pages/store/callToAction'
import { BlogPosting, BreadcrumbList } from '../../components/layout/meta/schema-org'
import config from '../../config'
import { Container, getCanonicalFromPath, TextContainer } from '../../utils'
import { excerptResolver } from '../../utils/prismic/excerptResolver'
import { BlockSection, Content } from '../../utils/prismic/sliceStyles'
import Badge from './badge'
import ArticleMeta from './article-meta'
import { BlogHero } from '../../pages/_blog.styles'

// Reading time dependency
const readingTime = require('reading-time')

const BlogMeta = (data) => {
    const { title, path, image } = data
    const canonical = getCanonicalFromPath(path)
    const { organization } = config.organization
    const type = 'article'
    const jsonLD = [BlogPosting({ ...data, organization, canonical }), BreadcrumbList({ title, canonical, image })]

    return <Meta {...data} {...{ canonical, type, jsonLD }} />
}

const BlogContainer = styled(TextContainer)`
    max-width: 750px;
    padding-bottom: 0;

    padding-top: ${(props) => props.textPadding && 0};

    @media screen and (max-width: 1100px) {
        margin: 0 auto;
        padding-top: ${(props) => (props.textPadding ? 0 : '110px')};
    }

    @media screen and (max-width: 480px) {
        padding-top: ${(props) => (props.textPadding ? '0px' : '220px')};
    }

    @media screen and (max-width: 320px) {
        padding-top: ${(props) => (props.textPadding ? '0px' : '160px')};
    }

    img {
        max-width: 800px;
        display: block;
        margin: 0 auto;
    }

    a {
        text-decoration: underline;
    }

    ul {
        list-style-type: disc;
        margin-left: 1.6rem;
    }

    ol {
        list-style-type: decimal;
        margin-left: 1.6rem;
    }

    @media screen and (max-width: 1080px) {
        img {
            align-self: center;
            max-width: 100%;
        }
    }
`

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
        max-width: 750px;
    }
`

const TextWrapper = styled.div`
    height: 100%;
    max-width: 502px;
    margin-top: 73px;

    @media screen and (max-width: 800px) {
        margin-top: 30px;
    }

    @media screen and (min-width: 400px) and (max-width: 820px) {
        max-width: 100%;
        padding: 0 1rem;
    }
`

const BlogTitle = styled(motion.h1)`
    font-size: 2rem;
    display: flex;
    flex-wrap: wrap;
`

const Letter = styled(motion.span)`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
        word-break: break-all;
    }
`

const HeroImage = styled.div`
    position: relative;
    width: 539px;
    height: 305px;

    img {
        border-radius: 0.5rem;
        max-width: 539px;
        width: 100%;
        max-height: 305px;
        position: relative;
        top: 55px;
        margin-bottom: 0;
        object-fit: cover;

        @media screen and (max-width: 1024px) {
            max-width: 487px;
        }

        @media screen and (min-width: 480px) and (max-width: 820px) {
            max-width: 100%;
            padding: 0 1rem;
            max-height: unset;
            border-radius: 2.5rem;
            top: 1rem;
        }

        @media screen and (max-width: 480px) {
            top: 10px;
        }
    }

    @media screen and (max-width: 1100px) {
        width: auto;
        height: auto;
    }

    @media screen and (min-width: 480px) and (max-width: 820px) {
        max-height: 430px;
    }

    @media screen and (max-width: 480px) {
        margin: 0 auto;
        height: 80px;
    }
`

const Block = styled(BlockSection)`
    max-width: 750px;
    margin: 0 auto;
    padding: 0 24px;

    @media screen and (max-width: 425px) {
        max-width: 393px;
    }
`

const BlogPost = ({ data }) => {
    const lastPublicationDate = new Date(data.prismicBlog.data.post_date) || new Date(data.prismicBlog.last_publication_date)
    const firstPublicationDate = new Date(data.prismicBlog.first_publication_date)
    const image = data.prismicBlog.data.headerimage

    const getAuthor = () => {
        if (
            data.prismicBlog.data.authors === undefined ||
            data.prismicBlog.data.authors[0] === undefined ||
            !data.prismicBlog.data.authors[0].author ||
            !data.prismicBlog.data.authors[0].author.document
        ) {
            return undefined
        }

        const authors = []

        data.prismicBlog.data.authors.forEach((auth) => {
            const { document } = auth.author
            const hasAuthor = document.type === 'author'

            if (hasAuthor) {
                authors.push(document.data.full_name)
            }
        })

        if (authors.length <= 0) {
            return 'Tellow'
        }

        return authors.join(', ')
    }

    const slices = data.prismicBlog.data.body?.map((slice) => {
        switch (slice.slice_type) {
            case 'cta':
                return <CallToAction content={slice} key={slice.id} className="cta" />
            case 'custom_html':
                return (
                    <Block key={slice.id}>
                        <Content style={{ width: '100%', maxWidth: '750px' }}>
                            <div style={{ width: '100%', fontSize: '.9rem' }} dangerouslySetInnerHTML={{ __html: slice.primary.pre.text }} />
                        </Content>
                    </Block>
                )
            case 'text':
                return (
                    <BlogContainer textPadding key={slice.id}>
                        <div dangerouslySetInnerHTML={{ __html: slice.primary.text.html }} />
                    </BlogContainer>
                )
            // Needs to be fleshed out.
            case 'quote':
                return (
                    <BlockSection key={slice.id}>
                        <Content style={{ width: '100%', maxWidth: '750px' }}>
                            <blockquote style={{ width: '100%', textAlign: 'center' }}>
                                <p>{slice.primary.quote.text}</p>
                                <author style={{ fontSize: '.8rem' }}>{slice.primary.name_of_the_author.text}</author>
                            </blockquote>
                        </Content>
                    </BlockSection>
                )
            default:
                return null
        }
    })

    const articleReadingTime = readingTime(data.prismicBlog.data.content.text).text.replace('min read', 'minuten')

    // Animated title
    // Create an array of letters
    const title = data.prismicBlog.data.title.text
    const string = Array.from(title.split(' '))

    // Add staggering effect to the children of the container
    const containerVariants = {
        before: {},
        after: { transition: { staggerChildren: 0.05, delayChildren: 0.1 } },
    }

    // Variants for animating each word
    const letterVariants = {
        before: {
            opacity: 0,
            y: 20,
            transition: {
                type: 'spring',
                damping: 16,
                stiffness: 200,
            },
        },
        after: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                damping: 16,
                stiffness: 200,
            },
        },
    }

    // we take [0] element of tags array cause we display only one tag on a card
    const tag = data.prismicBlog.data.tags[0].tag.document.data.tag_name

    return (
        <Layout>
            <BlogMeta
                dateModified={lastPublicationDate}
                datePublished={firstPublicationDate || new Date(data.prismicBlog.data.post_date)}
                description={excerptResolver(data.prismicBlog.data)}
                author={getAuthor() || ''}
                title={data.prismicBlog.data.meta_title.text || data.prismicBlog.data.title.text}
                image={image.url || ''}
                path={`/blog/${data.prismicBlog.uid}`}
            />

            <BlogHero>
                <StyledContainer id="blog__ref">
                    <TextWrapper>
                        <Badge tag={tag} />
                        <BlogTitle center="y" height={26} width="100%" background="" variants={containerVariants} initial="before" animate="after">
                            {string.map((word, index) => (
                                <Letter
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    variants={letterVariants}
                                >
                                    {`${word}\u00A0`}
                                </Letter>
                            ))}
                        </BlogTitle>

                        <ArticleMeta lastPublicationDate={lastPublicationDate} readingTime={articleReadingTime} withIcons />
                    </TextWrapper>

                    <HeroImage>
                        <img src={image.url} alt={image.alt} />
                    </HeroImage>
                </StyledContainer>
            </BlogHero>

            <BlogContainer>
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.prismicBlog.data.content.html,
                    }}
                />
            </BlogContainer>
            {slices}
            {data.allPrismicBlog && data.allPrismicBlog.edges ? <Related edges={data.allPrismicBlog.edges} /> : null}
        </Layout>
    )
}

export default withPrismicPreview(BlogPost)

export const blogQuery = graphql`
    query BlogBySlug($uid: String!, $label: ID) {
        prismicBlog(uid: { eq: $uid }) {
            uid
            _previewable
            lang
            alternate_languages {
                id
                type
                lang
                uid
            }
            data {
                authors {
                    author {
                        document {
                            ... on PrismicAuthor {
                                id
                                _previewable
                                data {
                                    full_name
                                }
                                type
                            }
                        }
                    }
                }
                body {
                    ... on PrismicBlogDataBodyCta {
                        id
                        slice_type
                        primary {
                            background_color
                            button_colors
                            button_text {
                                text
                            }
                            section_title {
                                text
                            }
                            text_color
                            url {
                                url
                            }
                        }
                    }
                    ... on PrismicBlogDataBodyAuthor {
                        id
                        slice_type
                    }
                    ... on PrismicBlogDataBodyText {
                        id
                        slice_type
                    }
                    ... on PrismicBlogDataBodyBannerWithCaption {
                        id
                        slice_type
                    }
                    ... on PrismicBlogDataBodyQuote {
                        id
                        slice_type
                    }
                    ... on PrismicBlogDataBodyListOfArticles {
                        id
                        slice_type
                    }
                    ... on PrismicBlogDataBodyText {
                        id
                        slice_type
                        primary {
                            text {
                                html
                            }
                        }
                    }
                    ... on PrismicBlogDataBodyCustomHtml {
                        id
                        slice_type
                        primary {
                            pre {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicBlogDataBodyQuote {
                        id
                        slice_type
                        primary {
                            quote {
                                text
                                html
                            }
                            portrait_author {
                                url
                            }
                            name_of_the_author {
                                html
                                text
                            }
                        }
                    }
                }
                title {
                    html
                    text
                }
                content {
                    html
                    text
                    richText
                }
                headerimage {
                    dimensions {
                        height
                        width
                    }
                    alt
                    url
                }
                meta_description
                meta_title {
                    html
                    text
                }
                excerpt
                post_date
                tags {
                    tag {
                        document {
                            ... on PrismicTag {
                                id
                                _previewable
                                data {
                                    tag_name
                                }
                            }
                        }
                        slug
                    }
                }
            }
            first_publication_date
            last_publication_date
        }
        allPrismicBlog(limit: 9, filter: { data: { tags: { elemMatch: { tag: { id: { eq: $label } } } } }, uid: { ne: $uid } }) {
            edges {
                node {
                    id
                    _previewable
                    data {
                        title {
                            text
                        }
                        content {
                            text
                        }
                        excerpt
                        usepostdate
                        headerimage {
                            url
                            alt
                            fluid {
                                src
                                aspectRatio
                                base64
                                # sizes (deprecated)
                                srcSetWebp
                                srcSet
                                srcWebp
                            }
                        }
                        post_date
                        tags {
                            tag {
                                document {
                                    ... on PrismicTag {
                                        id
                                        _previewable
                                        data {
                                            tag_name
                                        }
                                    }
                                }
                                slug
                            }
                        }
                    }
                    uid
                }
            }
        }
    }
`
