import React from 'react'
import { Link } from './components/UI'

export const generic = [
    {
        question: 'Heb ik nog een boekhouder nodig?',
        answer: (
            <p>
                Tellow helpt je met het bijhouden van je administratie, zodat jouw boekhouding overzichtelijk is. Wij automatiseren je administratie, wat jou en je boekhouder veel
                tijd en geld bespaart. Onze vuistregel is: Tellow automatiseert, de boekhouder adviseert. Dus ja, het kan zijn dat je nog een boekhouder nodig hebt die met je
                meekijkt en je van advies voorziet. Iedere onderneming is namelijk net weer anders. Om die reden geven wij dan ook geen financieel advies.
            </p>
        ),
    },
    {
        question: 'Helpen jullie met overstappen?',
        answer: (
            <p>
                Tuurlijk! Overstappen is een fluitje van een cent. Onze ervaren adviseurs kunnen je overal bij helpen. Je doorloopt bij je aanmelding een stappenplan waarmee je
                gemakkelijk met Tellow start, ook als je nog openstaande posten hebt in je vorige pakket. Heb je toch wat extra hulp nodig? Ontvang ons e-book waarin je stap voor
                stap leest hoe je de overstap maakt. Zit je nu nog in Excel te pielen of ben je aangesloten bij een ander boekhoudpakket? De overstap is zo gemaakt!
            </p>
        ),
    },
    {
        question: 'Waarom bestaat Tellow en wat is jullie missie?',
        answer: (
            <>
                <p>
                    Het is onze missie om zzp’ers, freelancers en kleine bedrijven de vrijheid te geven om te ondernemen. Dat doen we door gebruiksvriendelijke en tijdbesparende
                    oplossingen te maken - die jou blij maken.
                </p>
                <p>
                    Met Tellow maken wij je boekhouding zo simpel, compleet en snel mogelijk. En we zijn super ambitieus, dus we willen nog veel meer toepassingen bedenken die
                    ervoor zorgen dat zzp’en gemakkelijker wordt dan ooit.
                </p>
            </>
        ),
    },
]

export const sectors = [
    {
        question: 'Is Tellow geschikt als ik meedoe aan de kleineondernemersregeling?',
        answer: (
            <>
                <p>
                    Absoluut! Als je je hebt aangemeld voor de KOR, ben je vrijgesteld van btw en mag je maximaal €20.000 omzet hebben. In Tellow zet je simpelweg het knopje ‘btw
                    vrijgesteld’ aan. Ga je onverwachts toch over de grens van €20.000 omzet? Dan zet je het knopje gewoon weer uit.
                </p>
            </>
        ),
    },
    {
        question: 'Kan ik gebruik maken van Tellow als ik ben vrijgesteld van btw? ',
        answer: (
            <>
                <p>Zeker! Je zet in je account simpelweg het knopje ‘btw vrijgesteld’ aan.</p>
            </>
        ),
    },
    {
        question: 'Ondersteunt Tellow het kasstelsel?',
        answer: (
            <>
                <p>
                    Nee, daar is (nog) geen functionaliteit voor. Stel je voor dat je een kapper bent en 40 mensen per week knipt. Je maakt natuurlijk niet voor iedere klant een
                    aparte factuur aan, maar je wilt wel je omzet in Tellow hebben. Het is gelukkig wél mogelijk om met Tellow te werken: iedere week maak je een verzamelfactuur,
                    gericht aan jezelf. Daarin zet je het de totale omzet van die week. Daar koppel je vervolgens je betalingen aan. Op deze manier komt de omzet gewoon in je
                    aangifte terecht. Lees hier meer over in{' '}
                    <span>
                        <a href="https://help.tellow.nl/nl/articles/1541072-ondersteunt-tellow-het-kasstelsel" target="_blank" rel="noopener noreferrer">
                            het artikel
                        </a>
                    </span>{' '}
                    in ons help center.
                </p>
            </>
        ),
    },
    {
        question: 'Kan ik 1 account gebruiken voor 2 bedrijven?',
        answer: <p>Nee. In Tellow is dit (nog) niet mogelijk, maar we werken met een team van meer dan 20 mensen iedere dag aan het verbeteren van Tellow!</p>,
    },
    {
        question: 'Kan ik Tellow gebruiken als ik veel facturen heb?',
        answer: (
            <p>
                Zeker. Je maakt dan iedere dag een aparte verkoopfactuur aan waarin je het totaalbedrag zet dat je aan producten hebt verkocht. Je hebt dan wel te maken met extra
                handelingen, dus je kunt je afvragen of Tellow dan het meest ideale boekhoudprogramma voor je is. Lees het{' '}
                <span>
                    <a href="https://help.tellow.nl/nl/articles/1541072-ondersteunt-tellow-het-kasstelsel" target="_blank" rel="noopener noreferrer">
                        hele artikel
                    </a>
                </span>{' '}
                in onze helpdesk.
            </p>
        ),
    },
    {
        question: 'Kan ik gebruik maken van Tellow als ik een VOF, maatschap, stichting, coöperatie of BV met meer dan 5 personen heb?',
        answer: (
            <>
                <p>
                    Alles is mogelijk! Het ligt er alleen aan waar je naar op zoek bent. Zo kun je met een VOF bijvoorbeeld geen inzage krijgen in de kosten per werknemer of
                    vennoot. Ook bij een BV is dit het geval. We tonen namelijk maar 1 algemene balans: de winst- en verliesrekening. Uit ervaring blijkt dat ondernemers van een
                    boekhoudpakket meer verlangen dan wij (nu) kunnen bieden. Heb je een stichting? Daarvoor hebben we (nog) geen toepasselijk grootboekrekeningschema. Check over 1
                    jaar absoluut weer bij ons in, de kans is groot dat we dan extra functies hebben toegevoegd.
                </p>
            </>
        ),
    },
]

export const pricing = [
    {
        question: 'Zijn er verborgen kosten?',
        answer: (
            <>
                <p>Nee, die zijn er niet. Geen vaste contracten, maandelijks opzegbaar. De prijs die je ziet is de totaalprijs. Tellow is op elk moment te annuleren.</p>
            </>
        ),
    },
    {
        question: 'Hoe beveiligt Tellow mijn gegevens?',
        answer: (
            <>
                <p>
                    Alle communicatie met Tellow verloopt via een beveiligde SSL (HTTPS) verbinding. Wij maken iedere dag een backup van al je gegevens zodat ze nooit verloren
                    gaan.
                </p>
            </>
        ),
    },
    {
        question: 'Hoe kan ik betalen?',
        answer: (
            <>
                <p>
                    Als je kiest voor een betaald Tellow abonnement, kun je ons in je account toestemming geven om door middel van een maandelijkse incasso het abonnementsgeld af
                    te schrijven van je bankrekening.
                </p>
            </>
        ),
    },
]

export const banks = [
    {
        question: 'Zijn mijn gegevens veilig?',
        answer: (
            <p>
                Dat zijn ze zeker! Tellow is gecertificeerd in het veilig houden van je privacy, bestand tegen cyber-attacks en voldoet aan alle eisen van de Belastingdienst en de
                AVG.
            </p>
        ),
    },
    {
        question: 'Ik ben een starter en weet nog weinig van boekhouden, is Tellow voor mij?',
        answer: (
            <p>
                Jazeker! Tellow gelooft in de toekomst van freelancen. Ons boekhoudprogramma is daarom zo gebouwd dat je zelfs zonder boekhoudkennis een volledige en veilige
                administratie hebt. Als cadeautje geven we je graag een <Link to="/whitepapers/succesvol-starten-als-zzper/">gratis whitepaper</Link> vol tips en tricks, voor een
                succesvolle start van je onderneming. Heb je nog vragen over Tellow? Vraag dan een gratis adviesgesprek aan met een van onze experts.
            </p>
        ),
    },
    {
        question: 'Wat zijn de voordelen van een koppeling met de bank?',
        answer: (
            <div>
                <ol>
                    <li>Je btw-aangifte doe je in 1 klik. Bye bye uitstelgedrag en boetes!</li>
                    <li>Je hebt altijd real-time inzicht in je financiële administratie.</li>
                    <li>
                        Tellow werkt als een personal assistant: wij vertellen je wanneer je actie moet ondernemen en helpen je die uit te voeren. Zo heb je bijvoorbeeld geen
                        stress meer of die factuur wel of niet betaald is.
                    </li>
                </ol>
            </div>
        ),
    },
    {
        question: 'Wat is het verschil tussen een automatische en handmatige connectie?',
        answer: (
            <>
                <p>
                    Bij de automatische bank-connectie koppel je jouw bankrekening eenmalig aan je Tellow account en vanaf dan worden banktransacties automatisch in Tellow geladen.
                    Je hebt daarmee altijd live inzicht in je financiën. Heeft een klant je factuur betaald? Dan krijg je direct een melding. Handig toch?
                </p>
                <p>
                    Met een handmatige koppeling ga je naar je online bankieren omgeving om daar je transacties te downloaden. Je uploadt die transacties in Tellow en hoppa, je
                    bent klaar. Dit doe je bijvoorbeeld één keer per week of maand. Uiteraard werken we zo hard mogelijk aan de optie om iedere bank automatisch te koppelen aan
                    Tellow.
                </p>
            </>
        ),
    },
]

export const support = [
    {
        question: 'Is overstappen naar Tellow makkelijk?',
        answer: (
            <>
                <p>
                    Zeker! Overstappen naar Tellow is net zo makkelijk als de was doen. En dat schijnen zelfs kinderen te kunnen. Anyway, zit je nu bij een ander boekhoudpakket of
                    vul je trouw je cijfertjes in via Excel, dan is overstappen naar Tellow een fluitje van een cent.
                </p>
                <p>
                    Doorloop bij je aanmelding een simpel stappenplan en alles wordt klaargezet om met Tellow te starten. Inclusief je nog openstaande posten uit je vorige pakket.
                    Toch wat extra hulp nodig? We helpen je graag.
                </p>
            </>
        ),
    },
    {
        question: 'Hoe verwerkt Tellow mijn bonnen?',
        answer: (
            <>
                <p>
                    Je pakt je telefoon, opent de Tellow app en maakt een fotootje van een bon of factuur. En dat is eigenlijk alles wat jij hoeft te doen. Terwijl jij weer verder
                    kunt met ondernemen, gaat Tellow direct voor je aan de slag. We lezen de bonnen en facturen automatisch uit en boeken ze vervolgens ook nog in je administratie.
                    Hoe handig!
                </p>
            </>
        ),
    },
    {
        question: 'Hoe maak en verstuur ik een factuur?',
        answer: (
            <>
                <p>Een factuurtje maken is zo gepiept. Dat kan via de Tellow app, maar ook met je laptop of iPad.</p>
                <p>
                    Facturen maken is leuk, vooral dat moment van versturen. Een factuur betaald krijgen is nog veel leuker. Geld binnen? Tellow stuurt je een update op je telefoon
                    en verandert de status automatisch naar ´Betaald´. Helaas staat het betalen van facturen niet bij elke klant bovenaan het takenlijstje. Was het maar zo´n feest.
                    Nou, wij proberen dat feestje wel te maken en daarom zetten wij meteen een betalingsherinnering voor je klaar. Zo loop je nooit meer geld mis!
                </p>
                <p>
                    Meer van zulke tips &amp; tricks? Bezoek ons Help Center.{' '}
                    <a href="https://help.tellow.nl/nl" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        https://help.tellow.nl/
                    </a>
                </p>
            </>
        ),
    },
    {
        question: 'Hoe doe ik mijn btw-aangifte met Tellow?',
        answer: (
            <>
                <p>
                    De btw-aangifte. Voor jou ook een terugkerend ritueel van uitstellen tot het allerlaatste moment? Tellow maakt het makkelijk voor je. Alles wat je in Tellow zet
                    wordt namelijk meteen bijgewerkt in het btw-formulier. Zo is de hoogte van je omzetbelasting altijd up to date en klaar om te versturen als het nodig is. En met
                    het Tellow Plus abonnement kun je je aangifte digitaal indienen bij de Belastingdienst. Lekker makkelijk.
                </p>
                <p>
                    Even ervaren hoe het echt werkt?{' '}
                    <a href="https://help.tellow.nl/nl/articles/2987492-btw-aangifte-doen-met-tellow" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        Lees het nog eens rustig door
                    </a>
                    .
                </p>
            </>
        ),
    },
    {
        question: 'Heb ik nog een boekhouder nodig?',
        answer: (
            <>
                <p>
                    Tellow is superhandig voor het bijhouden van je administratie. Je boekhouding is altijd netjes op orde en kwartaalaangiftes zijn een eitje. Deze automatisering
                    voorkomt dat jij of je boekhouder bonnen moet overtypen. Tenminste, als je ze allemaal weet te bewaren. Zo bespaart Tellow jou tijd en geld.
                </p>
                <p>
                    We begrijpen dat je soms ook behoefte hebt aan persoonlijk advies. Bijvoorbeeld bij de IB-aangifte aan het eind van het jaar. We werken graag samen met
                    boekhouders. Onze stelregel? Tellow automatiseert, de boekhouder adviseert. Je geeft de boekhouder eenvoudig toegang tot je account.{' '}
                    <a href="https://help.tellow.nl/nl/articles/1541275-hoe-geef-ik-mijn-boekhouder-toegang-tot-tellow" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        Lees hier hoe je dat doet.
                    </a>
                </p>
            </>
        ),
    },
    {
        question: 'Op welke toestellen is Tellow beschikbaar?',
        answer: (
            <>
                <p>
                    Uiteraard heb je een smartphone. Dat mag een superhippe zijn, of gewoon eentje die exact doet wat hij moet doen. Om gebruik te maken van Tellow moet je
                    smartphone draaien op iOS (vanaf versie 9.0) of Android (vanaf versie 5.0). De Tellow app is zowel te vinden in de{' '}
                    <Link to="https://itunes.apple.com/nl/app/tellow/id1187872148?mt=8" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        App Store
                    </Link>{' '}
                    als in{' '}
                    <Link to="https://play.google.com/store/apps/details?id=nl.tellow.app&hl=nl" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        Google Play
                    </Link>
                    .
                </p>
                <p>
                    Tellow is natuurlijk ook gewoon via web toegankelijk. Let hierbij wel op de internetbrowser die je gebruikt. Zelf vinden we Chrome of Safari altijd een feestje.
                    Edge of Internet Explorer (standaard ingestelde browsers van Microsoft) zijn wat minder vriendelijk voor Tellow.
                </p>
            </>
        ),
    },
    {
        question: 'Hoe kan ik Tellow opzeggen?',
        answer: (
            <>
                <p>
                    Jammer dat je Tellow wilt opzeggen. Maar hé, we willen alles makkelijk maken en dus ook opzeggen. Stuur even een e-mail naar{' '}
                    <a href="mailto:support@tellow.nl" style={{ color: '#fafafa', textDecoration: 'underline' }}>
                        support@tellow.nl
                    </a>
                    . Vermeld hierbij dat het om een opzegging gaat, je bedrijfsnaam en de reden van opzeggen. Je ontvangt vervolgens een bevestiging per e-mail.
                </p>
            </>
        ),
    },
]

// Corona / COVID-19

export const coronaTopic1 = [
    {
        question: 'Wat houdt deze regeling in?',
        answer: (
            <>
                <p>
                    Als je uitstel aanvraagt, mag je het betalen van je belasting met 3 maanden uitstellen. Zodra de Belastingdienst je aanvraag heeft ontvangen, stoppen de
                    invorderingsmaatregelen. Boetes voor het niet op tijd betalen komen ook te vervallen.
                </p>
            </>
        ),
    },
    {
        question: 'Waar moet ik uitstel aanvragen?',
        answer: (
            <>
                <p>
                    Bij{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.belastingdienst.nl/wps/wcm/connect/nl/home/content/coronavirus">
                        de Belastingdienst
                    </a>
                    . Dit kan nadat je aangifte hebt gedaan en een aanslag hebt ontvangen.
                </p>
            </>
        ),
    },
    {
        question: 'Hoe vraag ik uitstel aan?',
        answer: (
            <>
                <p>
                    Met het{' '}
                    <a target="_blank" rel="noopener noreferrer" href="http://tellow.nl/cdn/uitstel-covid19.pdf">
                        online formulier
                    </a>{' '}
                    ‘Verzoek bijzonder uitstel van betaling voor 3 maanden’ vraag je automatisch in 1 keer uitstel aan voor alle aanslagen inkomstenbelasting,
                    vennootschapsbelasting, omzetbelasting (btw) en loonheffingen. Als je dit formulier instuurt, legt de Belastingdienst voor al deze aanslagen de invordering
                    direct stil voor een periode van 3 maanden. Je hoeft daarvoor verder niets te doen.
                </p>
                <p>
                    Je kunt pas uitstel van betaling aanvragen nadat je een aansluit hebt ontvangen. Blijf dus op tijd aangifte doen. Het verzoek kan niet in behandeling worden
                    genomen als je nog geen aanslag hebt ontvangen.
                </p>
            </>
        ),
    },
    {
        question: 'Wat moet ik op het formulier invullen?',
        answer: (
            <>
                <p>Als je een eenmanszaak hebt hoef je alleen je BSN, adres en contactgegevens in te vullen. Overige entiteiten vullen in plaats van een BSN hun RSIN in.</p>
            </>
        ),
    },
    {
        question: 'Wat gebeurt er nadat het formulier is ingediend?',
        answer: (
            <>
                <p>
                    Je verzoek wordt verwerkt en je krijgt een schriftelijke bevestiging. Het kan zijn dat je toch een boete krijgt. Deze wordt automatisch teruggedraaid en die
                    hoef je dus niet te betalen. Je hoeft hiervoor ook geen bezwaarschrift in te dienen.
                </p>
            </>
        ),
    },
    {
        question: 'Wat als ik al een schriftelijke aanvraag had ingediend?',
        answer: (
            <>
                <p>
                    Deze blijft geldig en je hoeft niet opnieuw uitstel aan te vragen. De vereiste van een verklaring van een derde deskundige komt te vervallen en hoef je dus niet
                    na te sturen.
                </p>
            </>
        ),
    },
    {
        question: 'Kan ik ook langer dan 3 maanden uitstel krijgen?',
        answer: (
            <>
                <p>Ja, de voorwaarden hiervoor worden versoepeld. Je stuurt dan een brief naar de Belastingdienst.</p>
            </>
        ),
    },
    {
        question: 'Verder lezen?',
        answer: (
            <>
                <p>
                    Voor meer informatie over deze regeling, lees onze{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/berichten/nieuws/bijzonder-uitstel-van-betaling-aanvragen-wordt-eenvoudiger"
                    >
                        blog
                    </a>{' '}
                    of{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.tellow.nl/blog/betalingsuitstel">
                        de pagina van de belastingdienst
                    </a>
                    .
                </p>
            </>
        ),
    },
]

export const coronaTopic2 = [
    {
        question: 'Wat houdt de TOGS in?',
        answer: (
            <>
                <p>
                    De TOGS is bedoeld voor sectoren die schade lijden door noodgedwongen sluiting, de inperking van bijeenkomsten en/of het negatieve reisadvies. Het is een
                    eenmalige bijdrage van €4000 die je naar eigen invulling kunt besteden. Deze tegemoetkoming kan helpen om de eerste schade op te vangen en kosten te betalen.{' '}
                </p>
            </>
        ),
    },
    {
        question: 'Hoe vraag ik deze tegemoetkoming aan?',
        answer: (
            <>
                <p>
                    Je kunt de tegemoetkoming schade COVID-19 aanvragen van 27 maart 2020 om 16.30 uur t/m vrijdag 26 juni 2020 om 17.00 uur bij{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/aanvraagproces/aanvraagprocedure"
                    >
                        de Rijksdienst voor Ondernemend Nederland (RVO)
                    </a>{' '}
                    van de RVO. U kunt alleen online aanvragen.
                </p>
            </>
        ),
    },
    {
        question: 'Kom ik in aanmerking voor deze regeling?',
        answer: (
            <>
                <p>
                    De TOGS is bedoeld voor mkb-bedrijven (met en zonder personeel) die schade lijden door noodgedwongen sluiting, de inperking van bijeenkomsten en/of het
                    negatieve reisadvies. Je komt in aanmerking als je voldoet aan de{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/voorwaarden">
                        voorwaarden
                    </a>{' '}
                    én de hoofdactiviteit van je onderneming op 15 maart 2020 overeenkomt met{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/vastgestelde-sbi-codes">
                        een van de vastgestelde SBI-codes
                    </a>
                    . Een SBI code geeft aan wat de activiteit van een bedrijf is. Meer over de voorwaarden en de SBI-afbakening is{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kvk.nl/corona/togs-4000-euro-voor-direct-getroffen-ondernemers/">
                        hier
                    </a>{' '}
                    te vinden.
                </p>
                <p>
                    Vind je dat je SBI-code niet overeenkomt met uw hoofdactiviteit? Of is je SBI-code niet meegenomen in de regeling en denk je dat deze ook moet worden opgenomen
                    in de lijst? Meld dit dan{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/signaal-vergeten-sbi-codes-wssl"
                    >
                        hier
                    </a>
                    .
                </p>
            </>
        ),
    },
    {
        question: 'Verder lezen?',
        answer: (
            <>
                <p>
                    Bekijk{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19">
                        de website van het RVO
                    </a>
                    ,{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/veelgestelde-vragen">
                        de veelgestelde vragen
                    </a>{' '}
                    en{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19/aanvraagproces/aanvraagprocedure"
                    >
                        de uitleg over de aanvraagprocedure
                    </a>
                    .
                </p>
            </>
        ),
    },
]

export const coronaTopic3 = [
    {
        question: 'Wat houdt deze Tozo-regeling in?',
        answer: (
            <>
                <p>Zzp’ers die inkomsten mislopen en in de financiële moeilijkheden komen, kunnen aanspraak maken op de Tozo. Je kunt op 2 manieren ondersteuning aanvragen. </p>
                <ul>
                    <li>Voor een periode van 3 maanden inkomensondersteuning (een uitkering).</li>
                    <li>Een lening aanvragen voor bedrijfskapitaal om liquiditeitsproblemen op te lossen.</li>
                </ul>
                <p>
                    Deze regeling is gebaseerd op de Bbz en is enorm versoepeld om nu zo snel mogelijk verstrekt te kunnen worden. De Tozo werkt met terugwerkende kracht vanaf 1
                    maart 2020 en geldt voor 3 maanden, tot 1 juni 2020. Je vraagt de Tozo aan via de gemeente waar je woont.
                </p>
            </>
        ),
    },
    {
        question: 'Wat zijn de voorwaarden van de Tozo?',
        answer: (
            <>
                <p>De voorwaarden van de Bbz zijn enorm versoepeld om de Tozo op te stellen. Dit om getroffen zzp’ers snel te kunnen helpen: </p>
                <ul>
                    <li>De gemeente toetst niet op levensvatbaarheid van je onderneming.</li>
                    <li>Door versoepeling kan nu binnen 4 weken na aanvraag al worden uitgekeerd i.p.v. na 13 weken.</li>
                    <li>
                        De hoogte van de uitkering is afhankelijk van jouw gezinssamenstelling, zie{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.uwv.nl/particulieren/bedragen/detail/sociaal-minimum">
                            hier meer
                        </a>
                        .
                    </li>
                    <li>De uitkering is een gift en hoeft niet te worden terugbetaald.</li>
                    <li>Deze versnelde procedure geldt voor leningen voor bedrijfskapitaal tot maximaal €10.157.</li>
                    <li>
                        Bij een lening voor bedrijfskapitaal wordt de mogelijkheid tot uitstel van de aflossingsverplichting opgenomen en er wordt een lager rentepercentage
                        gehanteerd dan in de bbz geldt.
                    </li>
                    <li>
                        Zijn jij en je partner beide zzp’er? Dan kan slechts 1 van beiden een aanvraag indienen. Laat degene met het laagste inkomen voor jullie samen de aanvraag
                        invullen.
                    </li>
                </ul>
            </>
        ),
    },
    {
        question: 'Waar kan ik de Tozo regeling aanvragen?',
        answer: (
            <>
                <p>Je kunt de bbz aanvragen bij de gemeente waar je staat ingeschreven.</p>
            </>
        ),
    },
    {
        question: 'Hoe kan ik de Tozo aanvragen?',
        answer: (
            <>
                <p>
                    De regeling wordt uitgevoerd door je gemeente. Je kunt de Tozo aanvragen bij je woongemeente, niet de gemeente waar je bedrijf is gevestigd. Log in via de
                    website van de gemeente waar je staat ingeschreven, met behulp van je DigiD. Voor de aanvraag vul je het versoepelde aanvraagformulier Bbz in.{' '}
                </p>
                <ul>
                    <li>Een kopie geldig legitimatiebewijs</li>
                    <li>Afschriften van al je bank- en spaarrekeningen van de afgelopen 3 maanden</li>
                    <li>Inschrijving KvK</li>
                </ul>
            </>
        ),
    },
    {
        question: 'Kom ik in aanmerking voor de Tozo?',
        answer: (
            <>
                <p>Je komt in aanmerking als je voldoet aan de volgende eisen, je:</p>
                <ul>
                    <li>bent een gevestigde zelfstandige, vanaf 18 jaar tot de pensioengerechtigde leeftijd;</li>
                    <li>bent woonachtig en rechtmatig verblijvend in Nederland;</li>
                    <li>bent Nederlander of daarmee gelijkgesteld;</li>
                    <li>oefent het bedrijf of zelfstandig beroep in Nederland uit;</li>
                    <li>voldoet aan de wettelijke vereisten voor de uitoefening van het eigen bedrijf;</li>
                    <li>staat ingeschreven in het Handelsregister van de Kamer van Koophandel;</li>
                    <li>bent voor 17 maart 2020 om 18.45 uur je onderneming gestart en ingeschreven bij KVK.</li>
                    <li>
                        voldoet over 2019 aan het urencriterium waardoor je minimaal 1.225 uur werkzaam was in je eigen bedrijf of als zelfstandige. Ben je na 1 januari 2019
                        gestart? Dan moet je in ieder geval in de periode tussen inschrijving bij KVK en indiening van de aanvraag in 2020 gemiddeld minimaal 23,5 uur per week
                        aan/in je bedrijf gewerkt hebben.
                    </li>
                    <li>woont in de gemeente waar deze inkomensondersteuning wordt aangevraagd.</li>
                </ul>
                <p>
                    Via de{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://krijgiktozo.nl/">
                        site van het ministerie van SZW
                    </a>{' '}
                    kun je beoordelen of je in aanmerking komt voor de Tozo.
                </p>
            </>
        ),
    },
    {
        question: 'Verder lezen?',
        answer: (
            <>
                <p>
                    Bekijk{' '}
                    <a target="_blank" rel="noopener noreferrer" href="/">
                        het artikel van de KVK
                    </a>{' '}
                    voor veelgestelde vragen.
                </p>
            </>
        ),
    },
]

export const coronaTopic4 = [
    {
        question: 'Wat houdt deze regeling in?',
        answer: (
            <>
                <p>
                    Heb je werknemers in dienst? Met deze tegemoetkoming kun je werknemers met een vast en flexibel contract in dienst houden en hun loon doorbetalen. Deze regeling
                    kan voor 3 maanden worden aangevraagd, met de mogelijkheid tot verlenging van nog eens 3 maanden (wellicht met andere voorwaarden). Van 6 april tot en met 31
                    mei kun je de NOW aanvragen. Voldoet je bedrijf aan de voorwaarden en is deze goed ingevuld? Dan krijg je binnen 2 tot 4 weken een voorschot. De tweede betaling
                    wordt een maand daarna gedaan en de derde betaling een maand daarna.
                </p>
                <p>
                    Er gelden plichten voor deze regeling, lees deze{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/detail/welke-plichten">
                        hier
                    </a>
                    . De hoogte van de tegemoetkoming is situatie-afhankelijk: lees{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/detail/hoe-hoog-is-de-tegemoetkoming">
                        hier
                    </a>{' '}
                    meer.
                </p>
            </>
        ),
    },
    {
        question: 'Waar moet ik deze tegemoetkoming aanvragen?',
        answer: (
            <>
                <p>
                    Vanaf 6 april tot en met 31 mei kun je een aanvraag indienen bij het{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/">
                        UWV
                    </a>
                    . Je hebt hierbij je loonheffingsnummer nodig. Let op: je krijgt geen ontvangstbevestiging toegestuurd.
                </p>
            </>
        ),
    },
    {
        question: 'Welke voorwaarden gelden voor deze regeling?',
        answer: (
            <>
                <p>
                    Zie alle voorwaarden voor deze regeling{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kvk.nl/corona/het-coronavirus-check-hier-je-regelingen/#noodmaatregel">
                        hier
                    </a>
                    .
                </p>
            </>
        ),
    },
    {
        question: 'Wat als ik al een aanvraag had gedaan voor WTV?',
        answer: (
            <>
                <p>
                    Als je al een aanvraag voor WTV had gedaan, wordt deze nu beschouwd als aanvraag voor deze nieuwe tegemoetkomingsregeling. De WTV kan niet meer worden
                    aangevraagd omdat deze niet snel genoeg werkte en niet goed toepasbaar is op deze uitzonderlijke situatie.
                </p>
            </>
        ),
    },
    {
        question: 'Kom ik in aanmerking voor deze regeling?',
        answer: (
            <>
                <p>
                    Je komt in aanmerking voor deze regeling als je personeel hebt en minimaal 20% omzetverlies verwacht gedurende 3 maanden. De regeling is ook bedoeld voor recent
                    gestarte ondernemers, maar voor 1 maart 2020 moet je minimaal 1 maand omzet hebben gedraaid.
                </p>
            </>
        ),
    },
    {
        question: 'Verder lezen?',
        answer: (
            <>
                <p>
                    Lees meer op{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.uwv.nl/werkgevers/overige-onderwerpen/now/">
                        de website van het UWV
                    </a>
                    .
                </p>
            </>
        ),
    },
]
