import React from 'react'
import styled from 'styled-components'
import config from '../config'

export const Block = styled.div`
    width: 100%;
    font-weight: ${(props) => (props.bold ? 600 : 'normal')};
    background-color: ${(props) => (props.backgroundColor ? props.theme.color[props.backgroundColor] : 'transparant')};
    color: ${(props) => props.theme.color[props.color ? props.color : 'darkBlue']};
`

export const Container = styled.div`
    @media screen and (min-width: 1100px) {
        margin: 0 auto;
        max-width: ${1160 + 24 * 2}px;
    }
`

export const Text = styled.div`
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (min-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const StyledTextContainer = styled(Container)`
    padding-top: 40px;
    padding-bottom: 35px;
    background: white;

    @media screen and (min-width: 768px) {
        padding-top: 80px;
        padding-bottom: 75px;
    }

    @media screen and (min-width: 1100px) {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    &.without-hero-block {
        padding-top: 20px;

        @media screen and (min-width: 768px) {
            padding-top: 40px;
        }

        @media screen and (min-width: 1100px) {
            padding-top: 75px;
        }
    }
`

const CONTAINER = 'TextContainer'
export const TextContainer = React.forwardRef(({ children, className, style }, ref) => (
    <StyledTextContainer ref={ref} className={className} style={style}>
        <Text>{children}</Text>
    </StyledTextContainer>
))
TextContainer.displayName = CONTAINER

const StyledHeroBlock = styled(Block)`
    background: url(${(props) => props.background}) no-repeat center center;
    background-size: cover;
    padding-top: 32px;
    padding-bottom: 27px;

    @media screen and (min-width: 768px) {
        padding-top: 60px;
        padding-bottom: 120px;
    }

    @media screen and (min-width: 1100px) {
        padding-top: 75px;
        padding-bottom: 120px;
    }
`

const HERO = 'Hero'
export const Hero = React.forwardRef(({ children, className, background, style }, ref) => (
    <StyledHeroBlock
        ref={ref}
        {...{
            background,
            className,
            style,
        }}
    >
        {children}
    </StyledHeroBlock>
))
Hero.displayName = HERO

const FancyBase = styled.span`
    line-height: 1;
`

export const FancyBig = styled(FancyBase)`
    font-size: 2.4em;
`

export const FancySmall = styled(FancyBase)`
    font-size: 1.6em;
    margin-top: -10px;
`

export const FancyText = styled.span`
    color: ${(props) => props.theme.color.tellowGreen};
    font-weight: bold;
    position: relative;
`

export const Pair = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
        flex-direction: row;
    }
`

export const Item = styled.div`
    width: 100%;

    @media screen and (min-width: 1100px) {
        width: 50%;
        order: ${(props) => (props.left ? 0 : 1)};
    }
`

export const getCanonicalFromPath = (path) => `${config.url}${config.pathPrefix || ''}${path}`

export const StickyLegalMenu = styled.nav`
    position: sticky;
    top: 50px;
    background-color: white;

    .sticky {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
        list-style-type: none;
        border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);

        li {
            margin: 8px;
            width: 40%;
            text-align: center;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 80px 2rem 0 2rem;
        max-width: 20%;
        position: static;

        .sticky {
            display: inline-block;
            position: sticky;
            flex-direction: column;
            top: 80px;
            border-bottom: none;

            li {
                margin: 8px 0;
                width: unset;
                text-align: right;
            }
        }
    }

    @media screen and (min-width: 1100px) {
        padding: 150px 0 0 4rem;
    }
`

export const LegalContent = styled(Container)`
    display: flex;
    flex-direction: column;
    background: white;

    h2 {
        margin-top: 2em;
    }

    ul,
    ol {
        list-style-position: inside;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
    }
`

const GreyPatternDots = styled.div`
    z-index: -1;
    height: 100%;
    background: radial-gradient(closest-side at 12px 12px, #efefef 30%, #fff 40%);
    background-size: 30px 30px;

    @media screen and (min-width: 768px) {
        height: 500px;
    }
`

export const PatternDots = ({ className, style }) => <GreyPatternDots className={className} style={style} />
