/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

import greenwave from '../../../images/general/greenwave.svg'
import purplewave from '../../../images/general/purplewave.svg'
import whitewave from '../../../images/general/whitewave.svg'
import bluewave from '../../../images/general/bluewave.svg'
import lightbluewave from '../../../images/general/lightbluewave.svg'
import lightgraywave from '../../../images/general/lightgraywave.svg'
import redwave from '../../../images/general/redwave.svg'
import yellowwave from '../../../images/general/yellowwave.svg'
import gray300wave from '../../../images/general/gray300wave.svg'

const Wave = styled.div`
    z-index: -1;
    position: relative;
    height: auto;

    &:before {
        content: '';
        display: block;
        height: 2rem;
        /* width:100%; */
        background-size: cover;
        background-image: url(${(props) =>
            props.green
                ? greenwave
                : props.purple
                ? purplewave
                : props.blue
                ? bluewave
                : props.gray300
                ? gray300wave
                : props.color === 'purple'
                ? purplewave
                : props.color === 'green'
                ? greenwave
                : props.color === 'blue'
                ? bluewave
                : props.color === 'white'
                ? whitewave
                : props.color === 'lightblue'
                ? lightbluewave
                : props.color === 'lightgray'
                ? lightgraywave
                : props.color === 'red'
                ? redwave
                : props.color === 'yellow'
                ? yellowwave
                : whitewave});
        background-repeat: no-repeat;
        margin-bottom: -1px;
        margin-top: -2rem;
    }
`

export default Wave
