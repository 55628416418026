import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import config from '../../../config'
import ogImage from '../../../images/opengraph/og.png'

export const MetaTags = ({ title, description, canonical, keywords, image = ogImage, lang = 'nl', indexed }) => (
    <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="robots" content={indexed} />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <script src="https://www.googleoptimize.com/optimize.js?id=GTM-PTPC9X9" />
        {image && <meta name="image" content={image} />}
        {keywords.length > -1 && <meta name="keywords" content={keywords.join(', ')} />}
    </Helmet>
)

MetaTags.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
    keywords: PropTypes.array,
    image: PropTypes.string,
    indexed: PropTypes.string,
}
MetaTags.defaultProps = {
    keywords: [],
    image: undefined,
    indexed: 'index',
}

export const JsonLD = ({ json }) => (
    <Helmet>
        <script type="application/ld+json">{JSON.stringify(json)}</script>
    </Helmet>
)

export const OpenGraph = ({ type, canonical, title, description, image = ogImage }) => (
    <Helmet>
        {type && <meta property="og:type" content={type} />}
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:url" content={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={config.organization} />
        {image && <meta property="og:image" content={image} />}
        <meta property="fb:app_id" content={286640322197052} />
    </Helmet>
)

OpenGraph.propTypes = {
    type: PropTypes.oneOf(['article']),
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
}

OpenGraph.defaultProps = {
    type: 'article',
    image: undefined,
}

export const TwitterMeta = ({ creator, title, description, image = config.logo }) => (
    <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={creator} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="twitter:image" content={image} />}
    </Helmet>
)

TwitterMeta.propTypes = {
    creator: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
}

TwitterMeta.defaultProps = {
    image: undefined,
}
