/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from 'styled-components'

import { Wave } from '../../UI'

const shared = `
    line-height: 25px;
    border-radius: 35px;
    padding: 23px 40px 22px;
    border: none;
    font-weight: bold;
`

const RegisterContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;

    position: relative;
    max-width: 900px;
    padding: 2rem 2rem 4rem 2rem;

    #privacy-policy {
        transition: opacity 0.3s ease;
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.8rem;

        &:hover {
            opacity: 0.6;
        }
    }

    form {
        display: flex;
        flex: 1;

        margin-top: 2rem;
        max-width: 650px;
        width: 100%;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            text-align: center;

            input {
                margin-bottom: 1rem;
                margin-right: 0 !important;
            }
        }

        input[type='email'] {
            ${shared}

            flex: 3;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
            color: #4840bb;
            font-weight: bold;
            margin-right: 1rem;
            transition: box-shadow 0.15s ease-in-out;
        }

        input[type='submit'] {
            ${shared}

            flex: 1;
            color: white;
            background: ${({ theme }) => theme.color.tellowPurple};
        }

        input[type='email']:focus {
            outline: none;
            box-shadow: 0px 0 0 3px ${({ theme }) => theme.color.tellowPurple};
            transition: box-shadow 0.15s ease-in-out;
        }
    }
`

const ColoredBackground = styled.div`
    padding: 2rem 0;
    color: ${(props) =>
        props.purple ? props.theme.color.new.softWhite : props.green ? 'inherit' : props.red ? props.theme.color.new.softWhite : props.blue ? 'white' : 'inherit'};
    background: ${(props) =>
        props.purple
            ? props.theme.color.tellowPurple
            : props.green
            ? props.theme.color.tellowGreen
            : props.red
            ? props.theme.color.tellowRed
            : props.blue
            ? props.theme.color.tellowBlue
            : 'white'};

    h2 {
        color: ${(props) => (props.forceWhiteHeader ? 'white' : 'inherit')};
    }
`

function RegisterAsPartnerBlock() {
    const [email, setEmail] = useState('')

    const redirectToTypeform = (e) => {
        e.preventDefault()
        window.location.assign(`https://tellow.typeform.com/to/r6boUj?email=${email}`)
    }

    return (
        <>
            <Wave white />
            <ColoredBackground white>
                <RegisterContainer light>
                    <h2>Maak vandaag nog je gratis partneraccount aan</h2>
                    <p>Alles wat je nodig hebt om van start te gaan van waar je je nu bevindt naar waar je naartoe wil.</p>

                    <form onSubmit={(e) => redirectToTypeform(e)}>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="jouw@email.com" />
                        <input type="submit" />
                    </form>
                    <a href="/privacy" target="_blank" rel="noopener noreferrer" id="privacy-policy">
                        Privacybeleid van Tellow
                    </a>
                </RegisterContainer>
            </ColoredBackground>
        </>
    )
}

export default RegisterAsPartnerBlock
