const colors = require('@hellotellow/design/colors/index.cjs')

module.exports = {
    color: {
        tellowWhite: colors.white[100],
        tellowGray100: '#fafafa',
        tellowGray200: '#e9ecef',
        tellowGray300: '#dee2e6',
        tellowGray400: '#ced4da',
        tellowGray500: '#adb5bd',
        tellowGray600: '#495057',
        tellowGray700: '#343a40',
        tellowGray800: '#232628',

        tellowBlack: '#0b2242', // v -> same as 'blue'
        tellowBlue: '#0b2242', // v -> also 'black'

        tellowBlueLight: '#50c7ec', // v
        tellowPurple: '#5950ec', // v
        tellowPurpleLight: '#eeeefe',
        tellowGreen: '#26e7d5', // v -> rename to teal
        tellowDarkGreen: ' #20D69D',
        tellowRed: '#ee5656', // v
        tellowYellow: '#ffcc68', // v
        tellowOrange: '#ff8e4f',

        // TODO: Clean this up. Deprecate.
        new: {
            softWhite: '#efebd7', // temporary softer white, contrasting with purple
        },
    },
    gradient: {
        backgroundGreyToWhite: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(241,241,241,0) 100%)',
    },
    headerFontFamily: [
        'Plus Jakarta Sans',
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI", Roboto',
        'Helvetica Neue',
        'Arial',
        'Noto Sans',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji',
    ],
    bodyFontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI", Roboto',
        'Helvetica Neue',
        'Arial',
        'Noto Sans',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji',
    ],
    textShadow: '1px 1px 4px black',
    shadow: '0 10px 18px 0 rgba(0, 0, 0, 0.08)',
    heavyShadow: '0 14px 20px 0 rgba(0, 0, 0, 0.12)',
    largeShadow: '0 0 80px 0 rgba(0, 0, 0, 0.10)',
    lightShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.06)',
    subtleShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.06)',
    smoothShadow: '0 6px 14px rgba(0, 0, 0, 0.02), 0 12px 28px rgba(0, 0, 0, 0.05)',
    menuShadow: '0 0 6px rgba(0,0,0,0.02), 0 0px 6px rgba(0,0,0,0.05), 0 0 20px 0px rgba(0,0,0,.05), 0 0 1px rgba(0,0,0,0.1)',
    shadowFilter: '0 10px 20px rgba(0, 0, 0, 0.10)',
    highlight: '0px 0px 100px -20px rgba(255,255,255,.8)',
    zIndex: {
        balloonBackground: 3,
        balloonContent: 4,
        hero: 5,
        ribbon: 6,
        submenu: 8,
        menu: 30,
        navbar: 40,
        consent: 99999999999, // because of intercom chat-button
        notification: 999999999,
    },
    radii: {
        border: {
            input: '30px',
            full: '999px',
        },
    },
    font: {
        size: {
            xxxs: '0.5rem',
            xxs: '0.625rem',
            xs: '0.8rem',
            sm: '0.9rem',
            base: '1rem',
        },
        weight: {
            regular: 400,
            semibold: 500,
            bold: 600,
        },
    },
}

//
