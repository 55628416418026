import canUseDOM from 'can-use-dom'

const Segment = {
    track: (event, data = {}) => {
        if (canUseDOM && window.analytics) {
            window.analytics.track(`${event}`, data)
        }
    },
}

export default Segment
