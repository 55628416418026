import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { motion } from 'framer-motion'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { ColoredBackground, Wave } from '../UI'

/**
 * TailwindUI Hero Component
 *
 * Component derived from:
 * https://tailwindui.com/components/marketing/sections/heroes
 * --> "With angled image on right".
 *
 * Modified to work with CSS-in-JS / styled-components.
 */

const globalWidth = 1080

const Container = styled.main`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    max-width: ${globalWidth}px;
    padding: 0 2rem;
    overflow: hidden;

    @media (min-width: 620px) {
        margin-top: 3rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    @media (min-width: 1024px) {
        margin-top: 4rem;
    }

    @media (min-width: 1280px) {
        margin-top: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
`

const Title = styled(motion.h1)`
    font-size: 4rem;
    letter-spacing: -0.025em;
    line-height: 3.75rem;
    font-weight: 800;
    white-space: pre-line;
    color: ${({ theme }) => theme.color.tellowPurple};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    z-index: 999;
`

const Letter = styled(motion.span)`
    display: flex;
`

const Body = styled.p`
    padding-top: 2rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.015em;
    line-height: 1.75rem;
    white-space: pre-line;
    color: ${({ theme }) => theme.color.tellowGray600};

    .fat {
        font-weight: 600;
        color: ${({ theme }) => theme.color.tellowGray700};
    }

    @media (max-width: 450px) {
        padding-top: 0.5rem;
    }

    @media (min-width: 620px) {
        margin-top: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        max-width: calc(${globalWidth}px / 2.5);
    }

    @media (min-width: 1024px) {
        margin-top: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    @media (min-width: 1280px) {
        margin-left: 0;
        margin-right: 0;
    }
`

const Buttons = styled.div`
    margin: 1.25rem 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;

    div {
        border-radius: 9999px;
        background-color: ${({ theme }) => theme.color.tellowWhite};
        transition: background-color 150ms ease-in-out;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 3rem;
            font-weight: 500;
            font-size: 0.925rem;
            letter-spacing: -0.015em;
            color: ${({ theme }) => theme.color.tellowPurple};
        }

        + div {
            margin-left: 1rem;
        }

        &:hover {
            background-color: ${({ theme }) => lighten(0.4, theme.color.tellowYellow)};
        }
    }
`

const Illustration = styled.div`
    top: 0;
    right: 0;
    position: absolute;
    margin-top: 10rem;
    width: 55%;

    @media (max-width: 750px) {
        display: none;
    }
`

const StyledImg = styled(GatsbyImage)`
    height: 30rem;
    object-fit: contain;

    @media (min-width: 760px) {
        height: 24rem;
    }

    picture {
        img {
            height: 30rem;
            object-fit: contain !important;
            width: auto !important;

            @media (min-width: 620px) {
                height: 18rem;
            }

            @media (min-width: 760px) {
                height: 21rem;
            }

            @media (min-width: 1024px) {
                height: 24rem;
            }
        }
    }
`

export default function Hero() {
    const data = useStaticQuery(
        graphql`
            query {
                illustrationOne: file(relativePath: { eq: "illustrations/1-bg.png" }) {
                    ...fluidImageIllustration
                }
                illustrationTwo: file(relativePath: { eq: "illustrations/2-bg.png" }) {
                    ...fluidImageIllustration
                }
                illustrationThree: file(relativePath: { eq: "illustrations/3-bg.png" }) {
                    ...fluidImageIllustration
                }
                illustrationFour: file(relativePath: { eq: "illustrations/4-bg.png" }) {
                    ...fluidImageIllustration
                }
            }
        `
    )

    const taglines = [
        {
            tag: ['Alwéér een', 'bon kwijt?'],
            illustration: data.illustrationOne.childImageSharp.fluid,
        },
        {
            tag: ['Eh, de', 'bt-wattes?'],
            illustration: data.illustrationOne.childImageSharp.fluid,
        },
        {
            tag: ['Alwéér de', 'btw-aangifte', 'gemist?'],
            illustration: data.illustrationTwo.childImageSharp.fluid,
        },
        {
            tag: ['Hoéveel voor de', 'boekhouder?'],
            illustration: data.illustrationThree.childImageSharp.fluid,
        },
        {
            tag: ['Eigen baas?', 'Trots op je!'],
            illustration: data.illustrationFour.childImageSharp.fluid,
        },
    ]

    // Default/fallback values.
    const [random, setRandom] = useState(0)
    const [image, setImage] = useState(null)

    // Set random tag on client-side.
    useEffect(() => {
        const useEffectRandom = Math.floor(Math.random() * taglines.length)
        setRandom(useEffectRandom)
        setImage(taglines[useEffectRandom].illustration)
    }, [])

    // Map tagline to single array.
    const titleSpacingGenerator = Object.values(taglines[random].tag).map((line) => `${line}`)

    // Animated title
    // Add staggering effect to the children of the container
    const containerVariants = {
        before: { transition: { delay: 1 } },
        after: { transition: { staggerChildren: 0.25 } },
    }

    const letterVariants = {
        before: {
            opacity: 0,
            y: 20,
            transition: {
                type: 'spring',
                damping: 16,
                stiffness: 200,
            },
        },
        after: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                damping: 16,
                stiffness: 200,
            },
        },
    }

    return (
        <>
            <ColoredBackground yellow>
                <Container>
                    <Title center="y" variants={containerVariants} initial="before" animate="after">
                        {titleSpacingGenerator.map((line, index) => (
                            <Letter
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                variants={letterVariants}
                            >
                                {`${line}\u00A0`}
                            </Letter>
                        ))}
                    </Title>
                    <Body>
                        <span className="fat">Boekhouden is lastig.</span>
                        <br />
                        Wij weten het als geen ander.{`\n`}
                        Laat ons je helpen door de bonnen het bos weer te zien.
                    </Body>
                    <Buttons>
                        <div>
                            <a href="https://app.tellow.nl/registreer" style={{ fontWeight: 'bold' }}>
                                Meld je nu gratis aan
                            </a>
                        </div>
                    </Buttons>
                </Container>
                <Illustration>{image !== null ? <StyledImg image={image} alt={titleSpacingGenerator.join('')} /> : ''}</Illustration>
            </ColoredBackground>
            <Wave white />
        </>
    )
}

export const fluidImage = graphql`
    fragment fluidImageIllustration on File {
        childImageSharp {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`
