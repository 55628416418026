import React from 'react'
import styled from 'styled-components'

const StyledContentCol = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
        flex-direction: column;
        &.reverse {
            flex-direction: column-reverse;
        }
    }
`

export const ContentCol = ({ children, className, style }) => (
    <StyledContentCol className={className} style={style}>
        {children}
    </StyledContentCol>
)

const StyledContentRow = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
        flex-direction: row;
        &.reverse {
            flex-direction: row-reverse;
        }
    }
    .tellow-logo {
        display: none;
        @media screen and (min-width: 768px) {
            display: inline-block;
            width: 150px;
            height: auto;
            margin-top: 60px;
            svg {
                fill: #4bbc90;
            }
        }
    }
    .sector-image {
        position: relative;
        width: 100%;
    }
`

export const ContentRow = ({ children, className, style }) => (
    <StyledContentRow className={className} style={style}>
        {children}
    </StyledContentRow>
)

const StyledRowTitle = styled.h2`
    text-align: left;
`

export const RowTitle = ({ children, className, style }) => (
    <StyledRowTitle className={className} style={style}>
        {children}
    </StyledRowTitle>
)

const StyledCardRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-flow: wrap;
    }
`

export const CardRow = ({ children, className, style }) => (
    <StyledCardRow className={className} style={style}>
        {children}
    </StyledCardRow>
)

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    min-height: 50px;
    @media screen and (min-width: 768px) {
        width: 40%;
        text-align: left;
    }
`
