import { useMemo, useState, useCallback, useEffect } from 'react'

// Import locales so they are preprocessed in build.
import nl from '../locale/nl/nl.json'
import en from '../locale/en/en.json'

// Alias locales, in-memory.
const locales = {
    nl,
    en,
}

/**
 * @typedef {Object} TranslateHookResponse
 *
 * @property {'nl' | 'en'} i18n - Current detected locale
 * @property {object} translation - Translation object
 * @property {object} t - Alias for `translation`
 * @property {boolean} isEnglish - Detection helper
 * @property {boolean} isDutch - Detection helper
 */

// Determine which language we are using.
const isUsingEnglishLanguage = () => (new RegExp(/^\/en\//, 'gi').test(window.location.pathname) ? 'en' : 'nl')

// Resolve key from deep object notation.
const resolveFromDeepObject = (object, string) => string.split('.').reduce((acc, curr) => acc[curr], object)

/**
 * Custom hook to replicate i18next's hook,
 * without having to adapt their setup.
 *
 * This hook is inspired by i18next's hook however,
 * so when the codebase has to grow, we can adapt this.
 *
 * @param {string} namespace
 * @param {string} deepObjectSelection
 * @returns {TranslateHookResponse}
 */
const useTranslate = (namespace, deepObjectSelection = '') => {
    const [i18n, setI18n] = useState('nl')

    // Set initial language.
    useEffect(() => {
        setI18n(isUsingEnglishLanguage())
    }, [])

    // Get the file and use it.
    const file = locales[i18n]
    const source = namespace ? file[namespace] : file
    const translation = deepObjectSelection ? useCallback(resolveFromDeepObject(source, deepObjectSelection)) : source

    // Helpers
    const isEnglish = useMemo(() => i18n === 'en')
    const isDutch = useMemo(() => i18n === 'nl')

    return { i18n, translation, t: translation, isEnglish, isDutch }
}

export default useTranslate
