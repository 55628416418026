import React from 'react'
import styled from 'styled-components'

const Picture = styled.img`
    border-radius: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;

    @media screen and (max-width: 1100px) {
        display: none;
    }
`

const FreelancerPicture = ({ url, alt }) => <Picture src={url} alt={alt} />

export default FreelancerPicture
