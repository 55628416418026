import React from 'react'
import styled from 'styled-components'
import Pill from '../Pill/pill'
import ChevronIcon from '../../../images/styleguide/chevron'

const StyledChevron = styled.div`
    font-size: 0;
    transform: rotate(${(props) => props.rotation}deg);
`

const Chevron = ({ className, style, direction, background, color }) => {
    const rotation = {
        bottom: 0,
        left: 90,
        top: 180,
        right: 270,
    }[direction]

    const props = { className, style, rotation }
    const propsPill = { background, color }

    return (
        <Pill {...propsPill}>
            <StyledChevron {...props}>
                <ChevronIcon />
            </StyledChevron>
        </Pill>
    )
}

export default Chevron
