import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import PartnerBlock from '../../components/pages/homepage/partnersBlock'
import { SocialProofBlock } from '../../components/blocks'
import ColumnHero from '../../components/pages/whitepaper/columnHero'
import SummaryBlock from '../../components/pages/whitepaper/summaryBlock'
import CTABlock from '../../components/pages/starter/CTABlock'
import StarterHero from '../../components/pages/starter/starterHero'

export const whitepaperQuery = graphql`
    query Starter {
        prismicStarters {
            id
            uid
            _previewable
            data {
                color
                bulletpoint_image {
                    alt
                    url
                }
                bulletpoints {
                    bulletpoint {
                        text
                        html
                    }
                }
                content {
                    text
                    html
                }
                content_header {
                    text
                    html
                }
                hero_image {
                    url
                    alt
                }
                label {
                    text
                }
                meta_description {
                    text
                }
                meta_title {
                    text
                }
                pre_cta {
                    html
                    text
                }
                title {
                    html
                    text
                }
                subkop {
                    text
                    html
                }
            }
        }
    }
`

const Starter = ({ data }) => {
    // Shorthand for whitepaper data
    const dpSd = data.prismicStarters.data

    // Setting up basic data, easier formatting
    const bulletpoints = []
    dpSd.bulletpoints.forEach((point) => bulletpoints.push(point.bulletpoint.text.toString()))

    // Set color fetched from Prismic
    const { color } = dpSd
    function setColor(c) {
        // Waterfall if-statement; final else returns 'lightGrey' hex-code.
        if (c === 'Orange') {
            return '#FBB36B'
        }

        if (c === 'Blue') {
            return '#52B9E9'
        }

        if (c === 'Darkblue') {
            return '#011532'
        }

        return '#E2E2E2'
    }

    return (
        <Layout>
            <Meta title={dpSd.meta_title.text || dpSd.title.text} description={dpSd.meta_description.text} image={dpSd.hero_image.url || ''} path="/starters" />
            <StarterHero title={dpSd.title.text} subtitle={dpSd.subkop.text} url={dpSd.hero_image.url} alt={dpSd.hero_image.alt} label={dpSd.label.text} />
            <ColumnHero title={dpSd.content_header.text} body={dpSd.content.html} color={setColor(color)} />
            <SummaryBlock data={bulletpoints} url={dpSd.bulletpoint_image.url} alt={dpSd.bulletpoint_image.alt} />
            <CTABlock data={dpSd.pre_cta.text} label={dpSd.label.text} />
            <SocialProofBlock />
            <PartnerBlock />
        </Layout>
    )
}

export default withPrismicPreview(Starter)
