import React from 'react'

const Graph = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.59998 19.8C3.59998 19.3226 3.78962 18.8648 4.12718 18.5272C4.46475 18.1897 4.92259 18 5.39998 18H8.99998C9.47736 18 9.9352 18.1897 10.2728 18.5272C10.6103 18.8648 10.8 19.3226 10.8 19.8V28.8C10.8 29.2774 10.6103 29.7353 10.2728 30.0728C9.9352 30.4104 9.47736 30.6 8.99998 30.6H5.39998C4.92259 30.6 4.46475 30.4104 4.12718 30.0728C3.78962 29.7353 3.59998 29.2774 3.59998 28.8V19.8ZM14.4 12.6C14.4 12.1226 14.5896 11.6648 14.9272 11.3272C15.2647 10.9897 15.7226 10.8 16.2 10.8H19.8C20.2774 10.8 20.7352 10.9897 21.0728 11.3272C21.4103 11.6648 21.6 12.1226 21.6 12.6V28.8C21.6 29.2774 21.4103 29.7353 21.0728 30.0728C20.7352 30.4104 20.2774 30.6 19.8 30.6H16.2C15.7226 30.6 15.2647 30.4104 14.9272 30.0728C14.5896 29.7353 14.4 29.2774 14.4 28.8V12.6ZM25.2 7.20002C25.2 6.72263 25.3896 6.2648 25.7272 5.92723C26.0647 5.58967 26.5226 5.40002 27 5.40002H30.6C31.0774 5.40002 31.5352 5.58967 31.8728 5.92723C32.2103 6.2648 32.4 6.72263 32.4 7.20002V28.8C32.4 29.2774 32.2103 29.7353 31.8728 30.0728C31.5352 30.4104 31.0774 30.6 30.6 30.6H27C26.5226 30.6 26.0647 30.4104 25.7272 30.0728C25.3896 29.7353 25.2 29.2774 25.2 28.8V7.20002Z"
            fill="currentColor"
        />
    </svg>
)

export default Graph
