/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useInView } from '../../../hooks'
import Report from '../../../images/icons/report'
import Btw from '../../../images/icons/btw'
import Bank from '../../../images/englishlp/icons/bank'
import HourRegistration from '../../UI/Icons/timeClock'
import IncomeTax from '../../UI/Icons/incomeTax'
import Quotes from '../../../images/englishlp/icons/quote.js'
import { Button } from '../../UI'

const featureBlockData = {
    features: [
        {
            path: 'btw-aangifte',
            Icon: Btw,
            title: 'Automatische btw-aangifte',
            body: 'Dien de btw-aangifte meteen in bij de Belastingdienst. Niet meer apart inloggen, nooit meer te laat indienen en voorkom boetes.',
        },
        {
            path: 'inkomstenbelasting',
            Icon: IncomeTax,
            title: 'Inkomstenbelasting',
            body: 'Een foutloze IB-aangifte, direct opgesteld vanuit Tellow. Maak gebruik van alle aftrekposten en boekhoudtips om het maximale over te houden.',
        },
        {
            path: 'koppel-je-rekening',
            Icon: Bank,
            title: 'Directe koppeling met alle banken',
            body: 'Koppel Tellow snel en veilig met elke Nederlandse bank, zodat je transacties automatisch geïmporteerd worden.',
        },
        {
            path: 'facturen',
            Icon: Quotes,
            title: 'Facturen & offertes',
            body: 'Maak offertes en facturen in een handomdraai, verstuur ze direct vanuit Tellow en krijg een melding zodra er is betaald.',
        },
        {
            path: 'rapportages',
            Icon: Report,
            title: 'Rapportages',
            body: 'Altijd en overal inzicht in je financiën. Krijg inzicht in nog te ontvangen inkomsten, te betalen kosten en vooruitzichten voor de toekomst.',
        },
        {
            path: 'urenregistratie',
            Icon: HourRegistration,
            title: 'Uren- en kilometerregistratie',
            body: 'Gemakkelijk bijhouden hoeveel tijd je werkt voor een klant of project, of welke afstand je aflegt. Zet het vervolgens met gemak op je factuur.',
        },
    ],
}

const FeatureContainer = styled.div`
    display: grid;
    row-gap: 2.5rem;
    column-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);

    justify-content: space-evenly;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 3rem;
        gap: 2.5rem;
    }

    @media screen and (max-width: 555px) {
        grid-template-columns: 1fr;
        flex-direction: column;
        justify-content: center;
    }
`

const Feature = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    text-align: left;
    min-width: min-content;

    div > p {
        max-width: 38ch;

        a {
            color: ${({ theme }) => theme.color.tellowPurple};
            cursor: pointer;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 555px) {
        margin: 0 auto;
        flex: 1;
    }
`

const styles = {
    container: tw`max-w-6xl mx-auto px-4 pt-10 pb-6 sm:px-6 lg:px-8`,
    content: tw`grid grid-cols-1 md:grid-cols-3 gap-x-2 gap-y-4 items-center justify-center flex-col w-full`,
    column: tw`col-span-2`,
    title: tw`mt-0! text-tellow-purple text-center flex-1 tracking-tight antialiased sm:text-2xl md:text-3xl mb-2`,
    subTitle: tw`opacity-70 mb-16`,
    features: {
        container: tw`w-full flex flex-row`,
        svg: tw`h-8 w-8 flex-initial fill-none! text-tellow-purple mr-3 rounded-full bg-tellow-purple bg-opacity-10 justify-center items-center p-1.5`,
        textual: tw`flex-1`,
        title: tw`text-base mb-2 md:text-lg text-gray-800 font-semibold tracking-tight leading-tight`,
        body: tw`mt-2 mb-0 antialiased font-normal text-sm text-tellow-gray-800 opacity-80 tracking-tight`,
        a: tw`text-xs text-tellow-purple inline-flex mt-4 px-5 py-2 rounded-full bg-tellow-purple bg-opacity-5 hover:bg-opacity-10 transition`,
    },
    label: tw`px-3 pb-2 bg-tellow-purple bg-opacity-10 text-tellow-purple rounded-lg m-0 text-xs font-medium antialiased`,
    action: {
        solid: tw`flex justify-center items-center`,
        container: tw`flex justify-center items-center m-8`,
    },
}

/**
 * Variants for Framer Motion.
 */
const animation = {
    item: {
        visible: { opacity: 1, transition: { duration: 0.5, type: 'easeInOut' } },
        hidden: { opacity: 0 },
    },
    container: {
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
                duration: 0.15,
            },
        },
        hidden: { opacity: 0 },
    },
}

const Image = ({ state }) => {
    const data = useStaticQuery(
        graphql`
            query {
                one: file(relativePath: { eq: "home/benefits/Btw.png" }) {
                    ...fluidImage
                }
                two: file(relativePath: { eq: "home/benefits/Tax.png" }) {
                    ...fluidImage
                }
                three: file(relativePath: { eq: "home/benefits/Bank.png" }) {
                    ...fluidImage
                }
                four: file(relativePath: { eq: "home/benefits/Invoices.png" }) {
                    ...fluidImage
                }
                five: file(relativePath: { eq: "home/benefits/Report.png" }) {
                    ...fluidImage
                }
                six: file(relativePath: { eq: "home/benefits/Hour.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    return (
        <div css={tw`relative hidden md:flex items-center ml-auto w-4/6`}>
            {Object.entries(data).map(([k, src], i) => (
                <GatsbyImage
                    critical
                    alt="Functie"
                    loading="eager"
                    key={`img${i}${k}`}
                    image={getImage(src)}
                    css={[state === i ? tw`flex` : tw`hidden!`, tw`relative w-full ml-auto`]}
                />
            ))}
        </div>
    )
}

export default function featureBlock({ content }) {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    const [activeIndex, setActiveIndex] = useState(0)

    /**
     * Scroll into view
     * lifecycle hook.
     */
    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    return (
        <section css={styles.container}>
            <h2 css={styles.title}>{content.primary.functions.text}</h2>
            <h2 css={[styles.title, styles.subTitle]}>{content.primary.sub_function.text}</h2>
            <div css={styles.content}>
                <div css={styles.column}>
                    <FeatureContainer ref={ref} animate={controls} initial="hidden" variants={animation.container}>
                        {featureBlockData.features.map((feature, index) => (
                            <Feature
                                onClick={() => setActiveIndex(index)}
                                onHoverStart={() => setActiveIndex(index)}
                                variants={animation.item}
                                css={styles.features.container}
                                className={activeIndex === index && 'active'}
                                id="feature"
                                key={`featureHighlight${index}`}
                            >
                                <div css={styles.features.svg}>
                                    <feature.Icon />
                                </div>
                                <div css={styles.features.textual}>
                                    <a href={`/functies/${feature.path}`} css={styles.features.textual}>
                                        <p css={styles.features.title}>{feature.title}</p>
                                        <p css={styles.features.body}>
                                            {feature.body}
                                            <br />
                                        </p>
                                    </a>
                                    <a href={`/functies/${feature.path}`} css={styles.features.a}>
                                        Lees meer
                                    </a>
                                </div>
                            </Feature>
                        ))}
                    </FeatureContainer>
                </div>
                <Image state={activeIndex} />
            </div>
            <div style={styles.action.container}>
                <Button href="/functies" big purple css={styles.action.solid}>
                    Bekijk alle functionaliteiten <span className="arrow">{'->'}</span>
                </Button>
            </div>
        </section>
    )
}

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
