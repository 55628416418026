/* eslint-disable no-nested-ternary */
import React from 'react'
import styled, { css } from 'styled-components'

const StyledButton = styled.a`
    cursor: pointer;
    display: inline-flex;
    height: fit-content;
    padding: ${(props) => (props.big ? '1rem 2.5rem' : props.medium ? '1rem' : props.pricingMobile ? '0.65rem 1rem' : props.narrow ? '0.4rem .75rem' : '0.4rem 1.75rem')};

    box-shadow: ${(props) => (props.subtleShadow ? props.theme.subtleShadow : props.lightShadow ? props.theme.lightShadow : props.noShadow ? 'none' : 'none')};
    border-radius: 0.625rem;

    font-weight: 600;
    font-size: 0.8rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* With border prop */
    ${(props) =>
        props.border !== undefined || props.border === true
            ? css`
                  border-width: ${({ borderWidth }) => borderWidth || 2}px;
                  border-style: solid;
                  border-radius: ${({ borderRadius }) => borderRadius || 0.625}rem;
                  border-color: ${({ primary, borderColor, theme }) => (primary ? theme.color.tellowGray200 : borderColor || css`rgba(255, 255, 255, 0.25)`)};

                  &:hover {
                      border-color: ${({ primary, borderColor, theme }) => (primary ? theme.color.tellowGray400 : borderColor || theme.color.tellowWhite)};
                  }
              `
            : css`
                  border: 2px solid transparent;
              `};

    background-color: ${(props) =>
        props.disabled
            ? props.theme.color.tellowGray200
            : props.transparent || props.whiteTransparent
            ? 'transparent'
            : props.primary
            ? props.theme.color.tellowWhite
            : props.tertiary
            ? props.theme.color.tellowBlue
            : props.grey
            ? props.theme.color.tellowGray500
            : props.purple
            ? props.theme.color.tellowPurple
            : props.white
            ? props.theme.color.tellowWhite
            : props.red
            ? props.theme.color.tellowRed
            : props.gray
            ? props.theme.color.tellowGray800
            : props.green
            ? props.theme.color.tellowGreen
            : props.agerasGreen
            ? '#01FF94'
            : props.lightPurple
            ? props.theme.color.tellowPurpleLight
            : props.theme.color.tellowPurple};
    color: ${(props) =>
        props.disabled
            ? props.theme.color.tellowGray800
            : props.primary
            ? props.theme.color.tellowBlue
            : props.tertiary
            ? props.theme.color.tellowWhite
            : props.purple || props.whiteTransparent
            ? props.theme.color.tellowWhite
            : props.white || props.transparent
            ? props.theme.color.tellowPurple
            : props.lightPurple
            ? props.theme.color.tellowPurple
            : props.green
            ? props.theme.color.tellowWhite
            : props.agerasGreen
            ? props.theme.color.tellowPurple
            : props.theme.color.tellowWhite};

    *[arrow],
    .arrow {
        margin-left: 4px;
        margin-right: 4px;
        white-space: nowrap;
    }

    &:hover {
        transition: 150ms ease-in-out;
        opacity: 0.9;

        *[arrow],
        .arrow {
            transition: 150ms ease-in-out;
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &.filter-tag {
        margin: 8px;
        transition: box-shadow, color 0.15s;

        &:hover {
            color: #388869;
            transition: box-shadow, color 0.18s;
        }

        &:hover:active {
            box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        }
    }
`

const Button = ({ children, ...other }) => (
    <StyledButton {...other} rel="noopener noreferrer">
        {children}
    </StyledButton>
)

export default Button
