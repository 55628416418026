import React from 'react'
import { Block, Text } from '../partners'
import { RegisterAsPartnerForm } from '../../CTA'

export default function TypeForm({ title, body, color, buttonUrl, buttonUsed, buttonText }) {
    const titleProp = title.text
    const bodyProp = body.html
    const buttonTextProp = buttonText.text

    return (
        <Block color={color || 'white'}>
            <Text center>
                {titleProp ? <h2>{titleProp}</h2> : null}
                {bodyProp ? <div dangerouslySetInnerHTML={{ __html: bodyProp }} /> : null}
                {buttonUsed ? <RegisterAsPartnerForm center text={buttonTextProp} url={buttonUrl.url} /> : null}
            </Text>
        </Block>
    )
}
