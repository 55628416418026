import React from 'react'
import tw from 'twin.macro'

const styles = {
    bg: tw`bg-gray-800`,
    wrap: tw`w-full max-w-7xl py-15 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 items-center`,
    h2: tw`text-white text-left font-black tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    h3: tw`antialiased inline-block items-center text-white font-normal tracking-tight sm:text-xl md:text-2xl lg:text-3xl`,
    span: tw`text-tellow-green`,
}

const Price = ({ key, title, body }) => (
    <div key={key} css={styles.bg}>
        <div css={[styles.wrap, styles.grid]}>
            <h2 css={styles.h2}>
                {title.split(', ')[0]}
                {title.split(', ')[1] && <br />}
                {title.split(', ')[1] && <span css={styles.span}>{title.split(', ')[1]}</span>}
            </h2>
            <h3 css={styles.h3}>{body}</h3>
        </div>
    </div>
)

export default Price
