import tw, { styled } from 'twin.macro'

export const Container = styled.div`
    ${tw`bg-white`}
`

export const Spacing = styled.div`
    ${tw`max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8`}
`

export const StyledContainer = styled.div`
    ${tw`rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4`}
    background: ${({ theme }) => theme.color.tellowPurple};
`

export const Copy = styled.div`
    ${tw`pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20`}
`

export const Center = styled.div`
    ${tw`lg:self-center`}
`

export const Heading = styled.h2`
    ${tw`text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10`}
`

export const Span = styled.span`
    ${tw`block`}

    &:empty {
        display: none;
    }
`

export const P = styled.p`
    ${tw`mt-4 text-lg leading-6 text-indigo-200`}
`

export const A = styled.a`
    ${tw`mt-8 bg-white border border-transparent rounded-full shadow px-6 py-3 inline-flex items-center text-base leading-6 font-medium hover:text-tellow-purple hover:bg-gray-50 transition duration-150 ease-in-out`}
    color: ${({ theme }) => theme.color.tellowPurple};
`

export const Placement = styled.div`
    ${tw`relative pb-3/5 -mt-6 md:pb-1/2`}
`

export const Screenshot = styled.img`
    ${tw`absolute inset-0 w-full h-full transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20`}
`
