/* eslint-disable array-callback-return */
import React from 'react'
import styled from 'styled-components'

import { Block, Text } from '../partners'

const Code = styled.div`
    color: ${({ theme }) => theme.color.tellowWhite};
    border-radius: 1rem;
    /* border: 2px solid ${({ theme }) => theme.color.tellowWhite}; */
    box-shadow: 0 0 50px 0 rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.3);
    font-size: 2rem;
    font-weight: 600;
    font-family: monospace;
    letter-spacing: 0.1em;
    margin: 2rem 0;
    padding: 1rem 4rem;
    min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Column = styled.div`
    max-width: 650px;
    margin: 1rem auto;
    opacity: 0.9;
`

const updateClipboard = (newClip) => {
    navigator.clipboard.writeText(newClip)
}

// TODO: Update with 'click to copy' confirmation / UI / UX.

export default function Coupon({ color, code, title, body }) {
    return (
        <Block reducedHeight color={color || 'blue'} center>
            <Text center>
                <h2>{title}</h2>
                <Code onClick={() => updateClipboard(code)} color={color}>
                    {code}
                </Code>
                <Column dangerouslySetInnerHTML={{ __html: body }} />
            </Text>
        </Block>
    )
}
