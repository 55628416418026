import React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import map from './page-resolve-map'

const linkResolver = require('./src/utils/prismic/linkResolver')

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
        repositoryConfigs={[
            {
                repositoryName: 'tellow',
                linkResolver: (doc) => linkResolver(doc),
                componentResolver: componentResolverFromMap(map),
            },
        ]}
    >
        {element}
    </PrismicPreviewProvider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
    function trackSegmentPage() {
        // initialized is available when Segment has been invoked.
        if (window.analytics && window.analytics.initialized) {
            // eslint-disable-next-line no-unused-expressions
            window.analytics && window.analytics.page()
        }
    }

    trackSegmentPage()
}
