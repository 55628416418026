import React from 'react'
import styled from 'styled-components'

const BadgeWrapper = styled.span`
    background-color: ${(props) => props.theme.color.tellowPurpleLight};
    color: ${(props) => props.theme.color.tellowPurple};
    padding: 7px 12px;
    border-radius: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-self: flex-start;

    &.purple {
        color: ${(props) => props.theme.color.tellowWhite} !important;
        background-color: ${(props) => props.theme.color.tellowPurple} !important;
        font-weight: 500;
    }
`

const Badge = (props) => <BadgeWrapper className={props.purple ? 'purple' : undefined}>{props.tag}</BadgeWrapper>

export default Badge
