/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
    font-size: 4rem;
    letter-spacing: -0.025em;
    line-height: 3.75rem;
    font-weight: 800;
    white-space: pre-line;
    color: ${(props) => resolveColorFromProps(props.passedColor)};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;

    @media screen and (max-width: 400px) {
        font-size: 1.5rem;
        letter-spacing: -0.015em;
        line-height: 1.5rem;
    }
`

const resolveColorFromProps = (props) =>
    props.color === 'purple'
        ? props.theme.color.new.softWhite
        : props.color === 'green'
        ? 'inherit'
        : props.color === 'red'
        ? props.theme.color.new.softWhite
        : props.color === 'yellow'
        ? props.theme.color.tellowGray800
        : props.color === 'blue'
        ? 'white'
        : props.color === 'lightblue'
        ? 'white'
        : props.color === 'lightgray'
        ? props.theme.color.tellowGray800
        : props.color === 'grayblue'
        ? props.theme.color.tellowGray800
        : 'inherit'

export default function title({ children, color }) {
    return <Title passedColor={color}>{children}</Title>
}
