import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from '../../UI'

const ShareComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;

    margin-top: 2rem;

    p {
        color: ${({ theme }) => theme.color.tellowPurple};
        font-size: 1.25rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .url {
        display: flex;
        flex: 1;
        width: 100%;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            text-align: center;

            .input {
                margin-bottom: 1rem;
                margin-right: 0 !important;
            }
        }

        .input {
            flex: 1;
            background: white;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
            color: #4840bb;
            font-weight: bold;
            line-height: 25px;
            border-radius: 35px;
            padding: 23px 40px 22px;
            border: none;
            margin-right: 1rem;
        }

        input:focus {
            outline: none;
            box-shadow: inset 0px 0px 0px 3px ${({ theme }) => theme.color.tellowPurple};
        }
    }
`

const url = 'https://www.tellow.nl/corona-steunpakket'

export default class Share extends Component {
    copyURL() {
        return navigator.clipboard.writeText(url)
    }

    render() {
        return (
            <ShareComponent>
                <p>Deel met andere zpp'ers.</p>
                <div className="url">
                    <div className="input">{url}</div>
                    <Button big purple subtleShadow onClick={() => this.copyURL()}>
                        Kopieër link
                    </Button>
                </div>
            </ShareComponent>
        )
    }
}
