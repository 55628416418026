/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

const ColoredBackground = styled.div`
    z-index: -1;
    padding: 2rem 0;
    color: ${(props) =>
        props.purple || props.color === 'purple'
            ? props.theme.color.new.softWhite
            : props.green || props.color === 'green'
            ? 'inherit'
            : props.red || props.color === 'red'
            ? props.theme.color.new.softWhite
            : props.yellow || props.color === 'yellow'
            ? props.theme.color.tellowGray800
            : props.blue || props.color === 'blue'
            ? 'white'
            : props.lightblue || props.color === 'lightblue'
            ? 'white'
            : props.lightgray || props.color === 'lightgray'
            ? props.theme.color.tellowGray800
            : props.grayblue || props.color === 'grayblue'
            ? props.theme.color.tellowGray800
            : 'inherit'};
    background: ${(props) =>
        props.purple || props.color === 'purple'
            ? props.theme.color.tellowPurple
            : props.green || props.color === 'green'
            ? props.theme.color.tellowGreen
            : props.red || props.color === 'red'
            ? props.theme.color.tellowRed
            : props.blue || props.color === 'blue'
            ? props.theme.color.tellowBlue
            : props.yellow || props.color === 'yellow'
            ? props.theme.color.tellowYellow
            : props.lightblue || props.color === 'lightblue'
            ? props.theme.color.tellowBlueLight
            : props.lightgray || props.color === 'lightgray'
            ? props.theme.color.tellowGray100
            : // Deprecated for 'blue'
            props.grayblue || props.color === 'grayblue'
            ? props.theme.color.tellowBlue
            : // Fallback
              'white'};
`

export default ColoredBackground
