/* eslint-disable array-callback-return */
import React from 'react'
import { Block, Text } from '../partners'
import { RegisterAsPartnerButton } from '../../CTA'

const typeEnum = {
    TEXT_ONLY: 'Just text / No image',
    IMAGE_TEXT: 'L: Image / R: Text',
    TEXT_IMAGE: 'L: Text / R: Image',
}

export default function Freeform({ type = typeEnum.TEXT_ONLY, color, title, body, buttonUrl, image, buttonUsed, buttonText }) {
    const titleProp = title ? title.text : null
    const bodyProp = body || []
    // Only short text is centered. Paragraphs should be readable and thus left aligned.
    const shortText = bodyProp.text.split(' ').length <= 20
    const buttonTextProp = buttonUsed ? buttonText.text : 'Meld je nu aan'
    const img = <img alt={title.text || null} src={(image && image.url) || null} />

    // Check which content is present
    const hasTitle = Boolean(titleProp)
    const isQuote = shortText && !buttonUsed && !hasTitle && type === typeEnum.TEXT_ONLY

    return (
        <Block unlabelled isQuote={isQuote} textOnly={type === typeEnum.TEXT_ONLY} color={color || 'white'} center={type === typeEnum.TEXT_ONLY}>
            {type !== typeEnum.TEXT_ONLY && type === typeEnum.TEXT_IMAGE ? img : null}
            <Text parentHasImage={type === typeEnum.TEXT_ONLY} hasImages={type !== typeEnum.TEXT_ONLY} center={type === typeEnum.TEXT_ONLY && shortText}>
                {titleProp ? <h2>{titleProp}</h2> : null}

                <div dangerouslySetInnerHTML={{ __html: body.html }} />

                {buttonUsed ? <RegisterAsPartnerButton center={type === typeEnum.TEXT_ONLY} text={buttonTextProp} url={buttonUrl.url || null} /> : null}
            </Text>
            {type !== typeEnum.TEXT_ONLY && type === typeEnum.IMAGE_TEXT ? img : null}
        </Block>
    )
}
