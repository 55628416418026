import { useRef, useEffect } from 'react'

function useEventListener(eventName, handler, element) {
    // Create a ref that stores handler
    const savedHandler = useRef()

    useEffect(() => {
        // Define the listening target
        const targetElement = element?.current || window
        if (!(targetElement && targetElement.addEventListener)) {
            return
        }

        // Update saved handler if necessary
        if (savedHandler.current !== handler) {
            savedHandler.current = handler
        }

        // Create event listener that calls handler function stored in ref
        const eventListener = (event) => {
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!savedHandler?.current) {
                savedHandler.current(event)
            }
        }

        targetElement.addEventListener(eventName, eventListener)

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element, handler])
}

export default useEventListener
