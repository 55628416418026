import PropTypes from 'prop-types'
import config from '../../../config'

export const WebSite = ({ title, canonical }) => ({
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: canonical,
    name: title,
})
WebSite.propTypes = {
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export const BlogPosting = ({ title, description, image, canonical, author, organization, datePublished, dateModified }) => ({
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: canonical,
    name: title,
    alternateName: config.title,
    headline: title,
    dateModified,
    datePublished,
    image: {
        '@type': 'ImageObject',
        url: image,
    },
    description,
    author: {
        '@type': 'Person',
        name: author,
    },
    publisher: {
        '@type': 'Organization',
        url: config.url,
        logo: {
            '@type': 'ImageObject',
            name: 'Tellow Logo',
            width: '48',
            height: '48',
            url: config.logo,
        },
        name: organization,
    },
    mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': config.url,
    },
})
BlogPosting.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    organization: PropTypes.string.isRequired,
    datePublished: PropTypes.string.isRequired,
}

export const ReviewsSnippet = ({ name, applicationCategory, aggregateRating, address, contactPoint, url, logo }) => ({
    '@context': 'http://schema.org',
    '@type': 'http://schema.org/WebApplication',
    name,
    applicationCategory,
    aggregateRating,
    address,
    contactPoint,
    url,
    logo,
})

ReviewsSnippet.propTypes = {}

export const BreadcrumbList = ({ title, canonical, image }) => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': canonical,
                name: title,
                image,
            },
        },
    ],
})
BlogPosting.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
}

export const reviewStructuredData = [
    ReviewsSnippet({
        name: 'Tellow',
        applicationCategory: 'FinanceApplication',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: config.rating.klantenvertellen.score,
            reviewCount: config.rating.klantenvertellen.count,
            bestRating: config.rating.klantenvertellen.best,
            itemReviewed: {
                '@type': 'Organization',
                name: 'Tellow',
            },
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: config.address.street,
            addressLocality: config.address.city,
            postalCode: config.address.postalcode,
            addressCountry: config.address.country,
        },
        contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'Support',
            email: 'support@tellow.nl',
            hoursAvailable: {
                '@type': 'OpeningHoursSpecification',
                opens: config.support.opensAtTime,
                closes: config.support.closesAtTime,
            },
        },
        url: 'https://www.tellow.nl',
        logo: 'https://www.tellow.nl/email/images/tellow_woordmerk.png',
    }),
]
