import React from 'react'

const Scanning = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.2001 8.99999C6.24532 8.99999 5.32964 9.37928 4.65451 10.0544C3.97938 10.7295 3.6001 11.6452 3.6001 12.6V27C3.6001 27.9548 3.97938 28.8704 4.65451 29.5456C5.32964 30.2207 6.24532 30.6 7.2001 30.6H28.8001C29.7549 30.6 30.6705 30.2207 31.3457 29.5456C32.0208 28.8704 32.4001 27.9548 32.4001 27V12.6C32.4001 11.6452 32.0208 10.7295 31.3457 10.0544C30.6705 9.37928 29.7549 8.99999 28.8001 8.99999H25.9453C25.4679 8.99989 25.0102 8.81018 24.6727 8.47259L22.6549 6.45479C21.9799 5.77962 21.0644 5.4002 20.1097 5.39999H15.8905C14.9358 5.4002 14.0203 5.77962 13.3453 6.45479L11.3275 8.47259C10.99 8.81018 10.5322 8.99989 10.0549 8.99999H7.2001ZM18.0001 25.2C18.7092 25.2 19.4114 25.0603 20.0666 24.7889C20.7217 24.5176 21.317 24.1198 21.8185 23.6184C22.3199 23.1169 22.7177 22.5216 22.989 21.8665C23.2604 21.2113 23.4001 20.5091 23.4001 19.8C23.4001 19.0909 23.2604 18.3887 22.989 17.7335C22.7177 17.0783 22.3199 16.4831 21.8185 15.9816C21.317 15.4802 20.7217 15.0824 20.0666 14.811C19.4114 14.5397 18.7092 14.4 18.0001 14.4C16.5679 14.4 15.1944 14.9689 14.1817 15.9816C13.169 16.9943 12.6001 18.3678 12.6001 19.8C12.6001 21.2322 13.169 22.6057 14.1817 23.6184C15.1944 24.6311 16.5679 25.2 18.0001 25.2Z"
            fill="currentColor"
        />
    </svg>
)

export default Scanning
