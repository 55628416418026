import React from 'react'

import { checkForLength } from './helpers'
import { Block, Text } from '../partners'
import { RegisterAsPartnerButton } from '../../CTA'

export default function Header({ title, body, buttonUrl, image, buttonUsed, buttonText }) {
    const titleProp = checkForLength(title) && title.text
    const bodyProp = checkForLength(body) && body.text
    const buttonTextProp = checkForLength(buttonText) && buttonText.text
    const hasImage = checkForLength(image) && image.url !== null

    return (
        <Block reducedHeight>
            <Text>
                {titleProp ? <h1>{titleProp}</h1> : null}
                {bodyProp ? <div dangerouslySetInnerHTML={{ __html: bodyProp }} /> : null}
                {buttonUsed ? <RegisterAsPartnerButton text={buttonTextProp} url={buttonUrl.url} /> : null}
            </Text>
            {hasImage ? <img alt="Header" src={image.url} /> : null}
        </Block>
    )
}
