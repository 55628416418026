import React from 'react'
import tw from 'twin.macro'
import { generic as faqs } from '../../../faqs'
import { Accordeon } from '../../UI'
import { Block } from '../../../utils'
// import CTA from '../../refactored/CTA'

const style = {
    container: tw`text-center pt-10`,
    wrapper: tw`text-white! relative mt-4 mb-8 text-left`,
    h2: tw`text-gray-800 font-black tracking-tight antialiased mt-12 sm:mb-4 sm:text-3xl md:text-4xl lg:text-5xl`,
    h3: tw`antialiased text-indigo-100 tracking-tight mb-12`,
    p: tw`mt-8 text-sm antialiased`,
    span: tw`text-white`,
    faq: tw`mx-auto py-12`,
}
const FAQBlock = () => (
    <Block css={style.container} backgroundColor="tellowPurple" color="white">
        <div>
            <h2 css={style.h2}>
                {/* Probeer Tellow 30 dagen gratis */}
                <br />
                <span css={style.span}> Automatiseer je administratie.</span>
            </h2>
        </div>

        <div css={style.faq} style={{ maxWidth: 760 }}>
            <div css={style.wrapper}>
                {faqs.map((item) => (
                    <Accordeon color="white" key={item.question} question={item.question}>
                        {item.answer}
                    </Accordeon>
                ))}
            </div>

            <hr css={tw`invisible mt-6`} />
            <h3 css={style.h3}>Start nu en ontvang gratis opstarthulp!</h3>

            {/* <CTA color="white" text="Probeer Tellow nu 30 dagen gratis" /> */}
        </div>
    </Block>
)

export default FAQBlock
