/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react'
import { XMLParser } from 'fast-xml-parser'
import tw from 'twin.macro'
import styled from 'styled-components'
import { rgba } from 'polished'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useAnimationFrame, useCurrentWidth, useMedia } from '../../../hooks'
import CTA from '../../refactored/CTA'
import quotes from './data/quotes.json'

/**
 * Global vars.
 * Used to filter out reviews.
 */
const klantenvertellen = 'klantenvertellen.nl'
const company = 'company'
const picture = 'picture'

/**
 * Text Fragment;
 * used to query on the
 * klantenvertellen.nl
 * website, without having
 * an anchor to link to.
 */
const fragment = (string) => string.replace(/^/, '#:~:text=').split(/\r?\n/)[0].replace(' ', '%20')

/**
 * Card is a separate review in a Row.
 * 'backdropFilter' used to create 'glass' effect.
 *
 * @param {string} review that should be rendered.
 * @param {string} author that wrote this.
 */
const cardstyle = {
    wrapper: tw`flex flex-col items-start bg-indigo-50 bg-opacity-80 border border-white text-indigo-800 justify-between whitespace-pre-wrap w-full h-full rounded-md`,
    body: tw`block px-8 pt-8 mb-6 text-xs`,
    author: tw`block px-8 text-xs font-bold mt-4 mb-0`,
    source: tw`block px-8 mb-4! text-xs font-normal text-indigo-400 antialiased mx-auto`,
}
const Capitalize = styled.p`
    &:first-letter {
        text-transform: capitalize;
    }
`
const Card = ({ review, author, link }) => (
    <div css={cardstyle.wrapper} style={{ backdropFilter: 'blur(5px)' }}>
        <Capitalize css={cardstyle.body}>{review}</Capitalize>
        <span>
            <Capitalize css={cardstyle.author}>{author}</Capitalize>
            <a css={cardstyle.source} href={`${link}${fragment(review)}`} target="_blank" rel="noopener noreferrer">
                via klantenvertellen.nl
            </a>
        </span>
    </div>
)

/**
 * Company is for i.e. reviews
 * from comparison websites.
 */
const companystyle = {
    wrapper: tw`flex flex-col items-start bg-tellow-purple border text-white justify-between whitespace-pre-wrap w-full h-full rounded-md! overflow-hidden!`,
    cite: tw`px-8 pt-8 mb-6 antialiased tracking-tight font-semibold text-base`,
    a: tw`px-8 mb-4! flex items-center justify-between w-full text-xs text-white antialiased opacity-60`,
    url: tw`z-10 text-white py-4 px-6`,
}
const Company = ({ quote, author, link }) => (
    <div css={companystyle.wrapper}>
        <cite style={{ lineHeight: 1.325 }} css={companystyle.cite}>
            {quote}
        </cite>
        {link && (
            <a css={companystyle.a} href={link} target="_blank" rel="noopener noreferrer">
                {author}
                <svg css={tw`h-4 w-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        strokeWidth="2"
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                </svg>
            </a>
        )}
    </div>
)

/**
 * Photo is used to create a vibe;
 * give users an idea of who
 * might be using Tellow.
 */
const photostyle = {
    wrapper: tw`relative flex flex-row items-end justify-between whitespace-pre-wrap w-full h-full rounded-md! overflow-hidden!`,
    copy: tw`z-10 w-full py-4 px-6 flex flex-col justify-center`,
    p: tw`w-full mb-0 text-xs font-medium antialiased text-white`,
    span: tw`text-xs ml-4 self-end text-white opacity-70 antialiased font-light`,
    url: tw`z-10 text-white py-4 px-6`,
    textwrap: tw`z-10 w-full h-full flex items-end justify-between absolute bottom-0`,
    bg: tw`rounded-md absolute w-full h-full inset-0`,
}
const Gradient = styled(GatsbyImage)`
    position: relative;

    p {
        text-shadow: 0px 0px 3px rgba('black', 0.2);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(${rgba('#4f46e5', 0)} 50%, ${rgba('#4f46e5', 0.8)} 100%), radial-gradient(circle at bottom left, ${rgba('#4f46e5', 0.25)} 0, transparent 50%);
    }
`
const Photo = ({ src, person, title, link }) => (
    <div css={photostyle.wrapper}>
        <div css={photostyle.textwrap}>
            <span css={photostyle.copy}>
                <p css={photostyle.p}>
                    {person} <span css={photostyle.span}>{title}</span>
                </p>
                <p css={photostyle.p}>gebruikt ook Tellow.</p>
            </span>
            <a css={photostyle.url} href={link} target="_blank" rel="noopener noreferrer">
                <svg css={tw`h-4 w-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        strokeWidth="2"
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                </svg>
            </a>
        </div>

        <Gradient css={photostyle.bg} objectFit="cover" objectPosition="bottom left" alt="" image={src} />
    </div>
)

/**
 * Row contains reviews
 * in a horizontal fashion.
 *
 * @param reviews are, well... the reviews.
 * @param {number} w is the client width
 * @param {number} offset to set animation in motion.
 * @param {string} direction to animate in.
 */
const rowstyle = {
    wrapper: tw`mx-auto relative overflow-hidden flex w-full py-0`,
    container: tw`p-4 h-auto`,
}
const Row = ({ reviews, w, offset, direction }) => {
    const count = useMedia(['(min-width: 1800px)', '(min-width: 900px)', '(min-width: 700px)', '(min-width: 400px)'], [6, 3, 2, 1], 1)
    const minWidth = w / count
    const widthSwitcher = count >= 6 ? -w / 2 : -w
    const transform = `translate3d(${direction === 'left' ? widthSwitcher + offset : -offset}px, 0, 0)`

    return (
        <div css={rowstyle.wrapper}>
            {reviews.map((entry, idx) => (
                <div key={idx} style={{ transform, minWidth }} css={rowstyle.container}>
                    {entry.source === klantenvertellen && <Card review={entry.reviewContent.rating} author={entry.reviewAuthor} link={entry.link} />}
                    {entry.source === company && <Company quote={entry.quote} author={entry.reviewAuthor} link={entry.link} />}
                    {entry.source === picture && <Photo src={entry.src} person={entry.person} title={entry.title} link={entry.link} />}
                </div>
            ))}
        </div>
    )
}

/**
 * Component
 * itself.
 */
const style = {
    overflow: tw`relative overflow-hidden`,
    bg: tw`bg-white w-auto absolute left-0 right-0 top-0 h-32`,
    wrapper: tw`mx-auto transform -rotate-1 w-full overflow-hidden whitespace-nowrap`,
}
const url = 'https://www.klantenvertellen.nl/v1/review/feed.xml?hash=loumzk72ohzeylb'
export default function WallOfLove() {
    const [offset, setOffset] = useState(null)
    const w = useCurrentWidth()
    const reviews = useRef([])

    const options = { ignoreAttributes: false }
    const parser = new XMLParser(options)

    const data = useStaticQuery(
        graphql`
            query Images {
                paul: file(relativePath: { eq: "home/stills/paul.png" }) {
                    ...fluidImage
                }
                mireille: file(relativePath: { eq: "home/stills/mireille.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    const pic1 = {
        source: picture,
        person: 'Paul',
        title: 'hovenier',
        src: data.paul.childImageSharp.gatsbyImageData,
        link: 'https://youtu.be/61WjhwMJqrU',
    }
    const pic2 = {
        source: picture,
        person: 'Mireille',
        title: 'consultant',
        src: data.mireille.childImageSharp.gatsbyImageData,
        link: 'https://youtu.be/UgHeBV6tuJE',
    }

    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((xml) => parser.parse(xml))
            .then(({ ReviewFeedDto }) => {
                reviews.current = ReviewFeedDto.reviews.reviews
                    // Flatten hideous reviewContent construction, only take the 'opinion', and add a 'source' for later filtering.
                    .map((review) => ({
                        ...review,
                        source: klantenvertellen,
                        link: 'https://www.klantenvertellen.nl/reviews/1039811/tellow',
                        reviewContent: review.reviewContent.reviewContent.find(({ questionGroup }) => questionGroup === 'DEFAULT_OPINION'),
                    }))
                    // Filter out long ratings (usually have a complaint... just saying).
                    .filter(({ reviewContent }) => reviewContent.rating.split(' ').length <= 70)
                    // Filter out shitty ratings (6'jes cultuur... am a millennial after all).
                    .filter((review) => Number(review.rating) >= 6)
                    // Bring it down to 3*3-(concat entries) -> i.e. 9.
                    .slice(0, 6)
                    // Insert some different (non-klantenvertellen) content
                    .concat(pic1, pic2, ...quotes.quotes)
                    // Shuffle the array.
                    .sort(() => Math.random() - 0.5)
                // })
            })
    }, [])

    /**
     * Request Animation Frame (RAF) hook.
     *
     * For each frame out of 30 FPS, add
     * to the offset with 0.25 value.
     *
     * Returned 'ctx' lets you
     * pause the animation frame.
     */
    const { ctx } = useAnimationFrame(() => setOffset((prev) => (prev + 0.25) % window.document.body.clientWidth), [])

    /**
     * Intersection Observer.
     *
     * In order for the scrolling animation
     * to pause/stop when not visible for
     * performance reasons, we need to know
     * if the reviews are in view.
     */
    useEffect(() => {
        if (window.IntersectionObserver) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => (entry.isIntersecting ? ctx.play() : ctx.pause()))
                },
                { rootMargin: '0px 0px 100px 0px' }
            )

            observer.observe(document.getElementById('reviews'))
        }
    }, [])

    /**
     * Duplicate helper.
     *
     * @param {any[]} a array to duplicate.
     * @param {number} t times to multiply.
     */
    const duplicate = (a, t) => a.concat(...Array.from({ length: t }, () => a))

    /**
     * Multiply cards x-amount of
     * times on larger displays.
     */
    const times = useMedia(['(min-width: 1800px)', '(min-width: 900px)'], [2, 1], 1)

    /**
     * getThreeFromEnd.
     *
     * Well... gets three entries
     * from an array, counting from
     * the 'end' value of '.slice()'.
     *
     * @param {number} end to cut from.
     */
    const getFromEnd = (end = 3, length = 3) => reviews.current.slice(end - length, end)

    return (
        <div id="reviews" css={style.overflow}>
            <span css={style.bg} />
            {/* Width and margin to fix whitespace caused by 10 degree rotation. */}
            <div style={{ width: '105%', marginLeft: '-10px' }} css={style.wrapper}>
                <Row reviews={duplicate(getFromEnd(3), times)} direction="left" w={w} offset={offset} />
                <Row reviews={duplicate(getFromEnd(6), times)} direction="right" w={w} offset={offset} />
            </div>
            <div css={tw`mt-14`}>
                <CTA text="Dat klinkt goed! Ik wil dit ook" />
            </div>
        </div>
    )
}

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
