import React from 'react'
import StarRatings from 'react-star-ratings'
import styled from 'styled-components'
import { ColoredBackgroundWithProps, RelativeContainer } from '../../shared/landingPages/layoutComponents'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    margin: 0 auto 5.44rem auto;

    @media (max-width: 768px) {
        margin: 0 auto;
    }
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
    }
`

const Content = styled.div`
    margin: 0 10px;
`

const Body = styled.p`
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5f6368;
    margin-bottom: 0.75rem;
    width: 236px;
`

const Author = styled.p`
    font-style: italic;
    color: #9da7b3;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    margin-bottom: 0;
`

const Trusted = () => {
    const trustedData = [
        {
            review: `Het scheelt je tijd en geld in vergelijking met een boekhouder.`,
            author: `Marleen`,
            stars: 5,
        },
        {
            review: `De beste app, je doet alles makkelijk op computer en telefoon.`,
            author: `Hans`,
            stars: 5,
        },
        {
            review: `Om 20:50 bellen en meteen geholpen, fantastische service.`,
            author: `Trevor`,
            stars: 5,
        },
    ]

    return (
        <ColoredBackgroundWithProps>
            <RelativeContainer>
                <Container>
                    <MainContainer>
                        {trustedData.map((el) => (
                            <Content key={el.name}>
                                <StarRatings starDimension="20px" starRatedColor="#FFC761" rating={5} starSpacing="1.5px" />
                                <Body>{el.review}</Body>
                                <Author>{el.author}</Author>
                            </Content>
                        ))}
                    </MainContainer>
                </Container>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    )
}

export default Trusted
