import React from 'react'

const Quote = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.2002 7.19998C7.2002 6.2452 7.57948 5.32952 8.25461 4.65439C8.92974 3.97926 9.84542 3.59998 10.8002 3.59998H19.055C20.0097 3.60018 20.9252 3.9796 21.6002 4.65478L27.7454 10.8C28.4206 11.4749 28.8 12.3905 28.8002 13.3452V28.8C28.8002 29.7548 28.4209 30.6704 27.7458 31.3456C27.0706 32.0207 26.155 32.4 25.2002 32.4H10.8002C9.84542 32.4 8.92974 32.0207 8.25461 31.3456C7.57948 30.6704 7.2002 29.7548 7.2002 28.8V7.19998ZM10.8002 18C10.8002 17.5226 10.9898 17.0647 11.3274 16.7272C11.665 16.3896 12.1228 16.2 12.6002 16.2H23.4002C23.8776 16.2 24.3354 16.3896 24.673 16.7272C25.0106 17.0647 25.2002 17.5226 25.2002 18C25.2002 18.4774 25.0106 18.9352 24.673 19.2728C24.3354 19.6103 23.8776 19.8 23.4002 19.8H12.6002C12.1228 19.8 11.665 19.6103 11.3274 19.2728C10.9898 18.9352 10.8002 18.4774 10.8002 18ZM12.6002 23.4C12.1228 23.4 11.665 23.5896 11.3274 23.9272C10.9898 24.2647 10.8002 24.7226 10.8002 25.2C10.8002 25.6774 10.9898 26.1352 11.3274 26.4728C11.665 26.8103 12.1228 27 12.6002 27H23.4002C23.8776 27 24.3354 26.8103 24.673 26.4728C25.0106 26.1352 25.2002 25.6774 25.2002 25.2C25.2002 24.7226 25.0106 24.2647 24.673 23.9272C24.3354 23.5896 23.8776 23.4 23.4002 23.4H12.6002Z"
            fill="currentColor"
        />
    </svg>
)

export default Quote
