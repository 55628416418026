import React from 'react'
import StarRatings from 'react-star-ratings'
import tw from 'twin.macro'
import { RegisterButton, Wave } from '../../UI'

import { Quote } from '../../typography'
import Slider from '../../slider'
import { Block } from '../../../utils'

import KlantenVertellen from '../../../images/logos/klantenvertellen.png'
import { useTranslate } from '../../../hooks'

const styles = {
    block: tw`text-center pt-16 pb-10`,
    card: tw`pt-8 pt-4`,
    title: tw`text-gray-800 font-black tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    span: tw`text-white`,
    quotes: {
        wrap: tw`pt-2`,
    },
}
const SocialProofBlock = () => {
    const { t } = useTranslate()
    // const { socialProof } = t.components.blocks

    console.log(t)

    return (
        <>
            <Wave green />
            <Block css={styles.block} backgroundColor="tellowGreen" color="tellowGray800">
                <h2 css={styles.title}>
                    {t.components.blocks.socialProof.lookNoFurther}
                    <br />
                    <span css={styles.span}>{t.components.blocks.socialProof.recommendedBy}</span>
                </h2>

                <div css={tw`m-2`}>
                    <StarRatings starDimension="30px" starRatedColor="white" starEmptyColor="rgba(255,255,255,0.3)" rating={4.5} />
                </div>

                <img src={KlantenVertellen} width="100" alt="klanten vertellen logo" />

                <div css={styles.quotes.wrap}>
                    <Slider color="black">
                        <Quote
                            author="Eugenie"
                            city="Barendrecht"
                            text="Zeer gebruiksvriendelijk programma met ontzettend enthousiaste, vriendelijke en toegankelijke medewerkers."
                        />
                        <Quote author="Chris" city="Nieuweroord" text="Super team staat achter Tellow. Voor al je vragen kun je terecht bij het Tellow team." />
                        <Quote author="Kees" website="Asuadvies.nl" text="Die automatisering met Tellow is ideaal, want advies geven is het mooiste deel van het vak." />
                        <Quote
                            author="Hilde"
                            city="Baarn"
                            text="Geweldig team achter Tellow: zeer behulpzaam en vriendelijk. De app zelf vind ik ook heel prettig en overzichtelijk."
                        />
                    </Slider>
                </div>

                <div style={{ marginTop: '2em' }}>
                    <RegisterButton context="social-proof" big purple>
                        {t.generic.signUpForFree}
                    </RegisterButton>
                </div>

                <p
                    style={{
                        fontSize: '.9em',
                        padding: '3em 1em',
                        margin: '0 0 2em',
                    }}
                >
                    {t.generic.noPaymentRequired}
                    <br />
                    {t.generic.doneInABreeze}
                </p>
            </Block>
        </>
    )
}

export default SocialProofBlock
