import { useState, useLayoutEffect } from 'react'

/**
 * Check if getWidth
 * is not being used
 * by any SSR runs.
 */
const getWidth = () => typeof window !== 'undefined' && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)

function useCurrentWidth() {
    const [width, setWidth] = useState(getWidth())

    useLayoutEffect(() => {
        let timeoutId = null
        const resizeListener = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => setWidth(getWidth()), 150)
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    return width
}

export default useCurrentWidth
