import React from 'react'

const Invoice = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.2001 3.60001C15.2453 3.60001 14.3296 3.97929 13.6545 4.65442C12.9794 5.32955 12.6001 6.24523 12.6001 7.20001V21.6C12.6001 22.5548 12.9794 23.4705 13.6545 24.1456C14.3296 24.8207 15.2453 25.2 16.2001 25.2H27.0001C27.9549 25.2 28.8706 24.8207 29.5457 24.1456C30.2208 23.4705 30.6001 22.5548 30.6001 21.6V11.5452C30.5999 10.5905 30.2205 9.67498 29.5453 9.00001L25.2001 4.65481C24.5251 3.97963 23.6096 3.60021 22.6549 3.60001H16.2001Z"
            fill="currentColor"
        />
        <path
            d="M5.3999 14.4C5.3999 13.4452 5.77919 12.5295 6.45432 11.8544C7.12945 11.1793 8.04512 10.8 8.9999 10.8V28.8H23.3999C23.3999 29.7548 23.0206 30.6704 22.3455 31.3456C21.6704 32.0207 20.7547 32.4 19.7999 32.4H8.9999C8.04512 32.4 7.12945 32.0207 6.45432 31.3456C5.77919 30.6704 5.3999 29.7548 5.3999 28.8V14.4Z"
            fill="currentColor"
        />
    </svg>
)

export default Invoice
