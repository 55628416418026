import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import Footer from './footer'
import { NavBar } from '../UI'
import Theme from './theme'
import theme from '../../theme'
import CookieConsent from '../cookieconsent'

/**
 * Prevent giving non-functional components a ref
 */
function isStateless(component) {
    return component && typeof component === 'object' && typeof component?.type === 'function'
}

function isNoReactComponent(component) {
    return component?.$$typeof !== 'Symbol(react.element)'
}

const Layout = ({ children, color, noMenuBar, registrationButtonCampaignId, registrationButtonTier }) => {
    const [fetchBackgroundColor, setFetchBackgroundColor] = React.useState('transparent')
    const isAbleToRunAgain = React.useRef(true)

    if (!children) {
        throw new Error('No children for Layout.')
    }

    /**
     * Alter children and keep track of all
     * elements that have a forwardRef / ref.
     */
    const alteredReactChildren = React.Children.toArray(children)
        .filter(Boolean)
        .map((child) => {
            if (isAbleToRunAgain.current === false || isStateless(child) || isNoReactComponent(child)) {
                return React.cloneElement(child, {
                    ref: (ref) => {
                        if (isAbleToRunAgain.current === false) {
                            return ref
                        }

                        if (ref && 'style' in ref && typeof window !== 'undefined') {
                            const el = getComputedStyle(ref)
                            const background = el?.getPropertyValue('background-color')

                            /**
                             * Assure that the white color has an opacity,
                             * to assure 'filter: invert()' will work.
                             */
                            const filterOutAlphaWhite = background === 'rgba(0, 0, 0, 0)' ? 'white' : background
                            setFetchBackgroundColor(filterOutAlphaWhite)

                            // Assure this only runs once.
                            isAbleToRunAgain.current = false
                        }
                    },
                })
            }

            return child
        })

    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={() => (
                <Theme config={theme}>
                    <DefaultMenuColor color={color || fetchBackgroundColor} />
                    {noMenuBar ? null : (
                        <NavBar
                            parentBackground={fetchBackgroundColor}
                            registrationButtonCampaignId={registrationButtonCampaignId}
                            registrationButtonTier={registrationButtonTier}
                        />
                    )}
                    <main>{alteredReactChildren}</main>
                    <CookieConsent />
                    <Footer />
                </Theme>
            )}
        />
    )
}

const DefaultMenuColor = ({ color }) => (
    <Helmet key="default-menu-color">
        <style type="text/css">{`
            :root {
                --menu-color: ${color || 'transparent'};
            }
        `}</style>
        <meta name="theme-color" content={color || 'white'} />
    </Helmet>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
