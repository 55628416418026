/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout, Meta } from '../components/layout'
import { Hero, PartnersBlock, FeatureBlock, AppsBlock, SupportBlock } from '../components/pages/homepage'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const Home = ({ data }) => {
    const home = data?.prismicHome.data

    return (
        <Layout>
            <Meta
                keywords={['boekhoudprogramma', 'online boekhouden', 'boekhoudprogramma zzp', 'online boekhoudprogramma', 'online boekhouden zzp']}
                title={home.meta_title.text}
                description={home.meta_desc.text}
                path="/"
                jsonLD={reviewStructuredData}
            />
            {home.body.map((slice, idx) => {
                switch (slice.slice_type) {
                    case 'hero':
                        return <Hero className="hero" key={`${slice.id}-${idx}`} content={slice} />
                    case 'featureblock':
                        return <FeatureBlock className="featureblock" key={`${slice.id}-${idx}`} content={slice} />
                    case 'appsblock':
                        return <AppsBlock className="appsblock" key={`${slice.id}-${idx}`} content={slice} />
                    case 'partnersblock':
                        return <PartnersBlock className="partnersblock" key={`${slice.id}-${idx}`} content={slice} />
                    case 'supportblock':
                        return <SupportBlock className="supportblock" key={`${slice.id}-${idx}`} content={slice} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}
export default withPrismicPreview(Home)

export const query = graphql`
    {
        prismicHome {
            _previewable
            data {
                body {
                    ... on PrismicHomeDataBodyHero {
                        id
                        slice_type
                        primary {
                            title_1 {
                                text
                            }
                            title_2 {
                                text
                            }
                            description {
                                text
                            }
                            main_image {
                                url
                                alt
                            }
                            placeholder {
                                text
                            }
                            copy_waiting_time {
                                text
                            }
                            signup_button {
                                text
                            }
                        }
                    }
                    ... on PrismicHomeDataBodyFeatureblock {
                        id
                        slice_type
                        items {
                            functions_name {
                                text
                            }
                            explanation {
                                text
                            }
                            function_image {
                                url
                            }
                            icon_name {
                                text
                            }
                            path {
                                text
                            }
                        }
                        primary {
                            functions {
                                text
                            }
                            sub_function {
                                text
                            }
                        }
                    }
                    ... on PrismicHomeDataBodyAppsblock {
                        id
                        slice_type
                        primary {
                            app_title {
                                text
                            }
                            review_buton {
                                text
                            }
                            announcer {
                                text
                            }
                            app_title_after_freelancer_number {
                                text
                            }
                            primary_button {
                                text
                            }
                        }
                    }
                    ... on PrismicHomeDataBodyPartnersblock {
                        id
                        slice_type
                        items {
                            partners_image {
                                url
                            }
                        }
                        primary {
                            main_title_white {
                                text
                            }
                            main_title_green {
                                text
                            }
                            description {
                                text
                            }
                            gratis_button {
                                text
                            }
                        }
                    }
                    ... on PrismicHomeDataBodySupportblock {
                        id
                        slice_type
                        primary {
                            support_title {
                                text
                            }
                            description {
                                text
                            }
                            call_button {
                                text
                            }
                            button_href {
                                url
                            }
                            change_offer {
                                text
                            }
                            success_announcer {
                                text
                            }
                        }
                    }
                }
                meta_desc {
                    text
                }
                meta_title {
                    text
                }
            }
            uid
        }
    }
`
