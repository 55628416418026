import styled from 'styled-components'
import React from 'react'

const BlogTag = styled.div`
    display: inline-block;
    padding: 4px 0px 3px;
    font-size: 14px;
    border-bottom: ${(props) => `1px solid ${props.theme.color.tellowGreen}`};
    color: ${(props) => props.theme.color.tellowGreen};
`

const Tag = ({ children, ...other }) => <BlogTag {...other}>{children}</BlogTag>

export default Tag
