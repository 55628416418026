import React from 'react'
import styled from 'styled-components'
import BalloonIcon from '../../../images/styleguide/balloon'

const StyledBalloon = styled.div`
    position: relative;
    width: 208px;
    height: 204px;
    padding: 15px 20px 10px 20px;
    margin: 0 15px 15px;

    & > svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%; /* for safari */
        z-index: ${(props) => props.theme.zIndex.balloonBackground};
        color: ${(props) => props.theme.color[props.color]};
        filter: drop-shadow(${(props) => props.theme.shadowFilter});
        transform: rotateY(${(props) => (props.tip === 'left' ? '180deg' : '0deg')});
    }

    .children {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: 100%;
        overflow: hidden;
        z-index: ${(props) => props.theme.zIndex.balloonContent};
        color: ${(props) => props.theme.color[props.textColor]};
        text-align: center;
        font-weight: bold;
    }

    a {
        color: ${(props) => props.theme.color[props.linkColor]};
    }
`

const Balloon = ({ className, children, tip, style, color = 'white', textColor = 'darkBlue', linkColor = 'green' }) => (
    <StyledBalloon {...{ className, tip, color, textColor, linkColor, style }}>
        <BalloonIcon />
        <div className="children">{children}</div>
    </StyledBalloon>
)

export default Balloon
