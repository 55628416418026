import styled from 'styled-components'
import { Heading } from '../../typography'
import { Container, Text as TextBlock } from '../../../utils'

export const BackgroundBlock = styled.div`
    background-color: white;
`

export const Shortcuts = styled.div`
    color: black;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 1rem;

    a {
        border-radius: 15px;
        border: none;
        color: ${({ theme }) => theme.color.tellowBlue};
        display: flex;
        flex: 1 0 20%;
        margin: 0.25rem;
        justify-content: flex-start;
        align-items: left;
        transition-property: all;
    }

    a:hover img {
        transform: translateY(-5px);
        transition-duration: 0.1s;
    }

    a:focus {
        outline: none;
    }

    a img {
        margin: 0;
        margin-right: 1rem;
        align-self: center;
    }

    a p {
        margin: 0;
        font-weight: light;
        font-size: 1.2rem;
    }

    .underline {
        border-bottom: 2px;
        border-bottom-style: solid;
    }

    .orangeline {
        border-color: ${({ theme }) => theme.color.tellowOrange};
    }

    .blueline {
        border-color: ${({ theme }) => theme.color.tellowBlue};
    }

    .greenline {
        border-color: ${({ theme }) => theme.color.tellowGreen};
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`

export const HeroHeading = styled(Heading)`
    color: ${({ theme }) => theme.color.tellowBlue};
    font-weight: bold;
    margin-top: 0;
    padding-top: 4rem;
    margin-bottom: 0.5rem;
`

export const HeroSubHeader = styled.p`
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5em;
    padding-top: 1rem;
    margin-bottom: 1rem;
    width: 50%;

    @media screen and (max-width: 1300px) {
        width: 60%;
    }

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`

export const ContentBlock = styled.section`
    padding: 50px 0;
    color: ${({ theme }) => theme.color.tellowBlue};
    font-size: 16px;
`

export const StyledTextBlock = styled(TextBlock)`
    width: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 2rem;
    box-shadow: ${({ theme }) => theme.smoothShadow};
    padding: 2rem 1rem;
    margin: 0 1rem;

    @media screen and (min-width: 768px) {
        padding: 3rem 3rem 2rem 3rem;
        width: ${(props) => (props.width ? props.width : 'auto')};
        margin: ${(props) => (props.width ? '0 auto' : '0 1rem')};
    }
`

export const SectorCard = styled.li`
    flex: 1 100%;
    justify-content: space-between;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.smoothShadow};
    margin: 1rem;
    padding: 0;

    p {
        font-weight: normal;
        font-size: 20px;
        padding: 0.3rem 0 1rem 0;
        margin: 0 0 0 10%;
        color: ${({ theme }) => theme.color.tellowPurple};
    }

    @media screen and (min-width: 768px) {
        flex: 1 20%;
    }
`

export const SectorHeader = styled.img`
    width: 100%;
    height: auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom: 12px;
`

export const CTAButton = styled.a`
    font-weight: bold;
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.color.tellowPurple};
    padding: 0.6rem 3rem;
    margin: 1rem 0;
    color: white;
    align-self: ${(props) => (props.center ? 'center' : 'none')};
`

export const RelativeContainer = styled(Container)`
    position: relative;
`

const BackgroundShared = `
    position: absolute;
    height: auto;
    top: 150px;
    z-index: -1;
`

export const BackgroundElement = styled.div`
    ${BackgroundShared}

    &.first {
        display: none;
    }

    &.second {
        width: 340px;
        right: 0px;
        transform: rotate(90deg);
    }

    &.third {
        width: 320px;
        left: -30px;
        top: -50px;
    }

    &.dots {
        top: unset;
        bottom: -44px;
        right: 0;
        width: 70%;
        height: 400px;
    }

    @media screen and (min-width: 768px) {
        &.first {
            display: block;
            transform: rotate(100deg);
            width: 320px;
            top: -70px;
            left: -70px;
        }

        &.second {
            width: 680px;
        }

        &.third {
            width: 640px;
        }

    @media screen and (min-width: 1380px) {
        &.dots, .second {
            right: -80px;
        }
    }
`

export const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: disc;
    padding-inline-start: 0.5rem;

    li {
        border-left: 3px solid ${({ theme }) => theme.color.tellowRed};
        padding: 0.4rem 1rem;
        margin: 0.4rem 0;
        list-style: none;
        font-weight: bold;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
    }
`

export const CardRow = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding-inline-start: 0px;
    padding: 0;
    margin-bottom: 2rem;

    a img {
        display: none;
    }

    @media screen and (min-width: 768px) {
        a img {
            display: block;
        }
    }
`

export const Ticker = styled.p`
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-right: 1rem;
    line-height: 1.75rem;
    color: ${({ theme }) => theme.color.tellowGreen};
    padding-bottom: 2rem;

    @media screen and (min-width: 768px) {
        font-size: 2rem;
        line-height: 2.6rem;
    }
`

export const ButtonRow = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    vertical-align: middle;

    p {
        display: none;
        vertical-align: middle;
        font-weight: bold;
        margin: 0;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;

        p {
            display: inline-flex;
        }
    }
`
