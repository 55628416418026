import React from 'react'

const Btw = () => (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.50039 3.59998C8.54561 3.59998 7.62994 3.97926 6.95481 4.65439C6.27968 5.32952 5.90039 6.2452 5.90039 7.19998V32.4L12.2004 28.8L18.5004 32.4L24.8004 28.8L31.1004 32.4V7.19998C31.1004 6.2452 30.7211 5.32952 30.046 4.65439C29.3708 3.97926 28.4552 3.59998 27.5004 3.59998H9.50039ZM14.0004 8.99998C13.2843 8.99998 12.5976 9.28444 12.0912 9.79079C11.5849 10.2971 11.3004 10.9839 11.3004 11.7C11.3004 12.4161 11.5849 13.1028 12.0912 13.6092C12.5976 14.1155 13.2843 14.4 14.0004 14.4C14.7165 14.4 15.4032 14.1155 15.9096 13.6092C16.4159 13.1028 16.7004 12.4161 16.7004 11.7C16.7004 10.9839 16.4159 10.2971 15.9096 9.79079C15.4032 9.28444 14.7165 8.99998 14.0004 8.99998ZM25.173 9.52738C24.8354 9.18993 24.3777 9.00036 23.9004 9.00036C23.4231 9.00036 22.9653 9.18993 22.6278 9.52738L11.8278 20.3274C11.6559 20.4934 11.5187 20.692 11.4244 20.9116C11.3301 21.1313 11.2804 21.3674 11.2783 21.6065C11.2763 21.8455 11.3218 22.0825 11.4123 22.3037C11.5028 22.5249 11.6365 22.7259 11.8055 22.8949C11.9745 23.0639 12.1755 23.1975 12.3967 23.2881C12.6179 23.3786 12.8549 23.4241 13.0939 23.422C13.3329 23.4199 13.5691 23.3703 13.7887 23.276C14.0083 23.1816 14.2069 23.0445 14.373 22.8726L25.173 12.0726C25.5104 11.735 25.7 11.2773 25.7 10.8C25.7 10.3227 25.5104 9.86493 25.173 9.52738ZM23.0004 18C22.2843 18 21.5976 18.2844 21.0912 18.7908C20.5849 19.2971 20.3004 19.9839 20.3004 20.7C20.3004 21.4161 20.5849 22.1028 21.0912 22.6092C21.5976 23.1155 22.2843 23.4 23.0004 23.4C23.7165 23.4 24.4032 23.1155 24.9096 22.6092C25.4159 22.1028 25.7004 21.4161 25.7004 20.7C25.7004 19.9839 25.4159 19.2971 24.9096 18.7908C24.4032 18.2844 23.7165 18 23.0004 18Z"
            fill="currentColor"
        />
    </svg>
)

export default Btw
