import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from '../../UI'
import Segment from '../../external-services/segment'

const DownloadComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;

    margin-top: 2rem;

    p {
        color: ${({ theme }) => theme.color.tellowPurple};
        font-size: 1.25rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .download {
        display: flex;
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

const url = 'https://tellow.nl/cdn/uitstel-covid19.pdf'

const handleDownload = () => {
    window.open(url, '_blank')

    // Segment
    if (window.analytics.initialized) {
        Segment.track('Click - Download', {
            location: window.location.pathname,
            content: 'covid19',
        })
    }
}

export default class Download extends Component {
    render() {
        return (
            <DownloadComponent>
                <p>Download hier het uitstel-sjabloon; handig digitaal invulbaar.</p>
                <div className="download">
                    <Button
                        style={{ width: '100%', textAlign: 'center' }}
                        big
                        red
                        subtleShadow
                        type="submit"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleDownload()}
                    >
                        Sjabloon downloaden
                    </Button>
                </div>
            </DownloadComponent>
        )
    }
}
