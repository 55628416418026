/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import tw, { styled } from 'twin.macro'
import { Portal } from 'react-portal'
import { motion, AnimatePresence } from 'framer-motion'
import content from '../content.json'
import { useDeviceDetect, useKeyPress, useOnClickOutside, useTranslate } from '../../../../hooks'
import Item from './item'
import theme from '../../../../theme'
import config from '../../../../config'
import StreamlineIcon from '../../../../images/icons/streamline'
import AppStore from './logos/app-store-badge.svg'
import GooglePlay from './logos/google-play-badge.png'
import RegisterButton from '../../Buttons/registerButton'
import DropdownIcon from '../../MobileDropDown/MobileDropDown'
import Logo from '../../../../images/logos/tellow/logo'

/**
 * All Tailwind styles
 * in one style object.
 * Keep below DOM clean.
 */
const style = {
    svg: tw`ml-2 mr-4 mb-0`,
    open: tw`flex-1 p-4 appearance-none bg-transparent cursor-pointer transition-all hover:opacity-75 border-none outline-none focus:border-none focus:outline-none ring-0 focus:ring-0`,
    container: tw`fixed inset-0 z-40`,
    top: {
        wrapper: tw`pl-6 text-gray-100 flex justify-between items-center tracking-wide antialiased`,
        close: tw`h-8 w-8 mr-4 stroke-current cursor-pointer text-tellow-black`,
        logo: tw`h-8 w-8 stroke-current`,
        tellow: tw`m-0 px-2 py-4 text-2xl text-tellow-black font-bold`,
    },
    captive: {
        cloak: tw`absolute z-30 inset-0 bg-black bg-opacity-50`,
        unbutton: tw`appearance-none border-none outline-none focus:border-none focus:outline-none ring-0 focus:ring-0 rounded-md transition-all`,
        wrapper: tw`p-2 m-2 sm:max-w-md mx-2 sm:mx-auto rounded-md shadow-xl bg-white grid grid-rows-2 gap-y-2 fixed bottom-0 inset-x-0 z-50`,
        item: tw`m-0 px-4 flex items-center bg-tellow-purple hover:bg-tellow-purple transition-all text-white rounded text-sm font-semibold antialiased cursor-pointer outline-none! focus:outline-none! ring-0! focus:ring-0!`,
        close: tw`ml-2 mr-4 h-3 w-3 stroke-current text-gray-500`,
    },
    main: tw`grid py-6`,
    registerButton: tw`flex items-center mx-6 text-tellow-white justify-center`,
    apps: {
        wrapper: tw`flex flex-row gap-y-4 justify-center items-center text-gray-100 mt-1 mb-6`,
        badge: tw`max-h-9 max-w-32 w-full h-full block mb-0 mx-2`,
    },
    hr: tw`bg-gray-100`,
    platform: tw`px-8 grid grid-cols-2 gap-x-8 my-10 xs:grid-cols-1`,
    register: tw`px-2 pb-2`,
    button: tw`w-full block p-4 mt-10 rounded-2xl text-center text-white antialiased text-sm font-semibold bg-tellow-purple`,
    item: tw`my-1 text-sm font-semibold antialiased cursor-pointer`,
    textBlack: tw`text-tellow-black`,
    textWhite: tw`text-tellow-white`,
    dropdown: {
        question: tw`outline-none pl-6 text-tellow-black hover:font-semibold border-t border-l-0 border-r-0 border-b-0 border-solid border-gray-600 border-opacity-20 py-4 antialiased cursor-pointer bg-tellow-gray-100`,
        answer: tw`pt-6 ml-2`,
        dropDownIcon: tw`float-right mr-4`,
        grid: tw`grid grid-flow-row auto-rows-max`,
        text: tw`text-sm font-normal mb-0 opacity-90 ml-16 mr-6 -mt-6 mb-4 ml-6`,
        content: tw`flex flex-row items-start`,
        littleTitle: tw`text-base font-medium -mt-2 ml-6`,
        svg: tw`h-8 w-8 fill-none! text-tellow-purple ml-4 mr-4 rounded-full bg-tellow-purple bg-opacity-10 justify-center items-center p-1`,
    },
}

const dropdownData = [
    {
        i18nKey: 'invoices',
        path: `facturen`,
    },
    {
        i18nKey: 'quotes',
        path: `offertes`,
    },
    {
        i18nKey: 'banking',
        path: `koppel-je-rekening`,
    },
    {
        i18nKey: 'scanning',
        path: `bonnen`,
    },
    {
        i18nKey: 'reporting',
        path: `rapportages`,
    },
    {
        i18nKey: 'taxes',
        path: `btw-aangifte`,
    },
    {
        i18nKey: 'bookkeeping',
        path: `boekhouding`,
    },
    {
        i18nKey: 'hourRegistration',
        path: `urenregistratie`,
    },
    {
        i18nKey: 'incomeTax',
        path: `inkomstenbelasting`,
    },
    {
        i18nKey: 'support',
        featurePath: `support`,
    },
]

const data = [
    {
        name: 'Apple',
        link: 'https://app.adjust.com/41l68g0?campaign=Homepage&adgroup=iOS&creative=app_block&fallback=https%3A%2F%2Fapps.apple.com%2Fnl%2Fapp%2Ftellow%2Fid1187872148&redirect_android=https%3A%2F%2Fapps.apple.com%2Fnl%2Fapp%2Ftellow%2Fid1187872148&redirect_ios=https%3A%2F%2Fapps.apple.com%2Fnl%2Fapp%2Ftellow%2Fid1187872148',
        logo: AppStore,
    },
    {
        name: 'Android',
        link: 'https://app.adjust.com/b8zrw9z?campaign=Homepage&adgroup=Android&creative=app_block&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnl.tellow.app%26hl%3Dnl&redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnl.tellow.app%26hl%3Dnl&redirect_ios=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnl.tellow.app%26hl%3Dnl',
        logo: GooglePlay,
    },
]

const Accordeon = ({ id, initialOpen, onToggle, question, children }) => {
    const [height, setHeight] = useState(0)
    const loadedOnce = useRef(false)

    const toggle = () => {
        if (onToggle) onToggle()
        setHeight(height === 0 ? 'auto' : 0)
        loadedOnce.current = true
    }

    useEffect(() => {
        if (initialOpen) {
            toggle()
        }
    }, [])

    return (
        <>
            <a
                id={id}
                tabIndex={0}
                role="button"
                active={height !== 0}
                onClick={() => toggle()}
                onKeyPress={() => toggle()}
                css={style.dropdown.question}
                initialOpen={initialOpen}
            >
                <DropdownIcon active={height !== 0} style={style.dropdown.dropDownIcon} />
                {question}
            </a>
            <AnimateHeight duration={300} height={height} delay={100}>
                <div css={style.dropdown.answer} active={height !== 0}>
                    {height !== 0 || loadedOnce.current ? children : null}
                </div>
            </AnimateHeight>
        </>
    )
}

export const LogoLink = styled.a`
    & > svg {
        height: 1.25rem;
    }
`

const MenuButton = styled.button`
    flex: 1 1 0%;
    padding: 1rem;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border: none;
    outline: none;

    color: var(--menu-color, transparent);
    filter: blur(0px) invert(1) brightness(1.5) saturate(1) grayscale();

    &:focus {
        border: none;
        outline: none;
    }

    &:hover {
        opacity: 0.75;
    }
`
const TellowLogo = styled(Logo)`
    color: ${({ theme }) => theme.color.tellowPurple};
`

const DropdownContent = styled.div`
    border-bottom: ${(props) => (props.noBorder ? 'none' : '1px solid #eeeefd')};
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
`

const OverflowableWrapper = styled.div`
    position: relative;

    background: ${({ theme }) => theme.color.tellowWhite};
    box-shadow: ${({ theme }) => theme.smoothShadow};
    border-radius: 1rem;

    overflow-y: scroll;
    overflow-x: hidden;

    margin: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
`

function Menu({ registrationButtonCampaignId, registrationButtonTier }) {
    const { t, i18n } = useTranslate('components')

    /**
     * Modal state.
     */
    const [open, setOpen] = useState(false)
    const [captive, setCaptive] = useState(false)

    const picker = useRef()
    useOnClickOutside(picker, () => {
        setCaptive(false)
        setTimeOut(false)
    })

    /**
     * On pressing escape,
     * close the menu.
     */
    const esc = useKeyPress('Escape')
    useEffect(() => {
        openCloseHandler(false)
    }, [esc])

    /**
     * Detect mobile devices to
     * ask for Tellow App login.
     */
    const { isMobile, isApple } = useDeviceDetect()
    const mobileLoginHandler = () => {
        if (!isMobile) {
            window.open(config.links.app, '_blank')
            return
        }

        openCloseHandler(false)
        setCaptive(true)
    }

    const openCloseHandler = (menuOpened) => {
        if (menuOpened) {
            const { scrollY } = window
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollY}px`
        } else {
            const scrollY = document.body.style.top
            document.body.style.position = null
            document.body.style.top = null
            window.scrollTo(0, parseFloat(scrollY || '0') * -1)
        }

        setOpen(menuOpened)
    }

    /**
     * Reset page on destroy
     * (i.e. hook return method).
     */
    useLayoutEffect(() => () => {
        document.body.style.top = 0
    })

    /**
     * Open mobile app and
     * start a timer.
     */
    const [timeOut, setTimeOut] = useState(false)
    const openAppWithTimer = () => {
        if (timeOut) {
            const store = isApple ? config.links.stores.apple : config.links.stores.android
            return window.open(store, '_blank')
        }

        window.location.replace(config.links.deeplinkUri)

        setTimeout(() => {
            setTimeOut(true)
        }, 3000)
    }

    /**
     * Mount animation
     * for Framer Motion.
     */
    const animation = {
        modal: {
            open: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.15,
                    ease: 'easeOut',
                },
            },
            close: (custom) => ({
                opacity: 0,
                y: custom.position,
                transition: {
                    duration: 0.15,
                    ease: 'easeIn',
                },
            }),
        },
        cloak: {
            open: { opacity: 1 },
            close: { opacity: 0 },
        },
    }

    return (
        <>
            {/* Menu trigger. */}
            <MenuButton type="button" tabIndex={0} onClick={() => openCloseHandler(!open)} aria-hidden="true" aria-haspopup="true" aria-expanded={open ? 'true' : 'false'}>
                Menu
            </MenuButton>

            <AnimatePresence>
                {/* Login-captive. */}
                {captive && (
                    <Portal key="P1">
                        <motion.div ref={picker} css={style.captive.wrapper} custom={{ position: 5 }} initial="close" animate="open" exit="close" variants={animation.modal}>
                            <a css={[style.captive.item]} href={config.links.app} style={{ height: `${2 * 31 - 4}px` }}>
                                <StreamlineIcon css={[style.svg, style.captive.close]} color="#FFF" icon="MenuArrow" size={12} />
                                Log in via huidig scherm
                            </a>
                            <button
                                type="button"
                                css={[style.captive.unbutton, style.captive.item, timeOut && tw`bg-tellow-blue!`]}
                                onClick={() => openAppWithTimer()}
                                style={{ height: `${2 * 31 - 4}px` }}
                            >
                                <StreamlineIcon css={[style.svg, style.captive.close]} color="#FFF" icon="MenuShield" size={12} />
                                {!timeOut ? 'Open de Tellow app' : 'Installeer de Tellow app'}
                            </button>
                            <button
                                type="button"
                                css={[style.captive.unbutton, style.captive.item, tw`bg-gray-50! text-gray-800 hover:bg-gray-100!`]}
                                onClick={() => setCaptive(false)}
                                style={{ height: `${2 * 31 - 4}px` }}
                            >
                                <StreamlineIcon css={[style.svg, style.captive.close]} color={theme.color.tellowGray500} icon="MenuClose" size={10} />
                                Sluit dit scherm
                            </button>
                        </motion.div>
                    </Portal>
                )}
                {/* The 'thing that makes background goes black' */}
                <AnimatePresence>
                    {captive && (
                        <Portal key="P2">
                            <motion.div initial="close" animate="open" exit="close" variants={animation.cloak} css={style.captive.cloak} />
                        </Portal>
                    )}
                </AnimatePresence>

                {/* The Menu */}
                {open && (
                    <Portal key="P3">
                        <motion.div custom={{ position: -5 }} initial="close" animate="open" exit="close" variants={animation.modal} css={style.container}>
                            <OverflowableWrapper>
                                {/* Logo Header */}
                                <div css={style.top.wrapper}>
                                    <a css={style.top.tellow} href="/">
                                        <LogoLink>
                                            <TellowLogo />
                                        </LogoLink>
                                    </a>
                                    <svg
                                        fill="none"
                                        role="button"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        css={style.top.close}
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => openCloseHandler(!open)}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>

                                <RegisterButton css={style.registerButton} purple context="navigation" campaign={registrationButtonCampaignId} tier={registrationButtonTier}>
                                    {t.menu.signup} &nbsp;
                                    <span className="arrow" tw="text-tellow-white">
                                        {'->'}
                                    </span>
                                </RegisterButton>

                                {/* Main */}
                                <div css={style.main} initial="fadeIn" animate="fadeIn" exit="fadeOut" variants={animation}>
                                    <Accordeon css={style.dropdown.question} question={t.menu.features}>
                                        <div css={style.dropdown.pointsGrid}>
                                            {dropdownData.map(({ i18nKey, path, featurePath }, index) => (
                                                <a key={t.menu.submenus[i18nKey].title} href={path ? `/functies/${path}` : `/${featurePath}`}>
                                                    <DropdownContent key={t.menu.submenus[i18nKey].title} noBorder={index === dropdownData.length - 1}>
                                                        <div css={style.dropdown.content}>
                                                            <p css={style.dropdown.littleTitle}>{t.menu.submenus[i18nKey].title}</p>
                                                        </div>

                                                        <p css={style.dropdown.text}>{t.menu.submenus[i18nKey].body}</p>
                                                    </DropdownContent>
                                                </a>
                                            ))}
                                        </div>
                                    </Accordeon>

                                    {content.product.main.map((item) => (
                                        <Item key={item.name} i18n={i18n} content={{ ...item, name: t.menu[item.i18nKey] }} />
                                    ))}

                                    {/*
                                        Login.
                                        This is separate (i.e. not mapped)
                                        due to the click handlers.
                                    */}
                                    <Item
                                        tw="text-tellow-purple"
                                        key={3}
                                        content={{
                                            name: t.menu.login,
                                            onClick: () => mobileLoginHandler(),
                                        }}
                                    />
                                </div>

                                <div css={style.apps.wrapper}>
                                    {data.map(({ logo, name, link }) => (
                                        <a key={name} href={link} target="_blank" rel="noopener noreferrer">
                                            <img css={style.apps.badge} src={logo} alt={name} />
                                        </a>
                                    ))}
                                </div>
                            </OverflowableWrapper>
                        </motion.div>
                    </Portal>
                )}
            </AnimatePresence>
        </>
    )
}

export default Menu
