/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'
import styled from 'styled-components'
// import { banks as banksFAQ } from '../../../faqs'
import { RegisterButton } from '../../UI'
import { Block } from '../../../utils'

// const faqStyles = {
//     container: tw`py-20 relative max-w-xl mx-auto px-2 sm:px-6 lg:px-8 lg:max-w-7xl`,
//     wrapper: tw`lg:grid lg:grid-cols-3 lg:gap-8`,
//     title: tw`antialiased mb-0 text-2xl font-extrabold text-gray-800`,
//     subtitle: tw`antialiased text-lg text-tellow-purple`,
//     item: {
//         container: tw`mt-12 lg:mt-0 lg:col-span-2`,
//         spacing: tw`space-y-12`,
//         question: tw`antialiased text-base leading-6 font-medium text-gray-800`,
//         answer: tw`antialiased mt-2 text-sm leading-relaxed text-gray-400`,
//     },
// }

// const FAQ = ({ questions }) => (
//     <div css={faqStyles.container}>
//         <div css={faqStyles.wrapper}>
//             <div>
//                 <h2 css={faqStyles.title}>Veel gestelde vragen</h2>
//                 <p css={faqStyles.subtitle}>Alle informatie op een rijtje.</p>
//             </div>
//             <div css={faqStyles.item.container}>
//                 <dl css={faqStyles.item.spacing}>
//                     {questions.map((question, index) => (
//                         <div key={index}>
//                             <dt css={faqStyles.item.question}>{question.question}</dt>
//                             <dd css={faqStyles.item.answer}>{question.answer}</dd>
//                         </div>
//                     ))}
//                 </dl>
//             </div>
//         </div>
//     </div>
// )

/**
 * Ratio is based on 85cm to 55cm;
 * which is the real life card ratio. ;-)
 */
const ratio = 0.655
const size = 200
const exception = {
    0: { gridColumnStart: 2 },
    1: { gridColumnStart: 4, transformOrigin: '33% 50%', transform: `rotate(-90deg)` },
    2: { gridColumnStart: 1 },
    3: { gridColumnStart: 3 },
    4: { gridColumnStart: 2 },
    5: { gridColumnStart: 4 },
    6: { gridColumnStart: 2 },
    7: { gridColumnStart: 4, transformOrigin: '33% 50%', transform: `rotate(90deg)` },
    8: { gridColumnStart: 2 },
}

const CardManager = ({ children, tilt = 2 }) => {
    /**
     * Calculation:
     * 1. Get the height of the card (by using the ratio).
     * 2. Substract the grid-grap (1rem).
     * 3. Add the remainder of the width.
     */
    const remToPx = (i) => i * 16
    const card = Math.floor(size * ratio)
    const remainder = size - card - remToPx(1)
    const complicatedGrid = `repeat(2, ${remainder}px ${card}px)`

    return (
        <div style={{ transform: `rotate(${tilt}deg)`, marginRight: '-1rem', gridTemplateColumns: complicatedGrid }} css={[tw`hidden lg:grid gap-4 z-10`]}>
            {children}
        </div>
    )
}

const chipstyle = {
    wrapper: tw`grid grid-cols-3 w-7 rounded-sm`,
    partial: tw`rounded bg-white col-span-1`,
    full: tw`rounded bg-white col-span-3`,
}
const Chip = ({ height = 3 }) => (
    <div style={{ columnGap: 1, rowGap: 1, padding: 2 }} css={chipstyle.wrapper}>
        <span style={{ height }} css={chipstyle.full} />
        {Array.from(new Array(6)).map((_, index) => (
            <span key={index} style={{ height }} css={chipstyle.partial} />
        ))}
        <span style={{ height }} css={chipstyle.full} />
    </div>
)

const cardstyle = {
    wrapper: tw`rounded-lg flex flex-row p-4`,
    left: tw`flex flex-col items-start justify-evenly flex-1`,
    text: tw`antialiased tracking-tight justify-start font-semibold text-xs m-0`,
}
const Card = ({ theme, bank, positioning, children }) => (
    <div style={{ ...positioning, background: theme.color, height: size * ratio, width: size }} css={cardstyle.wrapper}>
        <div css={cardstyle.left}>
            <Chip />
            <p style={{ color: theme.text }} css={cardstyle.text}>
                {bank}
            </p>
        </div>
        <div>{children}</div>
    </div>
)

const ctastyle = {
    wrap: tw`flex flex-col items-center`,
    button: tw`mt-2 mb-8`,
    strong: tw`font-bold text-gray-700 mb-4`,
    body: tw`antialiased mb-8 font-medium text-gray-500 text-sm text-center`,
}
const CTA = ({ text = 'Open een Tellow account' }) => (
    <div css={ctastyle.wrap}>
        <RegisterButton css={ctastyle.button} context="banks" tertiary big>
            {text} &nbsp; →
        </RegisterButton>

        <p css={ctastyle.body}>
            <strong css={ctastyle.strong}>Geen betalingsgegevens vereist.</strong>
            <br />
            Maak al in één minuut een account aan!
        </p>
    </div>
)

const Fade = styled.div`
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);

        top: 0;
        right: 0;
        bottom: 0;

        z-index: 20;
        width: 30vw;
        height: 100%;
        position: absolute;
    }
`

const BanksBlock = () => {
    const data = useStaticQuery(
        graphql`
            query {
                rabobank: file(relativePath: { eq: "logos/banks/highres/Rabo.png" }) {
                    ...fluidImage
                }
                abn: file(relativePath: { eq: "logos/banks/highres/ABN.png" }) {
                    ...fluidImage
                }
                sns: file(relativePath: { eq: "logos/banks/highres/SNS.png" }) {
                    ...fluidImage
                }
                regiobank: file(relativePath: { eq: "logos/banks/highres/Regiobank.png" }) {
                    ...fluidImage
                }
                asn: file(relativePath: { eq: "logos/banks/highres/ASN.png" }) {
                    ...fluidImage
                }
                knab: file(relativePath: { eq: "logos/banks/highres/Knab.png" }) {
                    ...fluidImage
                }
                triodos: file(relativePath: { eq: "logos/banks/highres/Triodos.png" }) {
                    ...fluidImage
                }
                ing: file(relativePath: { eq: "logos/banks/highres/ING.png" }) {
                    ...fluidImage
                }
                bunq: file(relativePath: { eq: "logos/banks/highres/Bunq.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    const styles = {
        layout: {
            block: tw``,
            grid: tw`grid grid-cols-1 grid-rows-1 lg:grid-rows-1 lg:grid-cols-2`,
            contextual: tw`max-w-7xl py-15 mx-auto px-4 sm:px-6 lg:px-8`,
        },
        intro: {
            wrap: tw`w-full flex flex-col justify-start`,
            title: tw`text-gray-800 font-black tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
            list: tw`grid grid-cols-2 sm:grid-cols-3 gap-2`,
            item: tw`antialiased my-1 font-bold tracking-tight text-gray-800 text-base flex flex-col justify-center items-center sm:items-start`,
            text: tw`antialiased prose text-sm mb-6`,
            notification: tw`bg-indigo-100 px-6 py-4 antialiased font-medium text-xs! rounded-lg text-tellow-purple! leading-relaxed`,
            bank: tw`inline-block order-first lg:hidden h-10 w-10 sm:-ml-2 sm:h-16 sm:w-16 pb-4`,
            span: tw`text-tellow-purple!`,
        },
        banks: tw`order-first lg:order-last flex flex-row flex-wrap items-center justify-center w-full h-full `,
    }

    const banks = [
        { bank: 'Rabobank', image: data.rabobank.childImageSharp.gatsbyImageData, color: '#F17823', text: 'white' },
        { bank: 'ING', image: data.ing.childImageSharp.gatsbyImageData, color: '#ffbd94', text: 'white' },
        // { bank: 'Knab', image: data.knab.childImageSharp.gatsbyImageData, color: '#587273', text: 'white' },
        { bank: 'bunq', image: data.bunq.childImageSharp.gatsbyImageData, color: '#000000', text: 'white' },
        { bank: 'ABN Amro', image: data.abn.childImageSharp.gatsbyImageData, color: '#efe3c3', text: '#009d83' },
        { bank: 'Triodos', image: data.triodos.childImageSharp.gatsbyImageData, color: '#4BF0BA', text: '#009777' },
        { bank: 'ASN', image: data.asn.childImageSharp.gatsbyImageData, color: '#904035', text: 'white' },
        { bank: 'SNS', image: data.sns.childImageSharp.gatsbyImageData, color: '#5FCCF7', text: '#273896' },
        { bank: 'RegioBank', image: data.regiobank.childImageSharp.gatsbyImageData, color: '#f1f7f9', text: '#db0e0a' },
        { bank: 'De Volksbank', image: data.regiobank.childImageSharp.gatsbyImageData, color: '#f1f7f9', text: '#db0e0a' },
        { bank: 'Handelsbanken', image: data.regiobank.childImageSharp.gatsbyImageData, color: '#f1f7f9', text: '#db0e0a' },
    ]

    return (
        <Block css={styles.layout.block}>
            <div css={[styles.layout.grid, styles.layout.contextual]}>
                <div css={styles.intro.wrap}>
                    <h2 css={styles.intro.title}>
                        Koppel je rekening! <br /> <span css={styles.intro.span}>Fluitje van een cent.</span>
                    </h2>
                    <p css={styles.intro.text}>
                        Integreer Tellow met jouw bankrekening en je transacties zijn altijd up-to-date. Koppel ze met één klik aan een bonnetje. Geen boekhouder meer nodig!
                    </p>

                    <ol css={styles.intro.list}>
                        {banks.map(({ image, bank }) => (
                            <li key={bank} css={styles.intro.item}>
                                <GatsbyImage image={image} alt={`${bank} logo`} css={styles.intro.bank} />
                                {bank}
                            </li>
                        ))}
                    </ol>

                    <p css={[styles.intro.notification, styles.intro.text]}>
                        Tellow werkt samen met bijna alle Nederlandse banken. Hiervoor gebruiken wij de nieuwe en beveiligde PSD2 connecties. Zo blijft je data gewoon in Tellow
                        achter slot en grendel.
                    </p>
                </div>

                <Fade css={styles.banks}>
                    <CardManager>
                        {banks.slice(0, 8).map(({ bank, fluid, color, text }, idx) => (
                            <Card key={bank} bank={bank} theme={{ color, text }} positioning={{ ...exception[idx] }}>
                                <GatsbyImage image={fluid} alt={`${bank} logo`} css={tw`h-12 w-12 p-4`} />
                            </Card>
                        ))}
                    </CardManager>
                </Fade>
            </div>
            <div css={styles.layout.contextual}>
                <CTA text="Ga direct aan de slag" />
                {/* <FAQ questions={banksFAQ} />
                <CTA /> */}
            </div>
        </Block>
    )
}

export default BanksBlock

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
