import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import BannerWithCaption from '../components/pages/store/bannerWithCaption'
import Products from '../components/pages/store/products'
import CallToAction from '../components/pages/store/callToAction'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const Store = ({ data }) => {
    const store = data.prismicStore.data

    return (
        <Layout>
            <Meta title={store.meta_title.text || store.title.text} description={store.meta_description.text} path="/store" jsonLD={reviewStructuredData} />
            {store.body.map((slice) => {
                switch (slice.slice_type) {
                    case 'banner_with_caption':
                        return <BannerWithCaption className="banner-with-caption" key={slice} content={slice} />
                    case 'products':
                        return <Products className="products" key={slice} content={slice} />
                    case 'cta':
                        return <CallToAction className="cta" key={slice} content={slice} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}

export default withPrismicPreview(Store)

export const query = graphql`
    {
        prismicStore {
            _previewable
            data {
                body {
                    ... on PrismicStoreDataBodyCta {
                        slice_type
                        primary {
                            background_color
                            button_colors
                            button_text {
                                text
                            }
                            text_color
                            section_title {
                                text
                            }
                            url {
                                link_type
                                url
                            }
                        }
                    }
                    ... on PrismicStoreDataBodyProducts {
                        id
                        slice_type
                        items {
                            category
                            description {
                                text
                            }
                            link_to_product {
                                target
                                url
                            }
                            product_image {
                                alt
                                url
                            }
                            title {
                                text
                            }
                        }
                        primary {
                            background_color
                            text_color
                            description {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicStoreDataBodyBannerWithCaption {
                        slice_type
                        primary {
                            background_color
                            description {
                                text
                            }
                            image_banner {
                                alt
                                url
                            }
                            subtitle_color
                            text_color
                            title_color
                            subtitle {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                }
                header_image {
                    alt
                    url
                }
                meta_title {
                    text
                }
                meta_description {
                    text
                }
            }
            uid
        }
    }
`
