import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    svg {
        fill: currentColor;
    }

    ol,
    ul {
        list-style-type: none;
        margin-left: 0;
    }

    .underline-links a {
        text-decoration: underline;
    }
    .green {
        color: ${(props) => props.theme.color.tellowGreen};
    }
    @media print {
        .no-print {
            display: none;
        }
    }
    @media screen and (max-width: 767px) {
        .sm-hidden {
            display: none;
        }
        .sm-center {
            text-align: center;
        }
    }
    @media screen and (min-width: 768px) {
        .md-hide {
            display: none;
        }
    }

    /* Safari desktop/iOS font rendering */
    h1, h2, h3, h4, h5, h6 {
        background: none;

        @supports (-webkit-appearance: none) or (-webkit-overflow-scrolling: touch) {
            /* -webkit-text-stroke: 1.5px currentColor; */
            -webkit-font-smoothing: subpixel-antialiased;
            -webkit-font-feature-settings: "kern" 1;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            font-synthesis: none;
        }
    }
`

const Theme = ({ children, config }) => (
    <ThemeProvider theme={config}>
        <>
            <GlobalStyle />
            {children}
        </>
    </ThemeProvider>
)

export default Theme
