function linkResolver(doc) {
    const [twoDigitI18nTag] = doc.lang.split('-')
    const i18nPrefixer = doc.lang !== 'nl-nl' ? `/${twoDigitI18nTag}` : ''

    switch (doc.type) {
        case 'blog':
            return `${i18nPrefixer}/blog/${doc.uid}`
        case 'landingspage-not-indexed':
            return `${i18nPrefixer}/lp/${doc.uid}`
        case 'homepage':
            return `/home/${doc.uid}`
        case 'partner':
            return `/partners/${doc.uid}`
        case 'job':
            return `/jobs/${doc.uid}`
        default:
            return `/`
    }
}

module.exports = linkResolver
