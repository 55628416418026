import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Button } from '../../UI'
import { BlockSection, Content } from '../../../utils/prismic/sliceStyles'

const ContentRow = styled(Content)`
    @media screen and (min-width: 768px) {
        max-width: 40rem;

        h2 {
            letter-spacing: -0.0125em;
        }

        &.flex-row {
            width: 100%;
            flex-direction: row;
            justify-content: center;

            h2 {
                margin: 0 50px 0 0;
            }
        }
    }
    @media screen and (min-width: 1100px) {
        max-width: 60rem;
    }
`

const BannerText = styled.h2`
    font-size: 30px !important;
    line-height: 45px;
    margin-bottom: 2rem !important;

    @media screen and (max-width: 480px) {
        font-size: 1.7rem !important;
        margin-bottom: 1.5rem !important;
    }
`

export default function callToAction({ content, row, className }) {
    const correctColorScheme = (buttonColors) => {
        switch (buttonColors) {
            case 'Blue button white text':
                return { tertiary: 'tertiary' }
            case 'White button dark text':
                return { primary: 'primary' }
            case 'White button purple text':
                return { white: 'white' }
            default:
                return null
        }
    }

    const slice = content.primary

    return (
        <BlockSection className={`${slice.background_color} ${className}`} style={{ padding: '2rem 1rem', marginTop: '80px' }}>
            <ContentRow className={row ? 'flex-row' : 'flex-col'}>
                <BannerText className={slice.text_color}>{slice.section_title.text}</BannerText>

                <Button css={tw`mt-2 text-base sm:text-sm xs:text-xs`} big subtleShadow href={slice.url.url} {...correctColorScheme(slice.button_colors)}>
                    {slice.button_text.text} &nbsp; →
                </Button>
            </ContentRow>
        </BlockSection>
    )
}
