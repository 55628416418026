import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { FAQ, Freeform, Header, TypeForm } from '../../components/pages/partner'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'

const Slices = styled.div`
    display: flex;
    flex-direction: column;
`

const Partner = ({ data }) => {
    const { data: dd } = data.prismicPartner
    const title = dd && dd.metatitle && dd.metatitle && dd.metatitle.length !== 0 ? dd.metatitle.text : ''
    const metadescription = (dd && dd.metadescription && dd.metadescription.length !== 0 && dd.metadescription.text) || ''

    return (
        <Layout>
            <Meta title={title} description={metadescription} image={(dd.logo && dd.logo.url) || ''} path={`/partners/${dd.uid}`} />
            <Slices>
                {dd.body.map((slc) => {
                    switch (slc.slice_type) {
                        case 'header':
                            return (
                                <Header
                                    title={slc.primary.header_title}
                                    body={slc.primary.header_body}
                                    image={slc.primary.header_image}
                                    color={slc.primary.background}
                                    buttonUrl={slc.primary.header_url}
                                    buttonUsed={slc.primary.header_button_used}
                                    buttonText={slc.primary.header_button_text}
                                />
                            )

                        case 'email___typeform':
                            return (
                                <TypeForm
                                    content={slc.items}
                                    title={slc.primary.typeform_title}
                                    body={slc.primary.typeform_body}
                                    buttonUrl={slc.primary.typeform_url}
                                    color={slc.primary.background}
                                    buttonUsed={slc.primary.typeform_button_used}
                                    buttonText={slc.primary.typeform_button_text}
                                />
                            )

                        case 'faq':
                            return <FAQ questions={slc.items} title={slc.primary.faq_title} body={slc.primary.faq_body} color={slc.primary.background} />

                        case 'freeform':
                            return (
                                <Freeform
                                    color={slc.primary.background}
                                    type={slc.primary.type}
                                    title={slc.primary.freeform_title}
                                    body={slc.primary.freeform_body}
                                    image={slc.primary.freeform_image}
                                    buttonUsed={slc.primary.freeform_button_used}
                                    buttonText={slc.primary.freeform_button_text}
                                    buttonUrl={slc.primary.freeform_button_url}
                                />
                            )

                        default:
                            return null
                    }
                })}
            </Slices>
        </Layout>
    )
}

export default withPrismicPreview(Partner)

export const partnerQuery = graphql`
    query Partners($uid: String!) {
        prismicPartner(uid: { eq: $uid }) {
            _previewable
            data {
                visitable
                priority
                color
                keywords {
                    keyword {
                        text
                    }
                }
                logo {
                    url
                }
                metadescription {
                    text
                }
                metatitle {
                    text
                }
                name {
                    text
                }
                body {
                    ... on PrismicPartnerDataBodyFaq {
                        id
                        slice_type
                        primary {
                            background
                            faq_body {
                                text
                            }
                            faq_title {
                                text
                            }
                        }
                        items {
                            faq_answer {
                                text
                                html
                            }
                            faq_question {
                                text
                                html
                            }
                        }
                    }
                    ... on PrismicPartnerDataBodyEmailTypeform {
                        id
                        primary {
                            background
                            typeform_button_used
                            typeform_title {
                                text
                            }
                            typeform_body {
                                text
                                html
                            }
                            typeform_button_text {
                                text
                                html
                            }
                            typeform_url {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPartnerDataBodyHeader {
                        id
                        primary {
                            background
                            header_body {
                                html
                                text
                            }
                            header_button_text {
                                html
                                text
                            }
                            header_button_used
                            header_image {
                                url
                                alt
                            }
                            header_title {
                                text
                                html
                            }
                            header_url {
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicPartnerDataBodyFreeform {
                        id
                        slice_type
                        primary {
                            type
                            background
                            freeform_body {
                                html
                                text
                            }
                            freeform_button_text {
                                html
                                text
                            }
                            freeform_button_url {
                                url
                            }
                            freeform_button_used
                            freeform_image {
                                url
                                alt
                            }
                            freeform_title {
                                text
                                html
                            }
                        }
                    }
                }
            }
        }
    }
`
