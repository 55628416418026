import React from 'react'
import styled from 'styled-components'
import BalloonIcon from '../../../images/styleguide/balloon'

const StyledBalloon = styled.span`
    width: 40px;
    height: 40px;
    padding: 5px;
    display: inline-block;
    position: relative;
    text-align: center;
    margin-right: 16px;
    float: left;
    font-weight: bold;
    vertical-align: middle;
    color: ${(props) => props.theme.color[props.color]};

    & > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    & > .children {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: 100%;
        overflow: hidden;
        z-index: ${(props) => props.theme.zIndex.balloonContent};
        color: ${(props) => props.theme.color[props.textColor]};
        text-align: center;
    }
`

const BalloonSmall = ({ className, children, style, color = 'green', textColor = 'white' }) => (
    <StyledBalloon {...{ className, color, textColor, style }}>
        <BalloonIcon />
        <span className="children">{children}</span>
    </StyledBalloon>
)

export default BalloonSmall
