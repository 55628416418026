import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PopupModal } from 'react-calendly'
import Button from './button'
import PhoneIcon from '../../../images/styleguide/phone'

const TRACKING_PARAMETERS = [{ term: 'gclid', mappedTerm: 'utm_term' }, { term: 'utm_source' }, { term: 'utm_medium' }, { term: 'utm_campaign' }, { term: 'utm_content' }]

const Icon = styled.div`
    width: 30px;
    height: 25px;
    margin-right: 10px;
    margin-top: -3px;
`

const PhoneButton = ({ primary, tertiary, whiteTransparent, big, style, className, border }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [trackingParams, setTrackingParamns] = useState('')

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        let params = ''

        TRACKING_PARAMETERS.forEach((element) => {
            if (urlParams.get(element.term)) {
                params += `&${element.mappedTerm ? element.mappedTerm : element.term}=${urlParams.get(element.term)}`
            }
        })

        setTrackingParamns(params)
    }, [])

    return (
        <>
            <Button
                onClick={() => setIsModalOpen(true)}
                primary={primary}
                whiteTransparent={whiteTransparent}
                tertiary={tertiary}
                big={big}
                style={style}
                className={className}
                {...border}
            >
                <Icon>
                    <PhoneIcon />
                </Icon>
                Plan gratis gesprek
            </Button>
            <PopupModal
                url={`https://calendly.com/tellow-compleet/compleet-informatie?primary_color=5950ec${trackingParams}`}
                onModalClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={typeof document !== 'undefined' ? document.getElementById('___gatsby') : null}
            />
        </>
    )
}
export default PhoneButton
