import React, { useState, useRef, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import tw, { styled } from 'twin.macro'
import MobileDropDownIcon from '../MobileDropDown/MobileDropDown'

const StyledFAQ = styled.div`
    margin: 1rem;

    * {
        font-size: 0.8rem;
        color: ${({ theme }) => theme.color.tellowGray800};

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ul {
        list-style: normal;
    }

    ol {
        list-style: disc;
    }

    ol,
    ul {
        margin: 1.666rem;
        list-style-position: outside;

        > li {
            margin-bottom: 0.25rem;

            &::marker {
                font-weight: 500;
                color: ${({ theme }) => theme.color.tellowPurple};
            }
        }
    }

    a {
        cursor: pointer;
        text-decoration: underline;
        color: ${({ theme }) => theme.color.tellowPurple};
    }
`

const Accordeon = ({ id, initialOpen, color = 'rgba(0,0,0,.7)', onToggle, question, children }) => {
    const [height, setHeight] = useState(0)
    const loadedOnce = useRef(false)

    const toggle = () => {
        if (onToggle) onToggle()
        setHeight(height === 0 ? 'auto' : 0)
        loadedOnce.current = true
    }

    useEffect(() => {
        if (initialOpen) {
            toggle()
        }
    }, [])

    const styles = {
        question: tw`block py-4 cursor-pointer border-0 tracking-tight antialiased font-semibold text-sm sm:text-base border-b border-solid border-gray-600 border-opacity-40 pl-1 focus:outline-none`,
    }

    return (
        <>
            <a
                id={id}
                style={{ color }}
                tabIndex={0}
                role="button"
                active={height !== 0}
                onClick={() => toggle()}
                onKeyPress={() => toggle()}
                css={styles.question}
                initialOpen={initialOpen}
            >
                <MobileDropDownIcon active={height !== 0} style={{ float: 'right' }} />
                {question}
            </a>
            <AnimateHeight duration={300} height={height} delay={100}>
                <StyledFAQ active={height !== 0}>{height !== 0 || loadedOnce.current ? children : null}</StyledFAQ>
            </AnimateHeight>
        </>
    )
}

export default Accordeon
