// @ts-check
import React from 'react'
import tw from 'twin.macro'
import Link from '../../Link/link'
import StreamlineIcon from '../../../../images/icons/streamline'

/**
 * @typedef {Object} ItemProps
 * @property {string} linkto            URL that Link has to go to
 * @property {string} icon              StreamLine icon it receives, is resolved in images/icons/index.js
 * @property {string} name              Displayed name of Item
 * @property {string} bold              Set item to bold (text)
 * @property {string} hex               !!! Hex-code !!! to convert to filter for SVG.
 * @property {boolean} shouldBeTracked  Boolean for tracking event
 * @property {string} description       Description for tracking event
 * @property {() => void} onClick       Callback fn.
 */

/**
 * Item takes the following params:
 * @param {Object} arguments - Item arguments, will be destructured.
 * @param {ItemProps} arguments.content - Item props (destructured from object).
 * @param {('nl' | 'en')=} arguments.i18n Language property
 */
export default function Item({ i18n, content }) {
    const { linkto, icon, name, hex, bold, onClick: parentOnClick, shouldBeTracked, description } = content
    const style = {
        item: tw`flex items-center border-t border-l-0 border-r-0 border-b-0 border-solid border-gray-600 border-opacity-20 py-4 antialiased cursor-pointer bg-tellow-gray-100`,
        link: tw`pl-6 text-tellow-black hover:font-semibold`,
        button: tw`appearance-none text-tellow-purple font-semibold antialiased text-left m-0 pl-6 hover:opacity-90`,
        icon: tw`my-auto inline-flex mr-4`,
    }

    /**
     * If a button does not have a linkto,
     * but does have an onClick, perform that.
     */
    if (!linkto && parentOnClick) {
        return (
            <button
                type="button"
                onClick={() => parentOnClick()}
                // @ts-ignore
                css={[style.button, style.item]}
                href={linkto}
                description={description}
            >
                {name}
            </button>
        )
    }

    /**
     * Link should only be used internally.
     * Outbound https should use a-tags.
     */
    if (linkto && linkto.startsWith('https://')) {
        return (
            <Link css={[style.item, style.link]} to={linkto} shouldBeTracked={shouldBeTracked} description={description}>
                <StreamlineIcon css={style.icon} color={hex || '#000000'} icon={icon} size={24} />
                {name}
            </Link>
        )
    }

    /**
     * Use Link for internal links.
     */
    return (
        <Link css={[style.item, style.link, bold && tw`font-semibold`]} to={linkto} language={i18n} shouldBeTracked={shouldBeTracked} description={description}>
            {name}
        </Link>
    )
}
