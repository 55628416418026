import React from 'react'
import Slick from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.state = { width: 0 }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth })
    }

    render() {
        const { children, className } = this.props

        const settings = {
            centerMode: true,
            dots: true,
            infinite: true,
            arrows: false,
            speed: 300,
            slidesToShow: this.state.width >= 1100 ? 3 : 1,
            focusOnSelect: true,
        }

        return (
            <Slick {...settings} className={className}>
                {children}
            </Slick>
        )
    }
}

const StyledSlider = styled(Slider)`
    padding-bottom: 50px; /* for dots */

    * {
        outline: 0;
    } /* small reset to prevent borders on divs */

    .slick-slide {
        padding: 24px;

        @media (max-width: 400px) {
            padding: 24px 0 !important;
        }
    }

    .slick-dots {
        position: relative;
        margin-top: -50px;
    }

    .slick-dots li button:before,
    .slick-dots li.slick-active button:before {
        color: ${(props) => (props.color ? props.theme.color[props.color] : props.theme.color.tellowGreen)};
    }

    .slick-dots li.slick-active button:before {
        font-size: 14px;
    }

    .slick-slide > * {
        cursor: pointer;
        opacity: 0.4;
        transition: all 300ms ease;
    }

    .slick-slide > *:hover {
        opacity: 0.5;
    }

    .slick-center > * {
        cursor: default;
        opacity: 1;
        transform: scale(1.08);
        box-shadow: ${(props) => props.theme.shadow};
    }
    .slick-slide > *:hover {
        opacity: 1;
    }
`

export default StyledSlider
