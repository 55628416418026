/* eslint-disable no-nested-ternary */
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Link from '../Link/link'

const height = {
    base: '4rem',
    tablet: '5rem',
    desktop: '6rem',
}

const breakpoint = {
    tiny: '350px',
    tablet: '1147px',
}

const shortTransitionTime = '25ms'
const defaultTransitionTime = '100ms'

export const Wrap = styled.div`
    height: ${height.base};

    position: sticky;

    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;

    z-index: 40;

    will-change: background-color;
    background-color: ${({ usePrimaryColor }) => (usePrimaryColor ? 'white' : 'var(--menu-color, white)')};

    padding-bottom: 4rem;

    @media screen and (min-width: 768px) {
        height: 70px;
    }
`

export const Bar = styled(motion.nav)`
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.navbar + 1};
    height: ${height.base};

    width: 100vw;
    max-width: min(100%, 1440px);

    display: grid;
    align-items: center;
    grid-template-columns: 2.75fr 1fr;

    margin: 0;

    will-change: background-color;
    background-color: ${({ usePrimaryColor }) => (usePrimaryColor ? 'white' : 'var(--menu-color, white)')};

    .row__container {
        display: flex;
        height: ${height.base};
        flex-direction: row;
        justify-content: flex-start;
    }

    @media screen and (max-width: ${breakpoint.tablet}) {
        grid-template-columns: repeat(2, 1fr);

        .branding {
            justify-content: flex-start !important;
            color: white;
        }

        .menu__mobile {
            display: flex;
            justify-content: flex-end;

            button {
                width: min-content;
                flex: 0 1;
            }
        }
    }

    will-change: height;
    transition: height ${defaultTransitionTime} ease-out;
    font-weight: 600;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & > * {
        z-index: ${({ theme }) => theme.zIndex.menu};
    }

    .fresnel-container {
        display: flex;
        flex-direction: row;
    }

    .branding {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 1rem;

        /* Logo styling */
        > a {
            will-change: filter;
            transition: color 50ms ease-in-out;
        }
    }

    .cta {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        font-weight: 400;
    }

    .logolink__copy {
        display: inline-block;
    }

    @media screen and (max-width: ${breakpoint.tablet}) {
        .cta {
            display: none;
        }
    }

    @media screen and (min-width: ${breakpoint.tablet + 1}) {
        line-height: 26px;
        height: ${height.tablet};
    }

    .menu__mobile {
        order: 2;
    }

    .menu__desktop {
        justify-content: flex-start;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;

        span.menu-slider > a {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            will-change: filter;
            color: var(--menu-color, white);
            filter: ${({ usePrimaryColor }) => (usePrimaryColor ? 'invert(100) brightness(0) saturate(100) grayscale()' : 'invert(100) brightness(100) saturate(100) grayscale()')};
        }
    }

    @media screen and (max-width: ${breakpoint.tablet}) {
        .menu__desktop {
            display: none;
        }
    }

    @media screen and (min-width: ${breakpoint.tablet}) {
        .menu__mobile {
            display: none;
        }
    }

    @media screen and (max-width: ${breakpoint.tiny}) {
        .logolink__copy {
            display: none;
        }
    }
`

export const LogoLink = styled(Link)`
    display: flex;

    & > svg {
        height: 1.25rem;

        @media screen and (min-width: ${breakpoint.tablet + 1}) {
            height: 26px;
        }
    }
`

export const StyledFunctionDropdown = styled(motion.section)`
    position: absolute;
    max-width: 100%;
    width: 100vw;
    left: 0;
    right: 0;
    top: 2.9rem;
    margin-top: 1.1rem;

    z-index: ${({ theme }) => theme.zIndex.navbar};
    background-color: ${({ theme }) => theme.color.tellowWhite} !important;
    filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
`

export const StyledLink = styled(Link)`
    position: relative;
    font-size: ${({ theme }) => theme.font.size.xs};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    display: flex;
    transition: all ${shortTransitionTime} ease-in-out;
    will-change: color, font-weight;

    &:not(.active) {
        color: ${({ theme }) => theme.color.tellowWhite};
        font-weight: 400;
        opacity: 0.85;
    }

    &.active {
        color: ${({ theme }) => theme.color.tellowWhite};
        font-weight: 600;
    }

    &:hover {
        color: ${({ theme }) => theme.color.tellowWhite};
    }
`

export const DropdownOrchestrator = styled.span`
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: auto 0;
    height: ${height.base};
    padding: 1.1rem 0;
`
export const DropdownArrow = styled.div`
    transition: all 0.2s linear;
    display: inline-block;
    line-height: 0%;
    margin-left: 2px;
    margin-top: 0.2rem;
    cursor: pointer;
    height: 18px;
`

export const StyledSpan = styled.span`
    position: relative;
    margin: auto 1rem;

    span {
        position: absolute;
        top: 0;
        height: 0.1rem;
        margin-top: -0.5rem;
        left: 0px;

        /**
        * Take the background color of the
        * bar and invert it on hover.
        */
        will-change: background-color;
        background-color: ${({ usePrimaryColor, theme }) => (usePrimaryColor ? theme.color.tellowPurple : `var(--menu-color, ${theme.color.tellowPurple})`)};
        ${({ usePrimaryColor }) => !usePrimaryColor && `filter: blur(0px) invert(1) brightness(1.5) saturate(1) grayscale(1)`};
    }
`
