import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
    box-shadow: ${(props) => props.theme.shadow};
    background: white;
    padding: 16px;
    margin: 0 auto 16px;
    max-width: 550px;

    @media screen and (min-width: 768px) {
        padding: 24px;
        position: relative;
        left: ${(props) => (props.pullLeft ? '-30px' : '30px')};
    }
`

const Card = ({ children, style, className, pullLeft }) => <StyledCard {...{ style, className, pullLeft }}>{children}</StyledCard>

export default Card
