import React from 'react'

import { Heading } from '../../typography'
import CTA from './CTA'
import HeroPicture from './heroPicture'

import { Block, Container, Item, Pair, Text as TextBlock } from '../../../utils'

const WhitepaperHero = ({ title, subtitle, url, alt, label, downloadUrl }) => (
    <Container style={{ paddingTop: '4rem' }}>
        <Pair>
            <Item>
                <TextBlock>
                    <Heading size={70} sizeTablet={60} sizeDesktop={70}>
                        {title}
                    </Heading>
                    <Heading size={25} style={{ fontWeight: 'normal' }}>
                        <div>{subtitle}</div>
                    </Heading>
                    <CTA labelName={label} passedUrl={downloadUrl} />
                </TextBlock>
            </Item>
            <Item>
                <Block>
                    <HeroPicture url={url} alt={alt} />
                </Block>
            </Item>
        </Pair>
    </Container>
)

export default WhitepaperHero
