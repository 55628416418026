import styled from 'styled-components'

const Colored = styled.div`
    font-weight: ${(props) =>
        // eslint-disable-next-line no-nested-ternary
        props.semibold ? 600 : props.bold ? 'bold' : 'normal'};
    color: ${(props) => props.theme.color[props.color ? props.color : 'darkBlue']};
`

export default Colored
