import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import styled from 'styled-components'

const StatsContainer = styled.ul`
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
        flex-direction: column;
    }
`

const Stat = styled.li`
    margin: 0 auto;
    padding: 1rem 2rem;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    /* min-width: 10rem; */
    /* max-width: max-content; */
    flex: 0 1 30%;

    h3 {
        font-size: 2rem;
        color: ${({ theme }) => theme.color.tellowGreen};
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 0.9rem;
        font-weight: 500;
    }

    @media screen and (max-width: 720px) {
        flex: 1;
        margin: 1rem;
    }
`

const thousandsSeparators = (num) => {
    const numParts = num.toString().split('.')
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    return numParts.join('.')
}

export default function stats({ data }) {
    useEffect(() => {
        AOS.init({ disable: 'mobile', once: true, easing: 'ease' })
    }, [])

    return (
        <StatsContainer>
            {data.map((entry, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stat key={`stat${i}`} data-aos="fade">
                    <h3>{thousandsSeparators(entry.amount)}</h3>
                    <p>{entry.what}</p>
                </Stat>
            ))}
        </StatsContainer>
    )
}
