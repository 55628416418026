/* eslint-disable no-nested-ternary */
import React from 'react'
import tw from 'twin.macro'
import { RegisterButton } from '../UI'
import { useTranslate } from '../../hooks'

const style = {
    wrap: tw`flex flex-col`,
    button: tw`mt-2 mb-8 text-base sm:text-sm xs:text-xs`,
    strong: tw`font-bold opacity-70 mb-4`,
    body: tw`antialiased mt-4 mb-8 font-medium opacity-50 text-xs`,
}

const CTA = ({ center = true, start = false, text = 'Open een Tellow account', margin, align = 'center', color = 'black', width = '100%', padding = '0', disclaimer = true }) => {
    const { t } = useTranslate('generic')

    return (
        <div css={[style.wrap, center ? tw`items-center` : start ? tw`items-start` : tw`items-center md:items-start`]} style={{ width, padding, margin }}>
            <RegisterButton big tertiary context="banks">
                {text} &nbsp; <span arrow="true">→</span>
            </RegisterButton>

            {disclaimer && (
                <p css={style.body} style={{ textAlign: align, color }}>
                    <strong css={style.strong}>{t.noPaymentRequired}</strong>
                    <br />
                    {t.doneInABreeze}
                </p>
            )}
        </div>
    )
}

export default CTA
