import { useState, useEffect } from 'react'

export default function useDeviceDetect() {
    const [isMobile, setMobile] = useState(false)
    const [isAndroid, setAndroid] = useState(false)
    const [isApple, setApple] = useState(false)

    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
        setMobile(Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)))
        setAndroid(Boolean(userAgent.match(/Android/i)))
        setApple(Boolean(userAgent.match(/iPhone|iPad|iPod/i)))
    }, [])

    return { isMobile, isAndroid, isApple }
}
