import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import tw from 'twin.macro'

const styles = {
    bg: tw`bg-gray-50`,
    wrapper: tw`max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8`,
    maxWidth: tw`max-w-3xl mx-auto divide-y-2 divide-gray-200`,
    title: tw`text-center text-3xl font-extrabold text-gray-900 sm:text-4xl`,
    body: tw`text-center text-base font-normal text-gray-700 sm:text-lg`,
    definitionList: tw`mt-6 space-y-6 divide-y divide-gray-200`,
    disclosure: tw`pt-6`,
    definitionTable: tw`text-lg`,
    diclosureButton: tw`appearance-none cursor-pointer border-none bg-transparent p-0 text-left w-full flex justify-between items-start text-gray-400`,
    questionText: tw`font-medium text-gray-900`,
    chevronWrapper: tw`ml-6 h-7 flex items-center`,
    chevron: tw`h-6 w-6 fill-none`,
    disclosurePanel: tw`mt-2 pr-12`,
    answerText: tw`text-base text-gray-500`,
}

export const FAQ = ({ items, primary }) => (
    <div css={styles.bg}>
        <div css={styles.wrapper}>
            <div css={styles.maxWidth}>
                <h2 css={styles.title}>{primary.faq_title.text}</h2>
                <p css={styles.body}>{primary.faq_body.text}</p>
                <dl css={styles.definitionList}>
                    {items.map((faq) => (
                        <Disclosure key={faq.question} css={styles.disclosure}>
                            {({ open }) => (
                                <>
                                    <dt css={styles.definitionTable}>
                                        <Disclosure.Button css={styles.diclosureButton}>
                                            <span css={styles.questionText}>{faq.faq_question.text}</span>
                                            <span css={styles.chevronWrapper}>
                                                <ChevronDownIcon
                                                    style={{ transform: `rotate(${open ? 180 : 0}deg)`, transition: 'transform 150ms ease' }}
                                                    css={styles.chevron}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel css={styles.disclosurePanel}>
                                        {open}
                                        <p css={styles.answerText}>{faq.faq_answer.text}</p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
        </div>
    </div>
)
