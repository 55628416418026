import React from 'react'
import styled from 'styled-components'
import { checkForLength } from './helpers'
import { Accordeon } from '../../UI'
import { Block } from '../partners'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    align-items: stretch;
    justify-content: center;
    padding-top: 3rem;
`

export default function FAQ({ questions, title, color, body }) {
    const titleProp = title.text
    const bodyProp = body.html
    const hasQuestions = questions
    const allQuestionsHaveAnswers = hasQuestions && questions.find((entry) => checkForLength(entry.faq_answer))

    // If data is inconsistent or incomplete; return early.
    // No breaking this website on my agenda, bruh.
    if (!hasQuestions || !allQuestionsHaveAnswers) return null

    return (
        <Block color={color || 'white'}>
            {titleProp ? <h2>{titleProp}</h2> : null}
            {bodyProp ? <div dangerouslySetInnerHTML={{ __html: bodyProp }} /> : null}

            {hasQuestions && allQuestionsHaveAnswers ? (
                <Wrapper>
                    {questions.map((QA) => (
                        <Accordeon key={QA.faq_question.text} question={QA.faq_question.text}>
                            {QA.faq_answer.html}
                        </Accordeon>
                    ))}
                </Wrapper>
            ) : null}
        </Block>
    )
}
