import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import tw from 'twin.macro'
import { Column, ContentRow } from '../../components/pages/landingpage/landingpage'
import Price from '../../components/pages/landingpage/price'
import Summary from '../../components/pages/landingpage/summary'
import { FAQ } from '../../components/pages/landingpage/faq'
import Coupon from '../../components/pages/landingpage/coupon'
import { BlockSection } from '../../utils/prismic/sliceStyles'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import FeaturesBlock from '../../components/pages/homepage/benefitsBlock'
import PartnerBlock from '../../components/pages/homepage/partnersBlock'
import SocialProofBlock from '../../components/pages/homepage/socialProofBlock'
import CallToAction from '../../components/pages/store/callToAction'
import { Button } from '../../components/UI'
import { Freeform } from '../../components/pages/partner'
import { CTA, Register } from '../../components/tailwind'
import Feature from '../../components/tailwind/features'
import SubtleCTA from '../../components/pages/landingpage/subtle-cta'
import { SupportBlock } from '../../components/pages/homepage'
import { useTranslate } from '../../hooks'

const LandingspageNotIndexed = ({ data }) => {
    const landingsPage = data.prismicLandingspageNotIndexed.data
    const landingsPageUid = data.prismicLandingspageNotIndexed.uid

    const shouldBeIndexedBySearchEngines = (option) => option === 'index by search engines'

    if (landingsPage) {
        const landingsPageContent = landingsPage.body.map((slice) => {
            if (slice.slice_type === 'lp-features') {
                return <FeaturesBlock key={slice.id} />
            }

            if (slice.slice_type === 'summary') {
                return <Summary key={slice.id} data={slice} title={slice.primary.summary_title.text} items={slice.items} />
            }

            if (slice.slice_type === 'freeform') {
                return (
                    <Freeform
                        key={slice.id}
                        color={slice.primary.background}
                        type={slice.primary.type}
                        title={slice.primary.freeform_title}
                        body={slice.primary.freeform_body}
                        image={slice.primary.freeform_image}
                        buttonUsed={slice.primary.freeform_button_used}
                        buttonText={slice.primary.freeform_button_text}
                        buttonUrl={slice.primary.freeform_button_url}
                    />
                )
            }

            if (slice.slice_type === 'coupon') {
                return (
                    <Coupon
                        key={slice.id}
                        color={slice.primary.background}
                        code={slice.primary.code.text}
                        title={slice.primary.coupon_title.text}
                        body={slice.primary.coupon_body.html}
                    />
                )
            }

            if (slice.slice_type === 'register') {
                return <Register key={slice.id} data={slice.primary} />
            }

            if (slice.slice_type === 'lp-support') {
                return <SupportBlock key={slice.id} />
            }

            if (slice.slice_type === 'lp-partners') {
                return <PartnerBlock key={slice.id} english={slice.primary.language === true} />
            }

            if (slice.slice_type === 'lp-social-proof') {
                return <SocialProofBlock key={slice.id} />
            }

            if (slice.slice_type === 'cta') {
                return <CallToAction className="cta" key={slice.id} content={slice} />
            }

            if (slice.slice_type === 'screenshot_cta') {
                return <CTA key={slice.id} content={slice} />
            }

            if (slice.slice_type === 'feature') {
                return <Feature key={slice.id} content={slice} />
            }

            if (slice.slice_type === '2_column_content') {
                return (
                    <BlockSection key={slice.id} className={`${slice.primary.background_color}`}>
                        <ContentRow>
                            <Column className={slice.primary.text_color}>
                                <h2>{slice.primary.first_column_title.text}</h2>
                                <p>{slice.primary.first_column_content.text}</p>
                            </Column>
                            <Column className={slice.primary.text_color}>
                                <h2>{slice.primary.second_column_title.text}</h2>
                                <p>{slice.primary.second_column_content.text}</p>
                            </Column>
                        </ContentRow>
                    </BlockSection>
                )
            }

            if (slice.slice_type === 'pricing') {
                return <Price key={slice.id} title={slice.primary.pricing_title.text} body={slice.primary.pricing_body.text} />
            }

            if (slice.slice_type === 'subtle_cta') {
                return <SubtleCTA key={slice.id} title={slice.primary.text.text} href={slice.primary.url.url} />
            }

            if (slice.slice_type === 'faq') {
                return <FAQ key={slice.id} items={slice.items} primary={slice.primary} />
            }

            return null
        })

        const metaInfo = shouldBeIndexedBySearchEngines(landingsPage.indexed) ? (
            <Meta path={`/lp/${landingsPageUid}`} title={landingsPage.meta_title.text} description={landingsPage.meta_description.text} />
        ) : (
            <Meta name="robots" indexed="noindex" path={`/lp/${landingsPageUid}`} title={landingsPage.meta_title.text} description={landingsPage.meta_description.text} />
        )

        return (
            /**
             * Used to be 'LayoutLanding', but since this page
             * is now 100% the same with the 'normal' pages,
             * it makes little sense to maintain both.
             */
            <Layout>
                {/* Meta / SEO */}
                {metaInfo}

                {/* Default header */}
                {landingsPage.disable_header === false ? null : <Content data={landingsPage} />}

                {/* Slices */}
                {landingsPageContent}
            </Layout>
        )
    }
}

const style = {
    bg: tw`relative bg-tellow-blue`,
    wrap: tw`bg-tellow-blue max-w-7xl py-15 mx-auto px-4 lg:py-20 lg:text-left`,
    button: tw`w-min-content mb-10 whitespace-nowrap text-base sm:text-sm xs:text-xs`,
    text: tw`w-full lg:w-1/2 px-4 sm:px-8 xl:pr-16`,
    h1: tw`text-tellow-green text-left font-bold tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    tag: tw`mt-2 mb-6 text-base font-semibold tracking-wider antialiased bg-tellow-purple bg-opacity-10 px-8 py-2 rounded-xl text-tellow-purple`,
    visual: tw`relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full`,
    image: tw`absolute inset-0 w-full h-full object-cover`,
}

const CONTENT = 'Content'
const Content = React.forwardRef(({ data }, ref) => {
    const { t } = useTranslate('landingPage')
    const firstColonIndex = data.title.text.indexOf(':')
    const hasNewPrefix = new RegExp(/^(nieuw|new):/, 'gi').test(data.title.text)

    return (
        <section ref={ref} css={style.bg}>
            <div css={style.wrap}>
                <div css={style.text}>
                    {/* Title */}
                    {hasNewPrefix && <span css={style.tag}>{t.new}</span>}
                    <h1 style={{ hyphens: 'auto' }} css={style.h1}>
                        {hasNewPrefix ? data.title.text.slice(firstColonIndex + 1, data.title.text.length).trim() : data.title.text}
                    </h1>

                    <p css={tw`prose text-sm! leading-relaxed! antialiased text-gray-300`}>{data.introduction.text}</p>

                    {/* Indicate whether CTA is disabled. Value 'false' indicates 'not disabled'. */}
                    {data.cta_button_toggle === false ? (
                        <>
                            <Button css={style.button} secondary big lightShadow href={data.cta_button_link.url}>
                                {data.cta_button_text.text} &nbsp; →
                            </Button>

                            <p css={tw`text-sm antialiased font-medium tracking-tight text-gray-200`}>{data.cta_button_sidenote.text}</p>
                        </>
                    ) : null}
                </div>
            </div>
            <div css={style.visual}>
                <img css={style.image} src={data.intro_image.url} alt={data.intro_image.alt} />
            </div>
        </section>
    )
})
Content.displayName = CONTENT

export default withPrismicPreview(LandingspageNotIndexed)

export const landingsPageQuery = graphql`
    query LandingsPageBySlug($uid: String!) {
        prismicLandingspageNotIndexed(uid: { eq: $uid }) {
            _previewable
            lang
            alternate_languages {
                id
                type
                lang
                uid
            }
            data {
                body {
                    ... on PrismicLandingspageNotIndexedDataBodyLpSocialProof {
                        id
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyLpPartners {
                        id
                        slice_type
                        primary {
                            language
                        }
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyLpFeatures {
                        id
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyCta {
                        id
                        slice_type
                        primary {
                            background_color
                            button_colors
                            button_text {
                                text
                            }
                            section_title {
                                text
                            }
                            text_color
                            url {
                                url
                                target
                            }
                        }
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyFreeform {
                        id
                        primary {
                            background
                            freeform_body {
                                text
                                html
                            }
                            freeform_button_text {
                                html
                                text
                            }
                            freeform_button_url {
                                url
                            }
                            freeform_button_used
                            freeform_image {
                                alt
                                url
                            }
                            freeform_title {
                                text
                                html
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyScreenshotCta {
                        id
                        primary {
                            screenshot_cta_title {
                                text
                                html
                            }
                            screenshot_cta_subtitle {
                                html
                                text
                            }
                            screenshot_cta_button_body {
                                html
                                text
                            }
                            screenshot_cta_button_image {
                                alt
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyFeature {
                        id
                        items {
                            feature_title {
                                html
                                text
                            }
                            feature_body {
                                html
                                text
                            }
                        }
                        primary {
                            features_pretitle {
                                text
                                html
                            }
                            features_title {
                                html
                                text
                            }
                            features_body {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyCoupon {
                        id
                        primary {
                            code {
                                text
                                html
                            }
                            coupon_body {
                                html
                                text
                            }
                            coupon_title {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyRegister {
                        id
                        primary {
                            background
                            register_title_1 {
                                text
                                html
                            }
                            register_title_2 {
                                html
                                text
                            }
                            register_body {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBody2ColumnContent {
                        id
                        primary {
                            first_column_content {
                                text
                            }
                            first_column_title {
                                text
                            }
                            second_column_content {
                                text
                            }
                            second_column_title {
                                text
                            }
                            text_color
                            background_color
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodyPricing {
                        id
                        primary {
                            pricing_title {
                                text
                                html
                            }
                            pricing_body {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingspageNotIndexedDataBodySummary {
                        primary {
                            summary_title {
                                text
                            }
                        }
                        items {
                            entry {
                                text
                            }
                        }
                        slice_type
                        id
                    }
                    ... on PrismicLandingspageNotIndexedDataBodySubtleCta {
                        id
                        primary {
                            text {
                                text
                            }
                            url {
                                url
                            }
                        }
                        slice_type
                    }
                }
                title {
                    text
                }
                introduction {
                    text
                }
                intro_image {
                    url
                    alt
                }
                cta_button_text {
                    text
                }
                cta_button_sidenote {
                    text
                }
                cta_button_link {
                    url
                    target
                }
                meta_description {
                    text
                }
                meta_title {
                    text
                }
                indexed
                language
                has_screenshot
                disable_header
                cta_button_toggle
            }
            uid
        }
    }
`
