/* eslint-disable camelcase */
import React from 'react'
import tw from 'twin.macro'

const style = {
    wrap: tw`max-w-7xl py-15 mx-auto px-4 sm:px-6 lg:px-8`,
    h2: tw`text-gray-800 font-black tracking-tight antialiased mb-8 mt-4 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    grid: tw`grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3`,
    card: tw`flex flex-col justify-between bg-white rounded-xl p-2 text-left shadow-xl`,
    image: tw`h-20 self-center block p-4 mt-4`,
    content: tw`order-last bg-gray-50 rounded-md p-4`,
    h4: tw`antialiased tracking-tight text-xl font-bold`,
    p: tw`antialiased text-gray-700 text-base`,
}
const Products = ({ content }) => (
    <section css={tw`w-full bg-tellow-green`}>
        <div css={style.wrap}>
            <h2 css={style.h2}>{content.primary.title.text}</h2>

            <div css={style.grid}>
                {content.items.map(({ title, description, product_image, link_to_product }) => (
                    <div css={style.card} key={title.text} href={link_to_product.url} target={link_to_product.target}>
                        <div css={style.content}>
                            <h4 css={style.h4}>{title.text}</h4>
                            <p css={style.p}>{description.text}</p>
                        </div>
                        <img css={style.image} src={product_image.url} alt={product_image.alt || 'Aanbieding'} />
                    </div>
                ))}
            </div>

            <p className={content.primary.text_color}>{content.primary.description.text}</p>
        </div>
    </section>
)

export default Products
