/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { lighten } from 'polished'
import { Container } from '../../../utils'

const style = {
    bgGradientPurple: tw`bg-gradient-to-r from-tellow-purple to-indigo-500`,
}

const RelativeContainer = styled(Container)`
    position: relative;
`

const ComponentContainer = styled.section`
    display: flex;
    justify-content: space-evenly;

    flex-direction: ${({ direction }) => direction || 'row'};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
    ${({ padding }) => padding && `padding: ${padding}rem`};

    position: relative;
    margin: ${({ margin }) => `${margin !== undefined ? margin : '2.5'}rem auto`};
`

const ColouredBand = styled((props) => <ColoredBackground {...props} />)`
    height: 4rem;
`

const ColoredBackgroundWithProps = styled((props) => <ColoredBackground {...props} />)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`

const ColoredBackgroundGradientWithProps = styled((props) => <ColoredBackgroundGradient {...props} css={style.bgGradientPurple} />)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`

const ColoredBackground = styled.div`
    color: ${(props) =>
        props.purple ? props.theme.color.new.softWhite : props.green ? 'inherit' : props.red ? props.theme.color.new.softWhite : props.blue ? 'white' : 'inherit'};
    background: ${(props) =>
        props.gradient
            ? `linear-gradient(to bottom, ${props.theme.color.tellowPurple}, ${lighten(0.215, props.theme.color.tellowPurple)})`
            : props.purple
            ? props.theme.color.tellowPurple
            : props.gray100
            ? props.theme.color.tellowGray100
            : 'white'};
`

const ColoredBackgroundGradient = styled.div`
    color: ${(props) =>
        props.purple ? props.theme.color.new.softWhite : props.green ? 'inherit' : props.red ? props.theme.color.new.softWhite : props.blue ? 'white' : 'inherit'};
    background: ${(props) => (props.purple ? style.bgGradientPurple : props.gray100 ? props.theme.color.tellowGray100 : 'white')};
`

export { RelativeContainer, ComponentContainer, ColouredBand, ColoredBackgroundWithProps, ColoredBackgroundGradientWithProps, ColoredBackground }
