import React from 'react'
import tw from 'twin.macro'

import { RegisterAsPartnerButton } from '../../CTA'

import mainguy from '../../../images/partners/photos/mainguy.png'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl mb-0 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-10`,
    bigTitle: tw`text-3xl lg:text-5xl font-plus-jakarta font-black items-start mb-6 text-tellow-purple`,
    text: tw`text-base text-gray-600 font-normal`,
    span: tw`inline text-tellow-purple!`,
    content: tw`flex flex-col h-auto items-start pt-10`,
    img: tw`hidden md:inline mx-auto my-0 p-0 filter drop-shadow-xl`,
    textblock: tw`flex flex-col items-start`,
    name: tw`text-center text-sm font-bold mb-0`,
    title: tw`text-center text-xs text-tellow-purple mb-0`,
    imageContainer: tw`flex flex-col justify-end`,
}

const Header = () => (
    <div css={style.wrapper}>
        <div css={style.grid}>
            <div css={style.content}>
                <div css={style.bigTitle}>Partner worden</div>
                <div>
                    <p css={style.text}>
                        Onze missie is om zelfstandige ondernemers te helpen. Onderneem samen met Tellow en help de zelfstandige verder op het gebied van innovatie en groei. Met
                        onze partnership maken wij dit een realiteit.
                    </p>
                </div>
                <RegisterAsPartnerButton text="Contact opnemen" url="mailto:support@tellow.nl" />
            </div>
            <div css={style.imageContainer}>
                <img css={style.img} src={mainguy} alt="Partner Worden" />
            </div>
        </div>
    </div>
)

export default Header
