import landingsPageNotIndexed from './src/templates/prismic/landingspage-not-indexed'
import starters from './src/templates/prismic/starters'
import partner from './src/templates/prismic/partner'
import store from './src/pages/store'
import job from './src/templates/prismic/job'
import whitepaper from './src/templates/prismic/whitepaper'
import home from './src/pages/index'
import homepage from './src/templates/prismic/homepage'
import blog from './src/templates/prismic/blog-post'

/**
 * Map used to find according template files
 * when generating Prismic previews
 * (for content editors).
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
export default {
    'landingspage-not-indexed': landingsPageNotIndexed,
    whitepaper,
    homepage,
    starters,
    partner,
    store,
    home,
    blog,
    job,
}
