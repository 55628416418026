import React from 'react'
import tw, { styled } from 'twin.macro'

const Container = styled.div`
    ${tw`bg-white`}
`
const Spacing = styled.div`
    ${tw`max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8`}
`
const Heading = styled.h2`
    ${tw`text-base leading-6 font-semibold uppercase tracking-wide`}
    color: ${({ theme }) => theme.color.tellowPurple};
`
const P = styled.p`
    ${tw`mt-2 text-3xl leading-9 font-extrabold text-gray-900`}
`
const P2 = styled.p`
    ${tw`mt-4 text-lg leading-7 text-gray-500`}
`
const Column = styled.div`
    ${tw`mt-12 lg:mt-0 lg:col-span-2`}
`
const calculateRows = (length) => {
    switch (length) {
        case 1:
        case 2:
            return tw`sm:grid-rows-1`
        case 3:
        case 4:
            return tw`sm:grid-rows-2`
        case 5:
        case 6:
            return tw`sm:grid-rows-3`
        case 7:
        case 8:
            return tw`sm:grid-rows-4`

        default:
            break
    }
}
const DList = styled.dl`
    ${tw`space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8`}
    ${(props) => calculateRows(props.length)}
`
const FeatureEntry = styled.div`
    ${tw`flex space-x-3`}
`
const Text = styled.div`
    ${tw`space-y-2`}
`
const Title = styled.dt`
    ${tw`text-lg leading-6 font-medium text-gray-900`}
`
const Details = styled.dd`
    ${tw`flex space-x-3 lg:py-0 lg:pb-4`}
`
const Span = styled.span`
    ${tw`text-base leading-6 text-gray-500`}
`

const SVG = styled.svg`
    ${tw`flex-shrink-0 h-6 w-6`}
    color: ${({ theme }) => theme.color.tellowGreen};
`
const Check = () => (
    <SVG aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth="2" d="M5 13l4 4L19 7" />
    </SVG>
)

export default function Feature({ content }) {
    const slice = content.primary
    const { items } = content

    return (
        <Container>
            <Spacing>
                <div>
                    <Heading>{slice.features_pretitle.text || 'Alles wat je nodig hebt'}</Heading>
                    <P>{slice.features_title.text || 'Easy boekhouden'}</P>
                    <P2>{slice.features_body.text || 'Met deze must-have functies, kan iederéén een topboekhouding neerzetten.'}</P2>
                </div>
                <Column>
                    <DList length={items.length}>
                        {items.map((feature, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <FeatureEntry key={idx}>
                                <Check />
                                <Text>
                                    <Title>{feature.feature_title.text}</Title>
                                    <Details>
                                        <Span>{feature.feature_body.text}</Span>
                                    </Details>
                                </Text>
                            </FeatureEntry>
                        ))}
                    </DList>
                </Column>
            </Spacing>
        </Container>
    )
}
