import React from 'react'
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Block } from '../../../utils'
import { Button } from '../../UI'

const style = {
    announcer: tw`antialiased text-left uppercase tracking-wide font-normal text-tellow-purple mb-0`,
    block: tw`relative overflow-hidden py-4 sm:py-10`,
    container: tw`max-w-7xl py-10 mx-auto px-4 sm:px-6 lg:px-8`,
    content: tw`order-2 lg:order-1 w-full flex flex-col justify-center`,
    title: tw`text-left text-gray-800 font-black tracking-tight antialiased mt-2 mb-6 max-w-prose leading-tight text-2xl md:text-3xl lg:text-4xl`,
    wrap: tw`relative z-10 grid grid-cols-1 lg:grid-cols-2 gap-8 w-full`,
    body: tw`antialiased text-sm font-normal text-gray-500 mb-10 max-w-prose`,
    image: tw`order-1 lg:order-2 relative w-full items-center mb-6 lg:mb-0`,
    placement: tw`relative object-cover object-left`,
    base: tw`relative w-full max-w-xl h-full object-cover object-center`,
}

const buttonStyle = {
    solid: tw`flex items-center justify-center whitespace-pre-wrap break-normal`,
    outlined: tw`mr-auto bg-white! border-2 border-solid border-tellow-purple! text-tellow-purple whitespace-pre-wrap break-normal flex items-center justify-center`,
}

const personStyle = {
    base: tw`relative overflow-hidden rounded-2xl mb-0 object-cover object-center w-full h-40 lg:h-full`,
}
const Person = ({ name, src }) => <GatsbyImage alt={name || ''} image={src.childImageSharp.gatsbyImageData} css={personStyle.base} />

const Actionables = ({ content }) => (
    <div css={tw`max-w-xs space-y-4`}>
        <Button big purple css={buttonStyle.solid} href="https://help.tellow.nl/nl/articles/8575410-starten-met-tellow-video">
            Bekijk de webinar
        </Button>
        <Button big purple css={buttonStyle.outlined} href="/support">
            Lees meer  &nbsp; <span className="arrow">{'->'}</span>
        </Button>
    </div>
)

const SupportHeroes = ({ data }) => (
    <div css={tw`relative grid grid-cols-12 w-full gap-2.5 md:rotate-deg-2`}>
        <div css={style.base} style={{ gridColumn: '3 / span 4' }}>
            <Person src={data.hein} />
        </div>
        <div css={style.base} style={{ gridColumn: '7 / span 4' }}>
            <Person src={data.jelmer} />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={data.juul} />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={data.midas} />
        </div>
        <div css={style.base} style={{ gridColumn: 'span 4' }}>
            <Person src={data.siem} />
        </div>
    </div>
)

const SupportBlock = ({ content }) => {
    const data = useStaticQuery(
        graphql`
            query {
                midas: file(relativePath: { eq: "team/employees/just.png" }) {
                    ...fluidImage
                }
                hein: file(relativePath: { eq: "team/employees/xander.png" }) {
                    ...fluidImage
                }
                siem: file(relativePath: { eq: "team/employees/lois.png" }) {
                    ...fluidImage
                }
                jelmer: file(relativePath: { eq: "team/employees/remco.png" }) {
                    ...fluidImage
                }
                juul: file(relativePath: { eq: "team/employees/sylvia.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    return (
        <Block bold css={style.block}>
            <div css={style.container}>
                <div css={style.wrap}>
                    <div css={style.content}>
                        <p css={style.announcer}>{content.primary.success_announcer.text}</p>
                        <h2 css={style.title}>We behandelen jouw vragen met een persoonlijk antwoord, afgestemd op jouw situatie</h2>
                        <p css={style.body}>{content.primary.description.text}</p>

                        <Actionables content={content} />
                    </div>

                    <div css={style.image}>
                        <SupportHeroes data={data} />
                    </div>
                </div>
            </div>
        </Block>
    )
}

export default SupportBlock

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
