export const excerptResolver = (prismicBlogData) => {
    if (prismicBlogData.meta_description) {
        return prismicBlogData.meta_description
    }
    if (prismicBlogData.excerpt) {
        return prismicBlogData.excerpt
    }
    return prismicBlogData.content.text
}

export const truncate = (text, n = 250, useWordBoundary = false) => {
    if (text <= n) {
        return text
    }
    const subString = text.substr(0, n - 1)
    return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}...`
}
