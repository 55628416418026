/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Button from '../../UI/Buttons/button'

const shared = css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

const policy = css`
    opacity: 0.3;
    font-size: 0.75rem;
    font-weight: 500;
    padding-top: 0.5rem;
    margin: 0 auto;
`

const button = css`
    font-weight: bold;
    line-height: 25px;
    border-radius: 35px;
    padding: 23px 40px 22px;
    border: none;
`

const FormWrapper = styled.div`
    ${shared}
    flex-wrap: wrap;
    position: relative;
    justify-content: center;

    form {
        display: flex;
        flex: 1;

        margin: 0 auto;
        margin-top: 0.5rem;
        margin-bottom: 1rem !important;
        max-width: 750px;
        width: 100%;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            text-align: center;

            input {
                margin-bottom: 1rem;
                margin-right: 0 !important;
            }
        }

        input:not(#cta-form-submit) {
            ${button}

            flex: 1;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
            color: #4840bb;
            margin-right: 1rem;
            transition: box-shadow 0.15s ease-in-out;
        }

        input:not(#cta-form-submit):focus {
            outline: none;
            box-shadow: 0px 0 0 3px ${({ theme }) => theme.color.tellowPurple};
            transition: box-shadow 0.15s ease-in-out;
        }

        #cta-form-submit {
            ${button}

            flex: 1;
            max-width: 300px;
            min-width: min-content;

            color: ${({ theme }) => theme.color.tellowWhite};
            background: ${({ theme }) => theme.color.tellowPurple};

            @media screen and (max-width: 600px) {
                max-width: unset;
            }
        }
    }

    #privacy-policy {
        ${policy}
        flex: 1;
    }
`

const ButtonWrapper = styled.div`
    ${shared}

    justify-content: flex-start;
    margin: ${(props) => (props.center ? '0 auto' : '0')};

    form {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a:first-child span {
            transition: all 0.5s ease;

            display: inline-block;
            font-size: inherit;
            padding: 0;
            height: unset;
            background: unset;
            font-weight: 600;
            transform: translateX(0);
            opacity: 0.8;
        }

        a:first-child:hover span {
            transition: all 0.5s ease;

            opacity: 1;
            transform: translateX(5px);
        }

        #privacy-policy {
            flex: 1;
            padding-top: 1rem;
            margin: 0 auto;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    #privacy-policy {
        ${policy}
    }
`

function RegisterAsPartnerForm({ text, url, center }) {
    const [email, setEmail] = useState('')

    const redirectToTypeform = (e) => {
        e.preventDefault()
        const checkForEmailPrefilled = email ? `${url}?email=${email}` : url
        window.location.assign(checkForEmailPrefilled)
    }

    return (
        <FormWrapper id="CTA" center={center || false}>
            <form onSubmit={(e) => redirectToTypeform(e)}>
                <input id="" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="jouw@email.com" />
                <input id="cta-form-submit" type="submit" value={`${text}  →`} />
            </form>
            <a href="/privacy" target="_blank" rel="noopener noreferrer" id="privacy-policy">
                Privacybeleid van Tellow
            </a>
        </FormWrapper>
    )
}

function RegisterAsPartnerButton({ text, url, center }) {
    return (
        <ButtonWrapper center={center || false}>
            <form>
                <Button noShadow big gray href={url} target="_blank" purple>
                    {text || 'Meld je nu aan'} <span className="arrow">{'->'}</span>
                </Button>
            </form>
        </ButtonWrapper>
    )
}

export { RegisterAsPartnerForm, RegisterAsPartnerButton }
