import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { XMLParser } from 'fast-xml-parser'
import config from '../../../config'
import { getCanonicalFromPath } from '../../../utils'
import { WebSite } from './schema-org'
import { JsonLD, MetaTags, OpenGraph, TwitterMeta } from './tags'

const Meta = (data) => {
    const [reviews, setReviews] = useState([])

    const options = { ignoreAttributes: false }
    const parser = new XMLParser(options)

    const { path, title, jsonLD = [] } = data
    const canonical = getCanonicalFromPath(path)
    const creator = config.twitter

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            const request = await fetch('https://www.klantenvertellen.nl/v1/review/feed.xml?hash=loumzk72ohzeylb')
            const convertedXmlResponse = await request.text()
            setReviews(parser.parse(convertedXmlResponse).ReviewFeedDto)
        } catch (error) {
            console.error(error)
        }
    }

    const jsonLDWithReviews = jsonLD.concat(WebSite({ title, canonical })).map((entry) => ({
        ...entry,
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: reviews.last12MonthAverageRating || config.rating.klantenvertellen.score,
            reviewCount: reviews.numberReviews || config.rating.klantenvertellen.count,
            bestRating: config.rating.klantenvertellen.best,
            itemReviewed: {
                '@type': 'Organization',
                name: 'Tellow',
            },
        },
    }))

    return (
        <>
            <MetaTags {...data} canonical={canonical} />
            <OpenGraph {...data} canonical={canonical} />
            <TwitterMeta {...data} creator={creator} />
            <JsonLD json={jsonLDWithReviews} />
        </>
    )
}

Meta.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    keywords: PropTypes.array,
    jsonLD: PropTypes.array,
    image: PropTypes.string,
}

Meta.defaultProps = {
    keywords: [],
    jsonLD: [],
    image: undefined,
}

export default Meta
