/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lighten } from 'polished'
import { motion } from 'framer-motion'
import Linkify from 'react-linkify'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Meta from '../../components/layout/meta'
import { Layout } from '../../components/layout'
import { Wave, ColoredBackground } from '../../components/UI'
import { Title } from '../../components/tailwind'
import { reviewStructuredData } from '../../components/layout/meta/schema-org'

const Container = styled.div`
    padding: 4rem 1rem 0 1rem;
    overflow: hidden;
    max-width: 960px;
    margin: 0 auto;

    @media screen and (max-width: 720px) {
        padding: 2rem 1rem 0 1rem;
    }

    @media screen and (max-width: 960px) {
        margin: inherit 2rem;
    }
`

const JobHeader = styled.div`
    position: relative;
    margin: 2rem 0;

    div:first-child {
        p {
            color: ${({ theme }) => theme.color.tellowPurple};
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0.025em;
        }

        h1 {
            margin-top: 0.5rem;
            line-height: 2rem;
            letter-spacing: -0.05em;
            color: ${({ theme }) => theme.color.tellowGray800};
            font-size: 2.5rem;
            font-weight: 800;
        }
    }
`

const JobDescription = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;

    @media screen and (max-width: 720px) {
        grid-template-columns: unset;
        gap: unset;
    }
`

const Summary = styled.div`
    color: ${({ theme }) => theme.color.tellowGray600};
    margin-bottom: 1.5rem;

    h3 {
        color: ${({ theme }) => theme.color.tellowGray800};

        &.green {
            color: ${({ theme }) => theme.color.tellowGreen};
        }
    }

    li {
        list-style-type: disc;
        list-style-position: outside;
    }

    @media screen and (max-width: 720px) {
        margin: 1rem;
    }
`

const FlexColumns = styled.div`
    display: flex;
    flex-direction: column;

    p {
        white-space: pre-line;
        max-width: 750px;
    }
`

const Details = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    p {
        flex-shrink: 1;
    }

    p + p {
        margin-left: 2.5rem;
    }

    sup {
        color: ${({ theme }) => lighten(0.3, theme.color.tellowGray600)};
        letter-spacing: -0.015em;
        font-weight: 500;
        margin: 0;
        top: 0;
    }

    @media screen and (max-width: 450px) {
        flex-direction: column;

        p {
            margin-bottom: 0.75rem;
        }

        p + p {
            margin-left: unset;
        }
    }
`

const Picture = styled.div`
    display: flex;
    max-width: 960px;
    margin: 0 auto;
    margin-bottom: -3rem;
    padding: 1rem;

    img {
        flex: 1;
        object-fit: cover;
        margin: 0 auto;
        max-height: 300px;
        border-radius: 1rem;
    }
`

const CTA = styled.div`
    /* max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20 */
    margin: 2rem 0;

    h2 {
        font-size: 1.875rem;
        line-height: 2rem;
        letter-spacing: -0.025em;
        font-weight: 800;

        span {
            color: ${({ theme }) => theme.color.tellowPurple};
        }
    }
`

const Buttons = styled.div`
    display: flex;
    margin-top: 2rem;

    div:first-child {
        display: inline-flex;

        a {
            display: inherit;
            align-items: center;
            justify-content: center;
            padding: 0.75rem 2rem;
            border: none;
            border-radius: 0.5rem;
            font-weight: 500;
            font-size: 0.9rem;
            color: ${({ theme }) => theme.color.tellowPurple};
            background-color: ${({ theme }) => lighten(0.45, theme.color.tellowPurple)};
            transition: background-color 0.3s ease-out;

            &:hover {
                background-color: ${({ theme }) => lighten(0.475, theme.color.tellowPurple)};
            }
        }
    }
`

// Framer Motion
const AnimatedTitle = styled(motion.h1)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    font-size: 2rem;
`

const Job = ({ data }) => {
    const { titel: title, perks, requirements, startdatum, mission, role, sector, location, baanomschrijving } = data.prismicJob.data
    const applyLink = data.prismicJob.data.apply_link && data.prismicJob.data.apply_link.url ? data.prismicJob.data.apply_link.url : 'mailto:support@tellow.nl'

    return (
        <Layout>
            <Meta
                title={`${title.text} vacature Tellow Amsterdam`}
                description={`${title.text} vacature bij Tellow Amsterdam! Help mee met het veranderen van boekhouden! Ben jij wie we zoeken? Reageer vandaag nog!`}
                image={data.prismicJob.data.header_image.url ? data.prismicJob.data.header_image.url : ''}
                path={`/jobs/${data.prismicJob.uid}`}
                jsonLD={reviewStructuredData}
            />
            <Container>
                <JobHeader className="relative z-10 mb-8 md:mb-2 md:px-6">
                    <div className="text-base max-w-prose lg:max-w-none">
                        <p className="leading-6 text-tellow-purple font-semibold tracking-wide uppercase">Vacature</p>
                        <AnimatedTitle center="y" width="100%" initial="before" animate="after">
                            {title.text}
                        </AnimatedTitle>
                    </div>
                </JobHeader>
                <Details>
                    <p>
                        <sup>Startdatum</sup>
                        <br />
                        <span>
                            <strong>{startdatum.text}</strong>
                        </span>
                    </p>
                    <p>
                        <sup>Sector</sup>
                        <br />
                        <span>
                            <strong>{sector.text}</strong>
                        </span>
                    </p>
                    <p>
                        <sup>Locatie</sup>
                        <br />
                        <span>
                            <strong>{location.text}</strong>
                        </span>
                    </p>
                </Details>
            </Container>
            {data.prismicJob.data.header_image.url ? (
                <Picture>
                    <img src={data.prismicJob.data.header_image.url} alt={title.text.text} />
                </Picture>
            ) : null}
            <Wave green />
            <ColoredBackground green>
                <Container>
                    <FlexColumns>
                        <Title color="gray">Ondernemers blij maken, daar doen we het voor!</Title>
                        <p style={{ marginTop: '2rem' }}>
                            {
                                "Wij zijn Tellow, een boekhoud app voor ondernemers, en wij willen zzp’ers de vrijheid geven om te ondernemen. We geloven helemaal in de 'gig'-economie en we doen ons best om die te laten groeien. We maken van boekhouden een feestje. 🎉 \n\n Elke dag proberen we freelancers zoveel mogelijk te helpen met hun administratie. Ons super slimme boekhoudprogramma automatiseert zoveel mogelijk voor hen."
                            }
                        </p>
                    </FlexColumns>
                    <FlexColumns>
                        <p>
                            <Linkify>{baanomschrijving.text}</Linkify>
                        </p>
                        <h4>Jouw rol</h4>
                        <p>
                            <Linkify>{role.text}</Linkify>
                        </p>
                        <h4>Jouw missie</h4>
                        <p>
                            <Linkify>{mission.text}</Linkify>
                        </p>
                    </FlexColumns>
                </Container>
            </ColoredBackground>
            <Wave white />
            <ColoredBackground white>
                <Container>
                    <JobDescription>
                        <Summary className="prose prose-lg text-gray-500 mb-6 lg:max-w-none lg:mb-0">
                            <h3>Vereisten</h3>
                            <ol>
                                {requirements.map((rqrmnt, i) => (
                                    <li key={`req_${i}`}>{rqrmnt.single_requirement.text}</li>
                                ))}
                            </ol>
                        </Summary>
                        <Summary className="prose prose-lg text-gray-500 mb-6 lg:max-w-none lg:mb-0">
                            <h3 className="green">Voordelen</h3>
                            <ol>
                                {perks.map((prk, i) => (
                                    <li key={`perk_${i}`}>{prk.single_perk.text}</li>
                                ))}
                            </ol>
                        </Summary>
                    </JobDescription>
                    <CTA>
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                            Precies wat je zoekt?
                            <br />
                            <span>Reageer vandaag nog!</span>
                        </h2>
                        <Buttons>
                            <div>
                                <a href={applyLink}>Reageer op deze vacature</a>
                            </div>
                        </Buttons>
                    </CTA>
                    <p style={{ marginTop: '3rem', fontSize: '0.8rem' }}>
                        <small>Recruiters, de werving doen wij zelf.</small>
                    </p>
                </Container>
            </ColoredBackground>
        </Layout>
    )
}

export default withPrismicPreview(Job)

export const jobQuery = graphql`
    query Jobs($uid: String!) {
        prismicJob(uid: { eq: $uid }) {
            id
            uid
            _previewable
            data {
                active
                header_image {
                    url
                }
                baanomschrijving {
                    text
                }
                startdatum {
                    text
                }
                titel {
                    text
                }
                location {
                    text
                }
                mission {
                    text
                }
                perks {
                    single_perk {
                        text
                    }
                }
                requirements {
                    single_requirement {
                        text
                    }
                }
                role {
                    text
                }
                sector {
                    text
                }
                apply_link {
                    url
                }
            }
        }
    }
`
