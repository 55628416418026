import React from 'react'
import tw from 'twin.macro'

const style = {
    wrap: tw`bg-white max-w-7xl py-15 mx-auto px-4 sm:px-6 lg:px-8`,
    intro: tw`grid gap-10 grid-cols-1 md:grid-cols-2`,
    title: tw`text-tellow-purple font-black tracking-tight antialiased mb-6! mt-2 sm:mb-12 sm:text-3xl md:text-4xl lg:text-5xl`,
    subtitle: tw`antialiased text-2xl font-semibold mt-0 mb-2 text-gray-800 text-2xl`,
    body: tw`text-gray-700 antialiased leading-relaxed`,
    grid: tw`grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3`,
    card: tw`flex flex-col justify-between bg-white rounded-xl p-2 text-left shadow-xl`,
    image: tw`mt-4 rounded-2xl`,
}

const BannerWithCaption = React.forwardRef(({ content }, ref) => (
    <section ref={ref} css={style.wrap}>
        <div css={style.intro}>
            <div>
                <h1 css={style.title}>{content.primary.title.text}</h1>
                <h3 css={style.subtitle}>{content.primary.subtitle.text}</h3>
                <p css={style.body}>{content.primary.description.text}</p>
            </div>
            <img css={style.image} src={content.primary.image_banner.url} alt="Happy freelancer!" />
        </div>
    </section>
))

BannerWithCaption.displayName = 'BannerWithCaption'

export default BannerWithCaption
