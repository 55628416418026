import React from 'react'
import styled from 'styled-components'

const TextualContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    text-align: ${(props) => (props.center ? 'center' : 'left')};

    span {
        color: ${(props) => (props.light ? props.theme.color.tellowGray800 : 'white')};
        background: ${(props) =>
            props.light ? 'linear-gradient(rgba(247,212,115, 0.8), rgba(247,212,115, 0.8))' : 'linear-gradient(rgba(255,255,255, 0.1), rgba(255,255,255, 0.1))'};
        font-weight: 500;
        font-size: 0.7rem;
        height: 2rem;

        border-radius: 0.3rem;
        padding: 0.35rem 1rem;
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: center;
    }

    h2 {
        font-size: 2rem;
        margin-top: ${(props) => (props.parentHasImage ? '0' : '2rem')};
    }

    form:first-of-type {
        margin-top: ${(props) => (props.hasImages ? '0' : '2rem')};
    }

    #content-body {
        display: flex;
        flex-direction: column;
        flex: 1;

        h3 {
            opacity: 1;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        p {
            opacity: 0.9;
            font-size: 0.9rem;
        }
    }

    ol,
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    li {
        padding-left: 1em;
        text-indent: -1em;
        margin-bottom: 0.5rem;
        font-weight: 600;
    }

    /* •/→ */
    li:before {
        content: '→';
        opacity: 0.25;
        line-height: 0;
        padding-right: 1rem;
    }
`

export default function Text(props) {
    return !props.children ? (
        <TextualContent center={props.center} light={props.light}>
            <span>{props.Label}</span>
            <h2>{props.Header}</h2>
            <div id="content-body" dangerouslySetInnerHTML={{ __html: props.Body }} />
        </TextualContent>
    ) : (
        <TextualContent parentHasImage={props.parentHasImage} hasImages={props.hasImages} center={props.center} light={props.light}>
            {props.children}
        </TextualContent>
    )
}
