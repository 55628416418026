import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { rgba } from 'polished'
import tw from 'twin.macro'
import { Block } from '../../../utils'
import { Button, Wave } from '../../UI'
import StreamlineIcon from '../../../images/icons/streamline'
import t from '../../../theme'

const StyledLI = styled.li`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.tellowGray600};
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: 1.5px solid ${rgba('#4840bb', 0.1)};

    & + li {
        margin-top: 0.2rem;
    }

    @media screen and (min-width: 768px) {
        border-radius: 0.65rem;
    }

    &:hover {
        color: ${({ theme }) => theme.color.tellowBlue};
    }

    &.active {
        background: ${({ theme }) => theme.color.tellowPurple};
        box-shadow: ${({ theme }) => theme.smoothShadow};
        color: ${({ theme }) => theme.color.tellowWhite};
        font-weight: 600;

        img {
            filter: invert();
        }
    }

    @media screen and (max-width: 767px) {
        &.active {
            border-right: none;
            border-left: none;
        }
    }
`

const BenefitsBlock = () => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [locked, setLocked] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            if (!locked) {
                setActiveIndex((activeIndex + 1) % benefits.length)
            }
        }, 5000)
        return () => clearInterval(interval)
    })

    function activate(newIndex) {
        setLocked(true)
        setActiveIndex(newIndex)
    }

    const data = useStaticQuery(
        graphql`
            query {
                benefit1: file(relativePath: { eq: "home/benefits/Aangifte.png" }) {
                    ...fluidImage
                }
                benefit2: file(relativePath: { eq: "home/benefits/Bonnen.png" }) {
                    ...fluidImage
                }
                benefit3: file(relativePath: { eq: "home/benefits/Facturen.png" }) {
                    ...fluidImage
                }
                benefit4: file(relativePath: { eq: "home/benefits/Bespaar.png" }) {
                    ...fluidImage
                }
                benefit5: file(relativePath: { eq: "home/benefits/Overstappen.png" }) {
                    ...fluidImage
                }
            }
        `
    )

    const benefits = [
        {
            title: 'Je btw-aangifte in een paar klikken ingediend',
            description: (
                <span>
                    <b>Je btw-aangifte in een paar klikken ingediend.</b> Elk kwartaal staat het automatisch voor je klaar. Je weet altijd hoeveel btw je moet betalen. Met Tellow
                    Plus kan je je aangifte digitaal naar de Belastingdienst opsturen.
                </span>
            ),
            media: <GatsbyImage image={data.benefit1.childImageSharp.gatsbyImageData} alt="Je btw-aangifte voor je klaargezet" />,
            icon: 'MenuShield',
        },
        {
            title: 'Verwerk je bonnen compleet automatisch',
            description: (
                <span>
                    <b>Verwerk je bonnen compleet automatisch.</b> Scan je bon in 1 klik. Tellow zet ze onder de juiste kostenpost. Dat is nooit meer bonnen over tikken!
                </span>
            ),
            media: <GatsbyImage image={data.benefit2.childImageSharp.gatsbyImageData} alt="Bespaar tot 90% op je kosten" />,
            icon: 'MenuBook',
        },
        {
            title: 'Stroomlijn je offertes en facturen',
            description: (
                <span>
                    <b>Stroomlijn je offertes en facturen.</b> Maak gemakkelijk facturen in je huisstijl, stuur ze vanaf je mobiel en krijg notificaties als een klant te laat is
                    met betalen.
                </span>
            ),
            media: <GatsbyImage image={data.benefit3.childImageSharp.gatsbyImageData} alt="Bonnen automatisch verwerkt" />,
            icon: 'MenuStore',
        },
        {
            title: 'Houd grip op je financiën',
            description: (
                <span>
                    <b>Houd grip op je financiën</b> met één simpel overzicht op je inkomsten uitgaven en btw. Dit is de Marie Kondo voor je boekhouding.
                </span>
            ),
            media: <GatsbyImage image={data.benefit4.childImageSharp.gatsbyImageData} alt="Facturen sturen in no-time" />,
            icon: 'MenuInfo',
        },
        {
            title: 'Overstappen is super makkelijk',
            description: (
                <span>
                    <b>Waarom moeilijk doen als het makkelijk kan?</b> Ontvang een e-book met stap voor stap uitleg of vraag gratis een telefoongesprek aan met een van onze ervaren
                    adviseurs.
                </span>
            ),
            media: <GatsbyImage image={data.benefit5.childImageSharp.gatsbyImageData} alt="Overstappen is super makkelijk" />,
            icon: 'MenuCheck',
        },
    ]

    return (
        <>
            <Wave white />
            <Block className="benefits">
                <style>{`
                    .benefits {
                        padding-top: 3em;
                        padding-bottom: 2em;
                        max-width: 800px;
                        margin: 0 auto;
                    }

                    @media screen and (max-width: 767px) {
                        .benefits .header,
                        .benefits .description {
                            text-align: center;
                        }

                        .benefits .interactive {
                            padding-top: 23px;
                        }
                    }

                    .benefits .footer {
                        text-align: center;
                    }

                    .benefits .media {
                        float: left;
                        height: 396px;
                        width: 180px;
                        margin-left: -17px;
                        margin-right: 20px;
                    }

                    .benefits .media-inner {
                        position: relative;
                    }

                    .benefits .media-item {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                    }

                    .benefits .media-item.active {
                        opacity: 1;
                    }

                    .benefits .media-item img {
                        width: 100%;
                    }

                    @media screen and (max-width: 767px) {
                        .benefits .description {
                            clear: both;
                        }

                        .benefits .description-inner {
                            padding: .5em 2.5rem;
                        }
                    }

                    @media screen and (min-width: 768px) {
                        .benefits .interactive {
                            margin-left: 291px;
                        }

                        .benefits .media {
                            margin-top: -155px;
                            width: 252px;
                        }

                        .benefits .description,
                        .benefits .header {
                            margin-left: 40%;
                        }

                        .benefits .description-inner {
                            padding: .5em 1rem;
                        }

                        .benefits .footer {
                            clear: both;
                        }
                    }

                    .benefits .description {
                        height: 155px;
                        position: relative;
                        font-size: 14px;
                        color: ${({ theme }) => theme.color.tellowBlue};
                        line-height: 25px;
                    }

                    .benefits .description-inner {
                        font-size: 16px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                    }

                    .benefits .description-inner.active {
                        opacity: 1;
                        transition: opacity .6s ease-in-out;
                    }

                    .benefits .availability {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                `}</style>

                <div css={tw`max-w-7xl pt-15 pb-5 mx-auto px-0 md:px-6 lg:px-8`}>
                    <div className="header">
                        <h3 css={tw`text-center sm:text-left text-gray-800 font-black tracking-tight antialiased mt-4 mb-4 sm:text-3xl md:text-4xl lg:text-5xl`}>
                            Laat je administratie vóór je werken.
                        </h3>
                        <p css={tw`font-normal antialiased text-sm text-gray-600 mb-8`}>Ontdek de voordelen van Tellow.</p>
                    </div>

                    <div className="media">
                        <div className="media-inner">
                            {benefits.map(({ media, icon }, index) => (
                                <div className={`media-item ${activeIndex === index ? 'active' : ''}`} key={icon}>
                                    {media}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="interactive">
                        <ul>
                            {benefits.map(({ title, icon }, index) => (
                                <StyledLI className={`${activeIndex === index && 'active'}`} onClick={() => activate(index)} key={icon}>
                                    <span css={tw`flex items-center`}>
                                        <StreamlineIcon color={t.color.tellowPurple} size="16" css={tw`m-0 mr-6`} icon={icon} />
                                        <p>{title}</p>
                                    </span>
                                </StyledLI>
                            ))}
                        </ul>
                    </div>

                    <div className="description">
                        {benefits.map(({ description, icon }, index) => (
                            <div className={`description-inner ${activeIndex === index && 'active'}`} key={icon}>
                                {description}
                            </div>
                        ))}
                    </div>

                    <div className="footer">
                        <div style={{ margin: '30px 0 20px' }}>
                            <Button href="/functies" big purple>
                                Bekijk alle features
                            </Button>
                        </div>
                    </div>
                </div>
            </Block>
        </>
    )
}

export default BenefitsBlock

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
        }
    }
`
