import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import styled from 'styled-components'
import { Navigation, Pagination } from 'swiper'
import { useMediaQuery } from 'react-responsive'

import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const SwiperStyles = styled.div`
    .swiper-slide {
        width: 75%;
        min-height: 350px;
    }

    ${(props) =>
        !props.isMobile &&
        `
    --swiper-pagination-color:  ${props.bulletColor};
    --swiper-pagination-bullet-inactive-color: ${props.bulletInactiveColor};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-size: 12px;
    --swiper-navigation-color: ${props.theme.color.tellowPurple};
    --swiper-navigation-size: 20px;


    margin: 2rem 1rem;
    display: flex;
    position: relative;
    .swiper-pagination-bullet {
        border: 2px solid ${props.bulletBorderColor};
    }

    .swiper-button-prev,
    .swiper-button-next {
        margin-bottom: 2rem;
        width: 2.25rem;
        height: 2.25rem;
        background-color: ${props.theme.color.tellowPurpleLight};
        border-radius: 50%;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -30px;
    }
    .swiper {
        margin: 0 3.75rem;
        position: unset;
    }
  `}
`

const CardContainer = styled.div`
    margin: 8px;
    background: ${(props) => props.theme.color.tellowWhite};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 350px;
    ${({ mobile }) =>
        mobile &&
        `transform: scale(0.9);
    opacity: 0.8;
    transition: all ease-out 0.2s;
    &.active {
        transform: scale(1);
        opacity: 1;
        transition: all ease-in 0.2s;
    }
  `}
`

const Carousel = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 650px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 651px) and (max-width: 1000px)' })

    const howManyBlogsToDisplay = (dataLength, defaultValue) => (dataLength >= defaultValue ? defaultValue : dataLength)

    return (
        <SwiperStyles
            isMobile={isMobile}
            isTablet={isTablet}
            bulletColor={props.bulletColor}
            bulletInactiveColor={props.bulletInactiveColor}
            bulletBorderColor={props.bulletBorderColor}
        >
            <Swiper
                modules={!isMobile && [Navigation, Pagination]}
                spaceBetween={isMobile ? 0 : 20}
                centeredSlides={isMobile}
                // eslint-disable-next-line no-nested-ternary
                slidesPerView={isMobile ? 'auto' : isTablet ? howManyBlogsToDisplay(props.amount, 2) : howManyBlogsToDisplay(props.amount, 3)}
                navigation={!isMobile}
                // eslint-disable-next-line no-nested-ternary
                slidesPerGroup={isMobile ? 1 : isTablet ? 2 : 3}
                pagination={!isMobile && { clickable: true }}
            >
                {props.children.map((child) => (
                    <SwiperSlide key={child.key}>
                        {({ isActive }) => (
                            <CardContainer className={isActive && 'active'} mobile={isMobile}>
                                {child}
                            </CardContainer>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </SwiperStyles>
    )
}

export default Carousel
