import React from 'react'
import tw from 'twin.macro'

import { RegisterAsPartnerButton } from '../../CTA'

import midas from '../../../images/partners/photos/midas.png'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl pb-4 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-10`,
    bigTitle: tw`text-2xl md:text-3xl lg:text-4xl tracking-tight font-black items-start mb-6`,
    text: tw`text-base md:text-base lg:text-base text-gray-800 tracking-tight`,
    span: tw`inline text-tellow-purple!`,
    content: tw`rounded-2xl bg-tellow-white bg-opacity-90 px-10 pt-14 lg:pt-5 col-span-1 z-10 shadow-lg `,
    img: tw`hidden md:inline mx-auto my-0 p-0 filter drop-shadow-xl`,
    textBlock: tw`absolute top-10 right-14 lg:visible invisible`,
    name: tw`text-center text-sm font-bold mb-0`,
    title: tw`text-center text-xs text-tellow-purple mb-0`,
    messageContainer: tw`flex flex-col h-auto items-start justify-center`,
    imageContainer: tw`flex flex-col justify-end`,
}

const BecomeAPartner = () => (
    <div css={style.wrapper}>
        <div css={style.content}>
            {/* Creation of grid to separate the component into two separate parts */}
            <div css={style.grid}>
                <div css={style.messageContainer}>
                    <div css={style.bigTitle}>
                        Begin je <br />
                        <span css={style.span}>partnership vandaag</span>
                    </div>
                    <div css={style.text}>
                        <p>Benieuwd of een partnership interessant voor jou is? Ik help je graag verder en beantwoord al je vragen.</p>
                    </div>
                    <RegisterAsPartnerButton text="Meld je aan" url="mailto:support@tellow.nl" />
                </div>

                <div css={style.imageContainer}>
                    <div css={style.textBlock}>
                        <p css={style.name}>Midas</p>
                        <p css={style.title}>Partner Manager</p>
                    </div>
                    <img css={style.img} src={midas} alt="Partner Manager" />
                </div>
            </div>
        </div>
    </div>
)

export default BecomeAPartner
