import React from 'react'
import styled from 'styled-components'

import { Block, Hero, Text as TextBlock } from '../../../utils'

const Content = styled(Hero)`
    padding: 3rem 0 2rem;
`

const Column = styled(Block)`
    p {
        column-count: 2;
        column-gap: 4rem;
        column-fill: balance;

        @media screen and (max-width: 768px) {
            column-count: 1;
        }
    }
`

// Set color of text to match contrast of background
// (passed via props, originates from Prismic)
function bodyColor(color) {
    if (color === '#FBB36B' || color === '#E2E2E2') {
        return 'black'
    }

    if (color === '#4ABB90' || color === '#011532' || color === '#52B9E9') {
        return 'white'
    }
}

const ColumnHero = ({ title, body, color }) => (
    <Content style={{ backgroundColor: color }}>
        <TextBlock
            style={{
                maxWidth: 1000,
                margin: '0 auto',
            }}
        >
            <p style={{ color: bodyColor(color) }}>
                <b>{title} </b>
            </p>
            <Column>
                <article
                    style={{ color: bodyColor(color) }}
                    dangerouslySetInnerHTML={{
                        __html: body,
                    }}
                />
            </Column>
        </TextBlock>
    </Content>
)

export default ColumnHero
