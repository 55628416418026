/* eslint-disable no-nested-ternary */
import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Segment from '../../external-services/segment'
import config from '../../../config'
import { useTranslate } from '../../../hooks'

const StyledLoginLink = styled.a`
    /* With border prop */
    ${(props) =>
        props.border === true &&
        css`
            border-width: 2px;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.25);
        `};

    /* Without border prop */
    ${(props) =>
        !props.border &&
        css`
            border: 2px solid ${({ theme }) => rgba(theme.color.tellowWhite, 0)};
        `};

    transition: opacity 150ms ease-in-out;

    cursor: pointer;
    display: inline-block;
    height: fit-content;
    border-radius: 0.65rem;

    padding: ${(props) => (props.narrow !== undefined ? '0.4rem .75rem' : '0.4rem 1.75rem')};
    margin: 0 0.5rem;

    font-weight: 600;
    font-size: 0.8rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &.active,
    &:hover {
        opacity: 0.9;
        transition: 150ms ease-in-out;

        ${(props) =>
            props.border &&
            css`
                border: 2px solid ${({ theme }) => theme.color.tellowWhite};
            `}
    }
`

const navigate = (context, url) => {
    Segment.track('Click - Login', {
        location: context,
        page: window.location.pathname,
    })

    // Timeout to have Segment trigger.
    setTimeout(() => {
        window.location.assign(url)
    }, 500)
}

const Login = ({ context, ...args }) => {
    const { t } = useTranslate('components', 'login')

    return (
        <StyledLoginLink
            {...args}
            onClick={() => {
                navigate(context, config.links.app)
            }}
        >
            {t.login}
        </StyledLoginLink>
    )
}

export default Login
