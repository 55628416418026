import React from 'react'
import tw from 'twin.macro'
import { useTranslate } from '../../hooks'

const styles = {
    wrap: tw`mx-0 px-5 md:px-10 my-4 text-left bg-white text-gray-800 rounded-xl shadow-xl`,
    text: tw`antialiased text-xs sm:text-sm md:text-lg mx-0! py-6 `,
    p: tw`antialiased tracking-tight inline-block`,
}

const Quote = ({ text, city, website, author }) => {
    const { t } = useTranslate()

    return (
        <div css={styles.wrap}>
            <blockquote css={styles.text}>{text}</blockquote>
            <div>
                <p css={styles.p}>
                    <b>{author}&nbsp;</b>
                </p>
                {city && (
                    <p css={styles.p}>
                        {t.generic.from} {city}&nbsp;
                    </p>
                )}
                {website && (
                    <p css={styles.p}>
                        {t.generic.of} {website}&nbsp;
                    </p>
                )}
            </div>
        </div>
    )
}

export default Quote
