import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
    color: ${(props) => props.theme.color[props.color]};
    font-size: ${(props) => props.size}px;
    @media screen and (min-width: 768px) {
        font-size: ${(props) => props.sizeTablet}px;
    }
    @media screen and (min-width: 1100px) {
        font-size: ${(props) => props.sizeDesktop}px;
    }
`
const H2 = styled.h2`
    color: ${(props) => props.theme.color[props.color]};
    font-size: ${(props) => props.size}px;
    @media screen and (min-width: 768px) {
        font-size: ${(props) => props.sizeTablet}px;
    }
    @media screen and (min-width: 1100px) {
        font-size: ${(props) => props.sizeDesktop}px;
    }
`
const H3 = styled.h3`
    color: ${(props) => props.theme.color[props.color]};
    font-size: ${(props) => props.size}px;
    @media screen and (min-width: 768px) {
        font-size: ${(props) => props.sizeTablet}px;
    }
    @media screen and (min-width: 1100px) {
        font-size: ${(props) => props.sizeDesktop}px;
    }
`

const Heading = ({ children, size, sizeTablet, sizeDesktop, style, className, color }) => {
    const props = { size, sizeTablet, sizeDesktop, style, className, color }
    if (size >= 40) {
        return <H1 {...props}>{children}</H1>
    }
    if (size >= 30) {
        return <H2 {...props}>{children}</H2>
    }
    return <H3 {...props}>{children}</H3>
}

export default Heading
