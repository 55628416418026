import React from 'react'
import styled from 'styled-components'

export const StyledBlockSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1rem;
    text-align: center;
    &.White {
        background-color: ${(props) => props.theme.color.tellowWhite};
    }
    &.Green {
        background-color: ${(props) => props.theme.color.tellowGreen};
    }
    &.Grey {
        background-color: ${(props) => props.theme.color.tellowGray200};
    }
    &.Darkblue {
        background-color: ${(props) => props.theme.color.tellowBlue};
    }
    &.Orange {
        background-color: ${(props) => props.theme.color.tellowOrange};
    }
    &.Purple {
        background-color: ${(props) => props.theme.color.tellowPurple};
    }
    .Green {
        color: ${(props) => props.theme.color.tellowGreen};
    }
    .White {
        color: ${(props) => props.theme.color.tellowWhite};
    }
    .DarkBlue {
        color: ${(props) => props.theme.color.tellowBlue};
    }
    .Orange {
        color: ${(props) => props.theme.color.tellowOrange};
    }
    .Purple {
        color: ${(props) => props.theme.color.tellowPurple};
    }
    .Dark {
        color: #01132f;
    }
    .Light {
        color: ${(props) => props.theme.color.tellowWhite};
    }

    @media screen and (max-width: 480px) {
        padding: ${(props) => props.mobile && 0} !important;
    }
`

const BLOCK = 'Block'
export const BlockSection = React.forwardRef(({ children, className, style }, ref) => (
    <StyledBlockSection ref={ref} className={className} style={style}>
        {children}
    </StyledBlockSection>
))
BlockSection.displayName = BLOCK

const StyledContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    @media screen and (min-width: 768px) {
        max-width: 60rem;
    }
    h2 {
        font-size: 2.7rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 3rem;
    }
    p {
        margin: 1rem 0;
        padding: 0;
        font-size: 1rem;
        line-height: 1.66rem;
        text-align: left;
    }
`

export const Content = ({ children, className, style, ...props }) => (
    <StyledContent {...props} className={className} style={style}>
        {children}
    </StyledContent>
)
