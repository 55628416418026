// @ts-check
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Segment from '../../external-services/segment'

/**
 * @param {string} description
 */
const trackEventAndRedirectHandler = (description) =>
    Segment.track(`Click - ${description}`, {
        location: window.location.pathname,
    })

/**
 * Since DOM elements <a> cannot receive activeClassName,
 * destructure the prop here and pass it only to GatsbyLink
 *
 * @param {Object} arguments
 * @param {any} arguments.children - Component chidlren.
 * @param {(import('twin.macro').TwStyle | string)[]} arguments.css - Optional injected styles.
 * @param {string} arguments.to - URL to link to.
 * @param {string=} arguments.activeClassName - Additional classnames.
 * @param {boolean=} arguments.download - Is this a download?
 * @param {boolean} arguments.shouldBeTracked - Should this be tracked?
 * @param {string} arguments.description - Description
 * @param {('nl' | 'en')=} arguments.language - i18n key.
 */
const Link = ({ children, to, activeClassName, download = false, shouldBeTracked, description, language, ...other }) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)

    /**
     * If it's an internal URL ('to'), and the requested language
     * is not Dutch (e.g. 'English' / 'en'), we can add the i18n
     * prefix to the URL, so that we are routed to a page in
     * the same language as requested. Make sure it exists.
     */
    const internalTo = internal ? `${language && language !== 'nl' ? `/${language}` : ''}${to}` : to

    if (shouldBeTracked) {
        return (
            <GatsbyLink onClick={() => trackEventAndRedirectHandler(description)} to={internalTo} activeClassName={activeClassName} {...other}>
                {children}
            </GatsbyLink>
        )
    }

    // Use Gatsby Link for internal links, and <a> for others
    if (internal && !download) {
        return (
            <GatsbyLink to={internalTo} activeClassName={activeClassName} {...other}>
                {children}
            </GatsbyLink>
        )
    }

    return (
        /* eslint-disable-next-line react/jsx-no-target-blank */
        <a target="_blank" href={to} {...(typeof to === 'string' && to.indexOf('.tellow.nl') === -1 ? { rel: 'noopener noreferrer' } : {})} {...other}>
            {children}
        </a>
    )
}

export default Link
