import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex: 0 0 21%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1rem;
    padding: 0.5rem;

    cursor: ${(props) => (props.visitable ? 'pointer' : 'default')};

    transition: all 0.5s ease;

    h2 {
        transition: all 0.5s ease;
        transform: translateX(10px);
        opacity: 0.7;
    }

    &:hover h2 {
        transition: all 0.5s ease;
        transform: translateX(0px);
        opacity: 1;
        font-weight: 600 !important;
    }

    span {
        transition: all 0.5s ease;

        opacity: 0;
        color: ${({ theme }) => theme.color.tellowPurple};
        font-size: 0.8rem;
        font-weight: 600;
        margin-left: 0.5rem;
        transform: translateX(0px);
    }

    &:hover span {
        transition: all 0.5s ease;

        opacity: 1;
        transform: translateX(5px);
    }

    @media screen and (max-width: 350px) {
        flex: 0 0 51%;
    }

    @media screen and (min-width: 350px) and (max-width: 770px) {
        flex: 0 0 41%;
    }

    @media screen and (min-width: 770px) and (max-width: 990px) {
        flex: 0 0 31%;
    }
`

const Logo = styled.div`
    display: block;
    height: 5rem;
    max-width: 7rem;

    img {
        height: 5rem;
        margin: 0 auto;
        object-fit: contain;

        mix-blend-mode: multiply;
        filter: grayscale(100%) saturate(100%) contrast(1.5);

        animation: appear 1s ease;
        transition: all 0.2s ease;

        @keyframes appear {
            0% {
                opacity: 0;
                transform: translateY(3px);
            }
            100% {
                opacity: 1;
            }
        }
    }
`

export default function Partner({ node }) {
    const { data, id, uid } = node
    const { text: name } = data.name
    const { visitable } = data

    const navigateToPartner = () => window.location.assign(`./${uid}/`)

    return (
        <Container visitable={visitable} onClick={visitable ? navigateToPartner : null} key={id}>
            <Logo visitable={visitable}>
                <img alt={name} src={data.logo.url} />
            </Logo>
        </Container>
    )
}
